import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTpyes from 'prop-types';
import { updateShippingAddress } from '../../../../actions/CustomerActions'

class EditShoppingAddress extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }
    
    state = {
        modal: false,

        line1: this.props.address.line1,
        line1Error: '',
        street: this.props.address.street,
        landmark: this.props.address.landmark,
        city: this.props.address.city,
        cityError: '',
        state: this.props.address.state,
        stateError: '',
        country: this.props.address.country,
        countryError: '',
        zipCode: this.props.address.zipCode,
    }

    static propTpyes = {
        updateShippingAddress: PropTpyes.func.isRequired
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });

        if(e.target.name === "line1") {
            this.setState({ line1Error: false });
        }

        if(e.target.name === "city") {
            this.setState({ cityError: false });
        }

        if(e.target.name === "state") {
            this.setState({ stateError: false });
        }

        if(e.target.name === "country") {
            this.setState({ countryError: false });
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    applyChanges = () => {
        {// Validate
            let error = false;
            if(!this.state.line1) {
                this.setState({ line1Error: true });
                error = true;
            }
    
            if(!this.state.city) {
                this.setState({ cityError: true });
                error = true;
            }
    
            if(!this.state.state) {
                this.setState({ stateError: true });
                error = true;
            }
    
            if(!this.state.country) {
                this.setState({ countryError: true });
                error = true;
            }
    
            if(error) return;
        }

        // Apply changes
        // 1. Create updated Shipping address
        let address = {
            ...this.props.address,
            line1: this.state.line1,
            street: this.state.street,
            landmark: this.state.landmark,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            zipCode: this.state.zipCode,
        }
        let shippingAddress = this.props.customer.shippingAddress;
        let customer = {
            // ...this.props.customer,
            _id: this.props.customer._id,
            shippingAddress: shippingAddress.map(a => a.addressId === address.addressId ? address : a)
        }
        this.props.updateShippingAddress(customer);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button 
                    className="btn-style float-left"
                    onClick={this.toggle}
                > <FontAwesomeIcon icon={faEdit} /> </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalBody>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row >
                                <Col>
                                    <Label>Address</Label>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.line1} type="address" name="line1" placeholder="(Address (Unit/Area))" invalid={this.state.line1Error}/>
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.street} type="text" name="street" placeholder="(Street/Road)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.landmark} type="text" name="landmark" placeholder="(Landmark)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.city} type="text" name="city" placeholder="(City/Town)" invalid={this.state.cityError}/>
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.state} type="text" name="state" placeholder="(State/Province)" invalid={this.state.stateError}/>
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.country} type="text" name="country" placeholder="(Country)" invalid={this.state.countryError}/>
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4} >
                                    <Input onChange={this.onChange} value={this.state.zipCode} type="text" name="zipCode" placeholder="(Zip/Pin Code)" />
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.applyChanges}>Apply</Button>
                        <Button color="secondary" onClick={this.cancelChanges}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { updateShippingAddress })(EditShoppingAddress);