import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOrderNotes } from '../../../../../actions/OrderActions';

class AddNotes extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        // onToggle();
        // notes: this.props.order.notes,
    }

    static propTypes = {
        updateOrderNotes: PropTypes.func.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            notes: this.props.order.notes,
        })
    }

    applyChange = () => {
        let order = {
            _id: this.props.order._id,
            notes: this.state.notes,
        }
        this.props.updateOrderNotes(order);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <Button className="p-0" color="link" onClick={this.toggle} >add notes</Button>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody>
                        <Row>
                            <Col md={2} >
                                <Label><strong>Notes:</strong></Label> 
                            </Col>
                            <Col md={10} >
                                <Input type="textarea" value={this.state.notes} onChange={this.onChange} name="notes" />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.applyChange}>Save Changes</Button>
                        <Button color="secondary" onClick={this.cancelChanges}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null , { updateOrderNotes } )(AddNotes);