import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Label, Card, CardBody, CardHeader, CardFooter, Table, Button, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { formatAddress, formatAmount } from '../../constVarAndFunc/formats'

class ViewOrder extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        // get order from props on toggle
        order: undefined,
    }

    static propTypes = {
        customer: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
        employee: PropTypes.object.isRequired,
    }

    onChange = e =>{
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    toggle = () => {
        console.log('Toggle!');
        this.setState({
            modal: !this.state.modal,
            order: this.props.order,
        })
    }

    getEmployeeNameId = employeeId => {
        let employee = this.props.employee.employees.find(employee => employee.employeeId === employeeId);
        if(employee) return `${employee.fullName} (${employee.employeeId})`;
    }

    findCustomerByOrderId = orderId => {
        const customerId = (this.props.orders.find(order => order._id === orderId)).customerId;
        if(customerId) {
            return this.findCustomer(customerId);
        }
    }

    findCustomer = customerId => {
        return this.props.customer.customers.find(customer => customer.customerId === customerId);
    }

    findAddress = (addressId, customerId) => {
        let address = {};
        const customer = this.findCustomer(customerId);
        if(customer.address.addressId === addressId) {
            // If primary address
            address = customer.address;
        } else {
            // If shipping address
            customer.shippingAddress.forEach(a => {
            if(a.addressId === addressId) {
                    address = a;
                }
            })
        }
        return address;
    }

    findProductName = code => {
        let product = this.props.product.products.find(p => p.code === code);
        if(!product) product = this.props.customProduct.customProducts.find(p => p.code === code);
        return `${product.name} (${product.productType})`;
    }

    render() {
        let { order } = this.props;

        let { customers } = this.props.customer;

        if(customers.length === 0) return;

        return (
            <div>
                <button class="btn-style" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faEye} />
                </button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl" >
                    <ModalHeader>
                        View Order
                    </ModalHeader>

                    <ModalBody>
                        <Card>
                            <CardBody style={{background: "aliceblue"}}>
                                <Row style={{whiteSpace: "pre-wrap"}}>
                                    <Col md={6} style={{paddingRight:"10px", borderRight: "2px solid #ccc"}} >
                                        <h5>Order Summary</h5>
                                        <Row >
                                            <Col md={6} >
                                                <Label><strong>Total Amount: </strong></Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount(order.orderSummary.totalAmount)}/- </Label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md={6} >
                                                <Label><strong>Shipping and Handling: </strong></Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount(order.orderSummary.shippingHandling)} </Label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md={6} >
                                                <Label><strong>Discount: </strong></Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount(order.orderSummary.discount)} </Label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md={6} >
                                                <Label><strong>State tax: </strong> {order.orderSummary.stateTax} %</Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount(order.orderSummary.totalAmount*(order.orderSummary.stateTax / 100))} </Label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md={6} >
                                                <Label><strong>Central Tax: </strong> {order.orderSummary.centralTax} %</Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount(order.orderSummary.totalAmount*(order.orderSummary.centralTax / 100))} </Label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md={6} >
                                                <Label><strong>Grand Total: </strong></Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount(order.orderSummary.grandTotal)}/- </Label>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6} style={{whiteSpace: 'pre-wrap'}}>
                                        <Row>
                                            <Col md={3}>
                                                <Label><strong>Sales Person:</strong></Label>
                                            </Col>
                                            <Col md={9}>
                                                <Label> {this.getEmployeeNameId(order.employeeId)} </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                {/* <h5>Address: </h5> */}
                                                <Label><strong>Address:</strong></Label>
                                            </Col>
                                            <Col md={8}>
                                                <Label> {formatAddress(this.findAddress(order.addressId, order.customerId))} </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <Label><strong>Notes: </strong></Label>
                                            </Col>
                                            <Col md={9}>
                                                {order.notes ? (
                                                    <div>
                                                        <Label> {order.notes} </Label>{"\n"}
                                                    </div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} >
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th>Code</th>
                                                    <th>Package Size</th>
                                                    <th>Num Package</th>
                                                    <th>Quantity</th>{/***Autofill ***/}
                                                    <th>Rate</th>
                                                    <th>Amount</th>{/***Autofill ***/}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {order.products.map((prod, i) => (
                                                    <tr key={i}>
                                                        <td>{i+1}</td>
                                                        <td> {this.findProductName(prod.code)} </td>
                                                        <td> {prod.code} </td>
                                                        <td> {prod.packageSize} </td>
                                                        <td> {prod.numPackage} </td>
                                                        <td> {prod.packageSize * prod.numPackage} </td>
                                                        <td> {prod.rate} </td>
                                                        <td> {prod.packageSize * prod.numPackage * prod.rate} </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle} >Okay</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    employee: state.employee,
    product: state.product,
    customProduct: state.customProduct,
    customer: state.customer,
})

export default connect(mapStateToProps, {} )(ViewOrder);