import {
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  LOADING_CUSTOMERS,
  UPDATE_CUSTOMER,
  ADD_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS,
  ARCHIVE_SHIPPING_ADDRESS,
  UNARCHIVE_SHIPPING_ADDRESS,
} from "./types";
import axios from 'axios';
import { addLog } from './LogActions';
import { tokenConfig } from './AuthActions';

export const getCustomers = () => (dispatch, getState) => {
    dispatch(loadingCustomers());
    axios
      .get('/api/customers', tokenConfig(getState))
      .then(res => {
          dispatch({
              type: GET_CUSTOMERS,
              payload: res.data,
          })
      })
}

export const loadingCustomers = () => disptach => {
    disptach({
        type: LOADING_CUSTOMERS,
    })
}

export const addCustomer = newCustomer => (dispatch, getState) => {
    axios
        .post('/api/customers', newCustomer, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CUSTOMER,
                payload: res.data,
            })

            // Log
            const log = {
                type: ADD_CUSTOMER,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: newCustomer,
            }
            addLog(log, dispatch);
        })
        .catch(err => console.log(err));
}

export const deleteCustomer = id => (dispatch, getState) => {
    axios
        .delete(`/api/customers/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_CUSTOMER,
                payload: id
            })

            // Log
            const log = {
                type: DELETE_CUSTOMER,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: id,
            }
            addLog(log, dispatch);
        })
}

const updateCustomerWithType = (updatedCustomer, type, dispatch, getState) => {
    const id = updatedCustomer._id;
    axios
        .put(`/api/customers/${id}`, updatedCustomer, tokenConfig(getState))
        .then(res => {
            dispatch({
                type,
                payload: res.data,
            })

            // Log
            const log = {
                type,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: updatedCustomer,
            }
            addLog(log, dispatch);
        })
}

export const updateCustomer = updatedCustomer => (dispatch, getState) => {
    updateCustomerWithType(updatedCustomer, UPDATE_CUSTOMER, dispatch, getState);
}

export const addShippingAddress = updatedCustomer => (dispatch, getState) => {
    updateCustomerWithType(updatedCustomer, ADD_SHIPPING_ADDRESS, dispatch, getState);
}

export const updateShippingAddress = updatedCustomer => (dispatch, getState) => {
    updateCustomerWithType(updatedCustomer, UPDATE_SHIPPING_ADDRESS, dispatch, getState);
}

export const archiveShippingAddress = updatedCustomer => (dispatch, getState) => {
    updateCustomerWithType(updatedCustomer, ARCHIVE_SHIPPING_ADDRESS, dispatch, getState);
}

export const unarchiveShippingAddress = updatedCustomer => (dispatch, getState) => {
    updateCustomerWithType(updatedCustomer, UNARCHIVE_SHIPPING_ADDRESS, dispatch, getState);
}