import React from 'react';
import { Label, Nav, NavItem, NavLink, TabContent, TabPane, Card, Button, CardTitle, CardBody, Row, Col, } from 'reactstrap';
import Summary from './Summary';

class Dashboard extends React.Component {
    state = {
        tab: 'Summary',
    }

    setTab = tab => {
        this.setState({
            tab,
        })
    }

    render() {
        return (
            <div>
                <Nav tabs justified pills style={{ background: '#F5FFFA' }}>
                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Summary' ? 'active' : null}
                            onClick={() => {this.setTab('Summary')}}
                        >
                            Summary
                        </NavLink>
                    </NavItem>

                    {/* <NavItem>
                        <NavLink
                            className={this.state.tab === 'Customers' ? 'active' : null}
                            onClick={() => {this.setTab('Customers')}}
                        >
                            Customers
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Orders' ? 'active' : null}
                            onClick={() => {this.setTab('Orders')}}
                        >
                            Orders
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Products' ? 'active' : null}
                            onClick={() => {this.setTab('Products')}}
                        >
                            Products
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Employees' ? 'active' : null}
                            onClick={() => {this.setTab('Employees')}}
                        >
                            Employees
                        </NavLink>
                    </NavItem> */}
                </Nav>

                <TabContent activeTab={this.state.tab}>
                    <TabPane tabId="Summary">
                        <Summary />
                    </TabPane>

                    {/* <TabPane tabId="Customers">
                        <h2>Customers</h2>
                    </TabPane>

                    <TabPane tabId="Orders">
                        <h2>Orders</h2>
                    </TabPane>

                    <TabPane tabId="Products">
                        <h2>Products</h2>
                    </TabPane>

                    <TabPane tabId="Employees">
                        <h2>Employees</h2>
                    </TabPane> */}
                </TabContent>
            </div>
        )
    }
}

export default Dashboard;