import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Card, CardBody, CardHeader, Button, Label, } from 'reactstrap';
import { ADMINISTRATION_MANAGEMENT } from '../../constVarAndFunc/UserLevel'
import { getReports } from '../../../actions/ReportActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ADD_STOCK, DAMAGED_PRODUCTS, INVENTORY_COUNT } from '../../../actions/types';
import InventoryCount from '../ProductTab/InventoryCount';
import AddStock from '../ProductTab/AddStock';
import DamagedProducts from '../ProductTab/DamagedProducts';
import DeleteReport from './DeleteReport';

class Reports extends React.Component {
    state = {

    }

    static propTypes = {
        user: PropTypes.object.isRequired,
        getReports: PropTypes.func.isRequired,
    }

    componentDidMount = () => {
        if(this.props.user.userLevel === ADMINISTRATION_MANAGEMENT) {
            this.props.getReports();
        }
    }

    renderReport = report => {
        if(report.reportType === INVENTORY_COUNT) {
            return <InventoryCount modal={true} products={report.payload.products} />
        }
    }

    render() {
        const reports = this.props.report.reports.filter(report => !report.isSubmitted);
        const submittedReports = this.props.report.reports.filter(report => report.isSubmitted);

        return (
            <div className="mt-3">
                <Card className="shadow"> 
                    <CardHeader>
                        <h4>
                            Reports
                        </h4>
                    </CardHeader>

                    <CardBody>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Report Name</th>
                                    <th>Report Type</th>
                                    <th>Report Date</th>
                                    <th>Reported by</th>
                                    <th>View</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {reports.map((report, i) => (
                                    <tr>
                                        <td>{i+1}</td>
                                        <td> {report.reportName} </td>
                                        <td> {report.reportType} </td>
                                        <td> {moment(report.reportDate).utcOffset("+05:30").format('DD MMM YYYY (LT)')} </td>
                                        <td> {report.user.name} </td>
                                        <td>
                                            {/* Inventory Count */}
                                            {report.reportType === INVENTORY_COUNT ? (
                                                <InventoryCount report={report} />
                                            ) : null}
                                            {/* Add stock */}
                                            {report.reportType === ADD_STOCK ? (
                                                <AddStock report={report} />
                                            ) : null}
                                            {/* Damaged Product */}
                                            {report.reportType === DAMAGED_PRODUCTS ? (
                                                <DamagedProducts report={report} />
                                            ) : null}
                                        </td>
                                        <td>
                                            <DeleteReport report={report} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Card className="shadow mt-3"> 
                    <CardHeader>
                        <h5>
                            Submitted Reports
                        </h5>
                    </CardHeader>

                    <CardBody>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Report Name</th>
                                    <th>Report Type</th>
                                    <th>Report Date</th>
                                    <th>Reported by</th>
                                    <th>Download</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {submittedReports.map((report, i) => (
                                    <tr>
                                        <td>{i+1}</td>
                                        <td> {report.reportName} </td>
                                        <td> {report.reportType} </td>
                                        <td> {moment(report.reportDate).utcOffset("+05:30").format('DD MMM YYYY (LT)')} </td>
                                        <td> {report.user.name} </td>
                                        <td>
                                            {/* Inventory Count */}
                                            {report.reportType === INVENTORY_COUNT ? (
                                                <InventoryCount report={report} download={true} />
                                            ) : null}
                                            {/* Add stock */}
                                            {report.reportType === ADD_STOCK ? (
                                                <AddStock report={report} download={true} />
                                            ) : null}
                                            {/* Damaged Product */}
                                            {report.reportType === DAMAGED_PRODUCTS ? (
                                                <DamagedProducts report={report} download={true} />
                                            ) : null}
                                        </td>
                                        <td>
                                            <DeleteReport report={report} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    report: state.report,
})

export default connect(mapStateToProps, { getReports })(Reports);