import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { invalid } from 'moment';
import React from 'react';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addShippingAddress } from '../../../../actions/CustomerActions';

class AddShippingAddress extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal : false,

        line1: null,
        line1Error: '',
        street: null,
        landmark: null,
        city: null,
        cityError: '',
        state: null,
        stateError: '',
        country: null,
        countryError: '',
        zipCode: null,
    }

    static propTypes = {
        addShippingAddress: PropTypes.func.isRequired
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });

        if(e.target.name === "line1") {
            this.setState({ line1Error: false });
        }

        if(e.target.name === "city") {
            this.setState({ cityError: false });
        }

        if(e.target.name === "state") {
            this.setState({ stateError: false });
        }

        if(e.target.name === "country") {
            this.setState({ countryError: false });
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    addAddress = () => {
        {// Validate
            let error = false;
            if(!this.state.line1) {
                this.setState({ line1Error: true });
                error = true;
            }
    
            if(!this.state.city) {
                this.setState({ cityError: true });
                error = true;
            }
    
            if(!this.state.state) {
                this.setState({ stateError: true });
                error = true;
            }
    
            if(!this.state.country) {
                this.setState({ countryError: true });
                error = true;
            }
    
            if(error) return;
        }

        // Add shipping address to the customer profile
        // 1. Create address
        let shippingAddress = this.props.customer.shippingAddress;
        let addressId = this.getAddressId(1);
        let newShippingAddress = {
            addressId,
            line1: this.state.line1,
            street: this.state.street,
            city: this.state.city,
            landmark: this.state.landmark,
            state: this.state.state,
            country: this.state.country,
            zipCode: this.state.zipCode,
        }
        // 2. Add to the list
        shippingAddress.push(newShippingAddress);
        let customer = {
            _id: this.props.customer._id,
            shippingAddress,
        }
        this.props.addShippingAddress(customer);
        this.cancelAddress();
    }

    // add shipping address helper function
    //addBy is number variable that is added to the last char of addressId
    // eg -> a + (addBy = 1) = b, a + (addBy = 3) = d
    // default addBy should be 1
    getAddressId = (addBy) => {
        let shippingAddress = this.props.customer.shippingAddress;
        let { addressId } = this.props.customer.address;

        if(shippingAddress.length === 0) {
            // If not shipping address exists add b as last char
            addressId = this.props.customer.customerId + 'b';
        } else {
            // If shipping address exists -> remove and add incremented char at the end
            addressId = shippingAddress[shippingAddress.length - 1].addressId;
            // Updating last char by 1 char (a -> b, p -> q)
            let lastChar = addressId.slice(-1);
            if(lastChar === 'Z') lastChar = '0';
            if (lastChar === 'z') lastChar = 'A';
            lastChar = String.fromCharCode(lastChar.charCodeAt(0) + parseInt(addBy));
            addressId = addressId.substring(0, addressId.length - 1) + lastChar;
        }

        //check if addressId already existing
        let found = false;
        for(let i=0; i<shippingAddress.length; i++) {
            if(shippingAddress[i].addressId === addressId) {
                found = true;
            }
        }

        if(found) {
            // If addressId already exists addby 1 more
            return this.getAddressId(addBy+1);
        } else {
            return addressId;
        }
    }

    cancelAddress = () => {
        this.setState(this.initialState)
    }

    render() {
        return (
            <div>
                <button
                    className="mr-3 btn-style"
                    onClick={this.toggle}
                ><FontAwesomeIcon icon={faPlus} />
                </button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    <ModalHeader>
                        Add shipping address
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row >
                                <Col>
                                    <Label>Address</Label>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.line1} type="address" name="line1" placeholder="(Address (Unit/Area))" invalid={this.state.line1Error}/>
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.street} type="text" name="street" placeholder="(Street/Road)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.landmark} type="text" name="landmark" placeholder="(Landmark)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.city} type="text" name="city" placeholder="(City/Town)" invalid={this.state.cityError}/>
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.state} type="text" name="state" placeholder="(State/Province)" invalid={this.state.stateError}/>
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.country} type="text" name="country" placeholder="(Country)" invalid={this.state.countryError}/>
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4} >
                                    <Input onChange={this.onChange} value={this.state.zipCode} type="text" name="zipCode" placeholder="(Zip/Pin Code)" />
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addAddress}>Add</Button>
                        <Button color="secondary" onClick={this.cancelAddress}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { addShippingAddress })(AddShippingAddress);