import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, Form, FormGroup, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deletePayment } from '../../../../../actions/OrderActions';
import AddPaymentModal from './AddPaymentModal';
import moment from 'moment';

class PaymentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        payments: [],

        msg: '',
    }

    static propTypes = {
        deletePayment: PropTypes.func.isRequired,
    }

    componentDidUpdate = prevProps => {
        if(prevProps.order !== this.props.order) {
            this.setState({
                payments: this.props.order.payments,
            })
        }
    }

    toggle = () => {
        // Initialize Payments 
        this.setState({
            modal: !this.state.modal,
            payments: this.props.order.payments,
        })
    }

    removePayment = index => {
        let payments = this.state.payments.filter((pay, i) => i !== index);
        this.setState({
            payments,
        })
    }

    saveChanges = () => {
        const order = {
            _id: this.props.order._id,
            payments: this.state.payments,
        }

        this.setState({
            msg: 'Saving changes...'
        })

        this.props.deletePayment(order);

        setTimeout(()=> {
            this.cancelChanges();
        }, 2500)
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button className="btn-style" onClick={this.toggle} >
                    <FontAwesomeIcon color="blue" icon={faInfoCircle} />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Payment Details
                    </ModalHeader>
                    <ModalBody>
                        {this.state.payments.map((payment, i) => (
                            <div className="mb-3">
                                <center><small> {moment(payment.dateOfEntry).utcOffset("+05:30").format('DD MMM YYYY (LTS)')} </small></center>
                                
                                <Row className="mb-2 mt-1">
                                    <Col md={4}>
                                        Payment Date:
                                    </Col>
                                    <Col md={7}>
                                        {(payment.dateOfPayment).split('T')[0]}
                                    </Col>
                                    <Col md={1}>
                                        <button className="btn-style" onClick={this.removePayment.bind(this, i)} >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col md={4}>
                                        Amount:
                                    </Col>
                                    <Col md={8}>
                                        {payment.amount}
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col md={4}>
                                        Method:
                                    </Col>
                                    <Col md={8}>
                                        {payment.method}
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col md={4}>
                                        Notes:
                                    </Col>
                                    <Col md={8}>
                                        {payment.notes}
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <AddPaymentModal order={this.props.order} />
                    </ModalBody>

                    <ModalFooter>
                        {this.state.msg ? (
                            <Label className="float-left mr-auto"> {this.state.msg} </Label>
                        ) : null}

                        <Button className="float-right" color="primary" onClick={this.saveChanges} >Save Changes</Button>
                        <Button className="float-right" color="secondary" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { deletePayment })(PaymentDetails);