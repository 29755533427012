import {
    LOADING_EMPLOYEES,
    GET_EMPLOYEES,
    ADD_EMPLOYEE,
    UPDATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    UPDATE_CUSTOMER
} from './types';
import axios from 'axios';
import { addLog } from './LogActions';
import { returnErrors } from './ErrorActions';
import { tokenConfig } from './AuthActions'

export const loadEmployees = () => {
    return {
        type: LOADING_EMPLOYEES,
    }
}

export const getEmployees = () => (dispatch, getState) => {
    dispatch(loadEmployees());
    axios
        .get('/api/employees', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_EMPLOYEES,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data.msg, err.response.status)));
}

export const addEmployee = employee => (dispatch, getState) => {
    axios
        .post('/api/employees', employee, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_EMPLOYEE,
                payload: res.data
            })

            // Log
            const log = {
                type: ADD_EMPLOYEE,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: employee,
            }
            addLog(log, dispatch);
        })
        .catch(err => dispatch(returnErrors(err.response.data.msg, err.response.status)));
}

export const updateEmployee = employee =>(dispatch, getState) => {
    const id = employee._id;
    axios
        .put(`/api/employees/${id}`, employee, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_EMPLOYEE,
                payload: res.data
            })

            // Log
            const log = {
                type: UPDATE_EMPLOYEE,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: employee,
            }
            addLog(log, dispatch);
        })
        .catch(err => dispatch(returnErrors(err.response.data.msg, err.response.status)));
}

export const deleteEmployee = id => (dispatch, getState) => {
    axios
        .delete(`/api/employees/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_EMPLOYEE,
                payload: res.data
            })

            // Log
            const log = {
                type: DELETE_EMPLOYEE,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: id,
            }
            addLog(log, dispatch);
        })
        .catch(err => dispatch(returnErrors(err.response.data.msg, err.response.status)));
}