import axios from 'axios';
import { returnErrors } from './ErrorActions';
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL
} from '../actions/types';

// Check token and load user
export const loadUser = () => (dispatch, getState) => {
    // User loading
    dispatch({ type: USER_LOADING });

    axios.get('/api/auth/user', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data.msg, err.response.status));
            dispatch({
                type: AUTH_ERROR,
            })
        })
}

// Logout user
export const logout = () => {
    return {
        type: LOGOUT_SUCCESS
    }
}

// Register
export const register = user => dispatch => {
    // Headers
    const config = {
        header: {
            "Content-type": "application/json"
        }
    }

    // Request body
    // { userLevel, name, email, password } = user;
    const body = JSON.stringify({ user });

    axios.post('/api/users', body, config)
        .then(res => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data.msg, err.response.status, 'REGISTER_FAIL'));
            dispatch({
                type: REGISTER_FAIL,
            })
        })
}

// Login
export const login = user => dispatch => {
    // Headers
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    axios.post('/api/auth', user, config)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data.msg, err.response.status, 'LOGIN_FAIL'));
            dispatch({
                type: LOGIN_FAIL,
            })
        })
}

// Setup config header and token
export const tokenConfig = getState => {
    // Get token from local storage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    // if token, add to headers
    if(token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
}