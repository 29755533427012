import React from 'react';
import { Modal, Alert, Table, ModalHeader, ModalBody, Card, CardBody, ModalFooter, Button, Input, Label, Row, Col, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTyeps from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faRupeeSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatAmount, formatBackToNumber } from '../../../../constVarAndFunc/formats';
import { archiveOrder, addOrderSplit } from '../../../../../actions/OrderActions';
import { PARTIAL, VOID } from '../../../../constVarAndFunc/StatusOptions';

class PartialOrder extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        order1Products: [],
        order2Products: [],
        alertMsg: '',
    }

    static propTypes = {
        product: PropTyeps.object.isRequired,
        customProduct: PropTyeps.object.isRequired,
        archiveOrder: PropTyeps.func.isRequired,
        addOrderSplit: PropTyeps.func.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.updateOrderSummary();
        })
    }

    toggle = () => {
        const { order } = this.props;
        let order1Products = this.props.order.products;
        let order2Products = [];
        this.setState({
            modal: !this.state.modal,
            order1Products,
            shippingHandling1: order.orderSummary.shippingHandling,
            shippingHandling2: 0,
            discount1: order.orderSummary.discount,
            discount2: 0,
            stateTax1: order.orderSummary.stateTax,
            stateTax2: order.orderSummary.stateTax,
            centralTax1: order.orderSummary.centralTax,
            centralTax2: order.orderSummary.centralTax,
            order2Products,
        }, () => {
            this.updateOrderSummary();
        })
    }

    findProductName = code => {
        if(!code) return;
        let product = this.props.product.products.find(p => p.code === code);
        if(!product) product = this.props.customProduct.customProducts.find(p => p.code === code);
        return `${product.name} (${product.productType})`;
    }

    moveProductDown = index => {
        // Move product from order1Products to order2Products
        let product = this.state.order1Products.find((p,i) => i === index);
        this.setState({
            order1Products: this.state.order1Products.filter((p,i) => i !== index),
            order2Products: [...this.state.order2Products, product],
        }, () => {
            this.updateOrderSummary();
        })
    }

    moveProductUp = index => {
        // Move product from order2Products to order1Products
        let product = this.state.order2Products.find((p,i) => i === index);
        this.setState({
            order2Products: this.state.order2Products.filter((p,i) => i !== index),
            order1Products: [...this.state.order1Products, product],
        }, () => {
            this.updateOrderSummary();
        })
    }

    updateOrderSummary = () => {
        const { order } = this.props;
        // For order 1
        let totalAmount1 = 0;
        this.state.order1Products.forEach(product => {
            totalAmount1 += product.packageSize * product.numPackage * product.rate;
        })
        let shippingHandling1 = parseFloat(this.state.shippingHandling1);
        let discount1 = parseFloat(this.state.discount1);
        let totalBeforeTax1 = (parseFloat(totalAmount1) + parseFloat(shippingHandling1) - parseFloat(discount1));
        let stateTax1 = parseFloat(this.state.stateTax1);
        let stateTaxAmount1 = (parseFloat((stateTax1 / 100) * parseFloat(totalBeforeTax1))).toFixed(2);
        let centralTax1 = parseFloat(this.state.centralTax1);
        let centralTaxAmount1 = (parseFloat((centralTax1 / 100) * parseFloat(totalBeforeTax1))).toFixed(2);
        let grandTotal1 = formatAmount(parseFloat(totalBeforeTax1) + parseFloat(stateTaxAmount1) + parseFloat(centralTaxAmount1));
        
        // For order 2
        let totalAmount2 = 0;
        this.state.order2Products.forEach(product => {
            totalAmount2 += product.packageSize * product.numPackage * product.rate;
        })
        let shippingHandling2 = parseFloat(this.state.shippingHandling2);
        let discount2 = parseFloat(this.state.discount2);
        let totalBeforeTax2 = parseFloat(totalAmount2 + parseFloat(shippingHandling2) - parseFloat(discount2));
        let stateTax2 = parseFloat(this.state.stateTax2);
        let stateTaxAmount2 = (parseFloat((stateTax2 / 100) * parseFloat(totalBeforeTax2))).toFixed(2);
        let centralTax2 = parseFloat(this.state.centralTax2);
        let centralTaxAmount2 = (parseFloat((centralTax2 / 100) * parseFloat(totalBeforeTax2))).toFixed(2);
        let grandTotal2 = formatAmount(parseFloat(totalBeforeTax2) + parseFloat(stateTaxAmount2) + parseFloat(centralTaxAmount2));

        this.setState({
            totalAmount1,
            shippingHandling1,
            discount1,
            stateTax1,
            stateTaxAmount1,
            centralTax1,
            centralTaxAmount1,
            grandTotal1,
            
            totalAmount2,
            shippingHandling2,
            discount2,
            stateTax2,
            stateTaxAmount2,
            centralTax2,
            centralTaxAmount2,
            grandTotal2,

            alertMsg: '',
        })
    }

    splitOrders = () => {
        // Validation
        {
            if(this.state.order1Products.length === 0 || this.state.order2Products.length === 0) {
                this.setState({
                    alertMsg: 'Orders cannot be empty.',
                })
                return;
            }
        }
        // Payment separation
        let { payments } = this.props.order;
        let paymentsOrder1 = [];
        let paymentDue1 = formatBackToNumber(this.state.grandTotal1);
        let paymentsOrder2 = [];
        let paymentDue2 = formatBackToNumber(this.state.grandTotal2);
        
        payments.forEach(payment => {
            // Check if order 1 has due payments
            if(payment.amount <= paymentDue1) {
                paymentsOrder1.push(payment);
                paymentDue1 -= payment.amount
            } else if(payment.amount <= paymentDue2) {
                paymentsOrder2.push(payment);
                paymentDue2 -= payment.amount;
            } else if(payment.amount > paymentDue1) {
                paymentsOrder1.push({
                    dateOfPayment: payment.dateOfPayment,
                    notes: 'Split amount \n' + payment.notes,
                    amount: paymentDue1,
                    method: payment.method,
                })
                paymentsOrder2.push({
                    dateOfPayment: payment.dateOfPayment,
                    notes: 'Split amount \n' + payment.notes,
                    amount: payment.amount - paymentDue1,
                    method: payment.method,
                })
            }
        })
        // Status preset
        let order1 = {
            ...this.props.order,
            _id: undefined,
            products: this.state.order1Products,
            orderSummary: {
                totalAmount: this.state.totalAmount1,
                shippingHandling: this.state.shippingHandling1,
                discount: this.state.discount1,
                stateTax: this.state.stateTax1,
                centralTax: this.state.centralTax1,
                grandTotal: formatBackToNumber(this.state.grandTotal1),
            },
            payments: paymentsOrder1,
            notes: 'SPLIT ORDER \n (Original order ID: ' + (this.props.order._id).slice(-10) + ")\n" + this.props.order.notes,
        }
        
        let order2 = {
            ...this.props.order,
            _id: undefined,
            products: this.state.order2Products,
            orderSummary: {
                totalAmount: this.state.totalAmount2,
                shippingHandling: this.state.shippingHandling2,
                discount: this.state.discount2,
                stateTax: this.state.stateTax2,
                centralTax: this.state.centralTax2,
                grandTotal: formatBackToNumber(this.state.grandTotal2),
            },
            payments: paymentsOrder2,
            notes: 'SPLIT ORDER \n (Original order ID: ' + (this.props.order._id).slice(-10) + ")\n" + this.props.order.notes,
        }
        
        let previousOrder = {
            _id: this.props.order._id,
            status: VOID,
        }

        this.props.addOrderSplit(order1);
        this.props.addOrderSplit(order2)
        this.props.archiveOrder(previousOrder);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <Button color="primary" className="float-right" onClick={this.toggle}>
                    Split Order
                </Button>
                
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl" backdrop="static">
                    <ModalHeader>
                        Split Order
                    </ModalHeader>
                    <ModalBody>                        
                        <Row>
                            <Col md={12} >
                                <h5>
                                    <strong>Order 1 products:</strong>
                                </h5>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Code</th>
                                            <th>Package Size</th>
                                            <th>Num Package</th>
                                            <th>Quantity</th>{/***Autofill ***/}
                                            <th>Rate</th>
                                            <th>Amount</th>{/***Autofill ***/}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.order1Products.map((prod, i) => (
                                            <tr key={`${prod.code}${i}`}>
                                                <td>
                                                    <button className="btn-style" onClick={this.moveProductDown.bind(this, i)}>
                                                        <FontAwesomeIcon icon={faArrowCircleDown} />
                                                    </button>
                                                </td>
                                                <td>{i+1}</td>
                                                <td> {this.findProductName(prod.code)} </td>
                                                <td> {prod.code} </td>
                                                <td> {prod.packageSize} </td>
                                                <td> {prod.numPackage} </td>
                                                <td> {prod.packageSize * prod.numPackage} </td>
                                                <td> {prod.rate} </td>
                                                <td> {prod.packageSize * prod.numPackage * prod.rate} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} >
                                <h5>
                                    <strong>Order 2 products:</strong>
                                </h5>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Code</th>
                                            <th>Package Size</th>
                                            <th>Num Package</th>
                                            <th>Quantity</th>{/***Autofill ***/}
                                            <th>Rate</th>
                                            <th>Amount</th>{/***Autofill ***/}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.order2Products.map((prod, i) => (
                                            <tr key={`${prod.code}${i}`}>
                                                <td>
                                                    <button className="btn-style" onClick={this.moveProductUp.bind(this, i)}>
                                                        <FontAwesomeIcon icon={faArrowCircleUp} />
                                                    </button>
                                                </td>
                                                <td>{i+1}</td>
                                                <td> {this.findProductName(prod.code)} </td>
                                                <td> {prod.code} </td>
                                                <td> {prod.packageSize} </td>
                                                <td> {prod.numPackage} </td>
                                                <td> {prod.packageSize * prod.numPackage} </td>
                                                <td> {prod.rate} </td>
                                                <td> {prod.packageSize * prod.numPackage * prod.rate} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={6}>
                                <Card className="shadow">
                                    <CardBody>
                                        <h5>Order 1 summary</h5>
                                        <Row className="mt-1"> 
                                            <Col md={6}>
                                                <Label>Total Amount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>{this.state.totalAmount1}</Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Shipping and Handling: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Input type="number" min="0" value={this.state.shippingHandling1} name="shippingHandling1" onChange={this.onChange} />
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Discount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Input type="number" min="0" value={this.state.discount1} name="discount1" onChange={this.onChange} />
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col md={6}>
                                                <h6>Taxable amount: </h6>
                                            </Col>
                                            <Col md={6}>
                                                <Label> {this.state.taxableAmount1} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Label>State tax: %</Label>
                                            </Col>
                                            <Col md={3}>
                                                <Input type="number" min="0" value={this.state.stateTax1} name="stateTax1" onChange={this.onChange} />
                                            </Col>
                                            <Col md={6} >
                                                <Label> {this.state.stateTaxAmount1} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Label>Central tax: %</Label>
                                            </Col>
                                            <Col md={3} >
                                                <Input type="number" min="0" value={this.state.centralTax1} name="centralTax1" onChange={this.onChange} />
                                            </Col>
                                            <Col md={6} >
                                                <Label> {this.state.centralTaxAmount1} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <h3>Grand Total: </h3>
                                            </Col>
                                            <Col md={6}>
                                                <h3><FontAwesomeIcon icon={faRupeeSign}/> {this.state.grandTotal1} </h3>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card className="shadow">
                                    <CardBody>
                                        <h5>Order 2 summary</h5>
                                        <Row className="mt-1"> 
                                            <Col md={6}>
                                                <Label>Total Amount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>{this.state.totalAmount2}</Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Shipping and Handling: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Input type="number" min="0" value={this.state.shippingHandling2} name="shippingHandling2" onChange={this.onChange} />
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Discount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Input type="number" min="0" value={this.state.discount2} name="discount2" onChange={this.onChange} />
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col md={6}>
                                                <h6>Taxable amount: </h6>
                                            </Col>
                                            <Col md={6}>
                                                <Label> {this.state.taxableAmount2} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Label>State tax: %</Label>
                                            </Col>
                                            <Col md={3}>
                                                <Input type="number" min="0" value={this.state.stateTax2} name="stateTax2" onChange={this.onChange} />
                                            </Col>
                                            <Col md={6} >
                                                <Label> {this.state.stateTaxAmount2} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Label>Central tax: %</Label>
                                            </Col>
                                            <Col md={3} >
                                                <Input type="number" min="0" value={this.state.centralTax2} name="centralTax2" onChange={this.onChange} />
                                            </Col>
                                            <Col md={6} >
                                                <Label> {this.state.centralTaxAmount2} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <h3>Grand Total: </h3>
                                            </Col>
                                            <Col md={6}>
                                                <h3><FontAwesomeIcon icon={faRupeeSign}/> {this.state.grandTotal2} </h3>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        {this.state.alertMsg ? (
                            <Alert className="float-left" color="danger">
                                {this.state.alertMsg}
                            </Alert>
                        ) : null}
                        <Button color="primary" onClick={this.splitOrders}>Split Order</Button>
                        <Button color="secondary" onClick={this.cancelChanges}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    product: state.product,
    customProduct: state.customProduct,
})

export default connect(mapStateToProps, { archiveOrder, addOrderSplit })(PartialOrder);