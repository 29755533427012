import React from 'react';
import { Label, Card, CardBody, Button, Input, Alert, Row,  } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

class TryDemo extends React.Component {
    state = {
        name: '',
        email: '',
        sendingEmail: false,

        alertMsg: '',
        alertMsgColor: '',
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    goHome = () => {
        window.location.pathname = "/"
    }

    sendPassword = () => {
        this.setState({
            alertMsg: '',
            alertMsgColor: '',
            sendingEmail: true,
        })
        axios.post('/api/demo/', { name: this.state.name, email: this.state.email })
            .then(res => {
                this.setState({
                    alertMsg: res.data.msg,
                    alertMsgColor: 'success',
                })
            })
            .catch(err => {
                if(err.response) {
                    this.setState({
                        alertMsgColor: 'danger',
                        alertMsg: err.response.data.msg,
                    })
                }
            })
            .finally(() => {
                this.setState({
                    sendingEmail: false,
                })
            })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.sendPassword();
        }
    }

    render() {
        return (
            <div style={{background: "#F5F5DC"}}>
                <div className="login-page-image">
                    <div className="login-card shadow-lg">
                        <Card >
                            <CardBody >
                                <Button color="primary" className="float-left" onClick={this.goHome}>
                                    <FontAwesomeIcon size="1x" icon={faHome } />
                                </Button>
                                <h4 className="d-flex justify-content-center">Try Demo</h4>
                                {this.state.alertMsg ? (
                                    <Alert color={this.state.alertMsgColor}>
                                        {this.state.alertMsg}
                                    </Alert>
                                ) : null}
                                <hr />
                                <Label>Enter your email to recieve a temporary password.</Label>
                                <Row className="p-3">
                                    <Label>Full Name: </Label>
                                    <Input 
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        placeholder="Enter Full Name"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3">
                                    <Label>Email</Label>
                                    <Input 
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        placeholder="Enter email"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3" >
                                    <Button block color="primary" disabled={this.state.sendingEmail} onClick={this.sendPassword} >Send Password</Button>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default TryDemo;