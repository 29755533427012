import React from 'react';
import { Alert, Button, Card, CardHeader, CardBody, CardImg, Col, Form, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../actions/AuthActions';
import { clearErrors } from '../actions/ErrorActions';
import Clienties from '../assets/images/clienties1.png';

class LoginPage extends React.Component {
    state = {
        email: '',
        password: '',
        alertMsg: '',
        isSandbox: window.location.href.includes('sandbox'),
    }

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        clearErrors: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.isAuthenticated) {
            window.location.pathname = "/dashboard/"
        }

        if(prevProps.error !== this.props.error) {
            const { msg, id } = this.props.error;
            if(id === 'LOGIN_FAIL') {
                this.setState({
                    alertMsg: msg
                })
            }
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            alertMsg: '',
        })
        this.props.clearErrors();
    }

    loginUser = () => {
        let user = {
            email: this.state.email,
            password: this.state.password
        }
        this.props.login(user);
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.loginUser();
        }
    }

    redirectForgot = () => {
        window.location.pathname = "/forgot/"
    }

    redirectDemo = () => {
        window.location.pathname = "/demo/"
    }

    render() {
        return (
            <div style={{background: "#F5F5DC"}}>
                <div className="login-page-image">
                    <div className="login-card shadow-lg">
                        <Card >
                            <CardHeader>
                                <center>
                                    <img src={Clienties} style={{height: '3rem'}} />
                                </center>
                            </CardHeader>
                            <CardBody >
                                <h4 className="d-flex justify-content-center">Log In</h4>
                                {this.state.alertMsg ? (
                                    <Alert color="danger">
                                        {this.state.alertMsg}
                                    </Alert>
                                ) : null}
                                <Row className="p-3">
                                    <Label>Email</Label>
                                    <Input 
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        placeholder="Enter email"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3">
                                    <Label>Password</Label>
                                    <Input 
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                        placeholder="Password"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3" >
                                    <Button block color="primary" onClick={this.loginUser} >Login</Button>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={6} className="d-flex justify-content-center ">
                                        <Button size="sm" onClick={this.redirectForgot} color="link">forgot password</Button>
                                    </Col>
                                    <Col md={6} className="d-flex justify-content-center ">
                                        {this.state.isSandbox ? (
                                            <Button size="sm" onClick={this.redirectDemo} color="link">try demo</Button>
                                        ) : null}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error,
    user: state.user,
})

export default connect(mapStateToProps, { login, clearErrors } )(LoginPage);