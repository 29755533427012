import React from 'react';
import { Button, Card, CardBody, CardFooter, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { updateOrderAddress } from '../../../../../actions/OrderActions';
import { formatAddress } from '../../../../constVarAndFunc/formats';

class ChangeOrderAddress extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        selectedAddressId: this.props.addressId
    }

    static propTypes = {
        updateOrderAddress: PropTypes.func.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    changeAddress = e => {
        this.setState({
            selectedAddressId : e.target.value,
        })
    }

    getAddress = () => {
        let customer = this.props.customer;
        let addresses = [];
        addresses.push(customer.address);
        customer.shippingAddress.forEach(address => {
            addresses.push(address);
        })

        return addresses;
    }

    saveChanges = () => {
        console.log(this.state.selectedAddressId);
        let order = {
            _id: this.props._id,
            addressId: this.state.selectedAddressId
        }
        this.props.updateOrderAddress(order)
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        let allAddress = this.getAddress();
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}> <FontAwesomeIcon icon={faEdit}/> </button>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader>
                        Change Address
                    </ModalHeader>
                    <ModalBody>
                        Available addresses:

                        {allAddress.map(address => (
                            <Card key={address.addressId} className="m-2 p-1" style={{whiteSpace: "pre-wrap"}}>
                                <CardBody style={address.addressId === this.state.selectedAddressId ? {background: 'aliceblue'} : null}>
                                    <Input type="radio" name="address" checked={this.state.selectedAddressId === address.addressId} value={address.addressId} onChange={this.changeAddress}/> {"\n"}
                                    {formatAddress(address)}
                                </CardBody>
                            </Card>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveChanges}>Save Changes</Button>
                        <Button color="secondary" onClick={this.cancelChanges}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect( null , { updateOrderAddress } )(ChangeOrderAddress);