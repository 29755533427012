import {
    LOADING_REPORTS,
    GET_REPORTS,
    ADD_REPORT,
    UPDATE_REPORT,
    DELETE_REPORT,
    SUBMIT_REPORT,
} from './types';
import axios from 'axios';
import { tokenConfig } from './AuthActions';
import { addLog } from './LogActions';

export const loadingResports = () => {
    return {
        type: LOADING_REPORTS,
    }
}

export const getReports = () => (dispatch, getState) => {
    dispatch(loadingResports);
    axios
        .get('/api/reports/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_REPORTS,
                payload: res.data,
            })
        })
        .catch(err => {
            console.log(err);
        })
}

export const addReport = report => (dispatch, getState) => {
    axios
        .post('/api/reports/', report, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_REPORT,
                payload: res.data,
            })

            // Add log
            const log = {
                type: ADD_REPORT,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: report,
            }
            addLog(log, dispatch);
        })
        .catch(err => {
            console.log(err);
            return false;
        })
}

export const submitReport = report => (dispatch, getState) => {
    const id = report._id;
    axios
        .put(`/api/reports/${id}`, report, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: SUBMIT_REPORT,
                payload: res.data,
            })

            // Add log
            const log = {
                type: SUBMIT_REPORT,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: report,
            }
            addLog(log, dispatch);
        })
        .catch(err => {
            console.log(err);
        })
}

export const deleteReport = id => (dispatch, getState) => {
    axios
        .delete(`/api/reports/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_REPORT,
                payload: id,
            })
        })
}