import {
    LOADING_ORDERS,
    GET_ORDERS,
    ADD_ORDER,
    DELETE_ORDER,
    UPDATE_ORDER,
    ADD_PAYMENT,
    UPDATE_ORDER_NOTES,
    UPDATE_ORDER_ADDRESS,
    UPDATE_ORDER_PRODUCT,
    UPDATE_ORDER_DOD,
    UPDATE_ORDER_STATUS,
    STATUS_SHIPPING_CHALLAN,
    DELETE_PAYMENT,
    UPDATE_ORDER_CHALLAN,
    ADD_ORDER_SPLIT,
    ARCHIVE_ORDER,
} from '../actions/types'

const initialState = {
    orders: [],
    isLoading: false,
}

export default function(state=initialState, action) {
    switch(action.type) {
        case LOADING_ORDERS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_ORDERS:
            return {
                ...state,
                isLoading: false,
                orders: action.payload,
            }
        case DELETE_ORDER:
            return {
                ...state,
                orders: state.orders.filter(order => order._id !== action.payload)
            }
        case ADD_ORDER:
        case ADD_ORDER_SPLIT:
            return {
                ...state,
                orders: [action.payload, ...state.orders]
            }
        case ADD_PAYMENT:
        case DELETE_PAYMENT:
        case UPDATE_ORDER_NOTES:
        case UPDATE_ORDER_ADDRESS:
        case UPDATE_ORDER_STATUS:
        case UPDATE_ORDER_CHALLAN:
        case STATUS_SHIPPING_CHALLAN:
        case UPDATE_ORDER_PRODUCT:
        case UPDATE_ORDER_DOD:
        case UPDATE_ORDER:
        case ARCHIVE_ORDER:
            return {
                ...state,
                orders: state.orders.map(order => order._id === action.payload._id ? action.payload : order)
            }
        default:
            return state;
    }
}