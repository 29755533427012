import { ADD_USER, GET_USERS, LOADING_USERS, UPDATE_USER } from '../actions/types';

const initialState = {
    isLoading: false,
    users: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_USERS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_USERS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            }
        case ADD_USER:
            return {
                ...state,
                users: [...state.users, action.payload],
            }
        case UPDATE_USER:
            return {
                ...state,
                users: state.users.map(user => user._id === action.payload._id ? action.payload : user)
            }
        default:
            return state
    }
}