import moment from 'moment';

export const formatAmount = (amount) => {
    if(!amount) return 0;
    if((typeof amount) === "number") if(amount % 1 !== 0) amount = amount.toFixed(2);
    return (amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}

export const formatAddress = (address, noId) => {
    if(!address) return;
    let add = '';
    if(!noId) if(address.addressId) add += `(id: ${address.addressId}) \n`
    if(address.line1) add += address.line1 + ', ';
    if(address.street) add += address.street + ', ';
    add += '\n';
    if(address.landmark) add += address.landmark + ', \n';
    if(address.city) add += address.city + ', ';
    if(address.zipCode) add += ` (${address.zipCode}), `;
    add += '\n';
    if(address.state) add += `${address.state}, `;
    if(address.country) add += `${address.country}`;
    // add += '.';
    return add;
}

export const formatBackToNumber = number => {
    if(typeof(number) === 'number') return number;
    if(!number) return 0;
    let [ number1 ] = number.split('/');
    let [int, dec] = number1.split('.');

    let num = 0;
    if(int) {
        int = int.replace(',', '');
        num += parseFloat(int.replace(/[^0-9]/g, ''));
    }
    if(dec) {
        dec = dec.replace(/[^0-9]/g, '');
        num += parseFloat((dec / (Math.pow(10, dec.length))));
    }
    return num;
}

export const formatDate = date => {
    if(!date) return;
    return moment.utc(date).format('DD MMM YYYY')
}

