import React from 'react';
import { Alert, Modal, Button, ModalHeader, ModalFooter, ModalBody, Label, Input, Row, Form, FormGroup } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',

        alertMsg: '',
        alertMsgColor: '',
    }

    static propTypes = {
        token: PropTypes.string.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.changePassword();
        }
    }

    changePassword = () => {
        this.setState({
            alertMsgColor: '',
            alertMsg: '',
        })
        const user = {
            email: this.props.email,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
        }

        // Add auth token in config - headers
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }
        
        if(this.props.token) {
            config.headers['x-auth-token'] = this.props.token;
        }

        axios.post('/api/users/passwordchange', user, config)
            .then(res => {
                this.setState({
                    alertMsgColor: 'success',
                    alertMsg: res.data.msg,
                })
                setTimeout(() => {
                    this.cancelChanges();
                }, 3500);
            })
            .catch(err => {
                if(err.response) {
                    this.setState({
                        alertMsg: err.response.data.msg,
                        alertMsgColor: 'danger'
                    })
                }
            })
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <Button block className="mt-3" color="info" size="sm" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faKey} className="mr-3" />
                    Change password
                </Button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    backdrop="static"
                    size="sm"
                    centered='true'
                >
                    <ModalHeader>
                        Change Password
                    </ModalHeader>
                    <ModalBody style={{padding: '2rem'}}>
                        {this.state.alertMsg ? (
                            <Alert color={this.state.alertMsgColor}>
                                {this.state.alertMsg}
                            </Alert>
                        ) : null}
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup >
                                <Row className="mb-2">
                                    <Label>Old Password: </Label>
                                    <Input 
                                        type="password"
                                        name="oldPassword"
                                        value={this.state.oldPassword}
                                        onChange={this.onChange}
                                        placeholder="Enter old password"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>

                                <Row className="mb-2">
                                    <Label>New Password: </Label>
                                    <Input 
                                        type="password"
                                        name="newPassword"
                                        value={this.state.newPassword}
                                        onChange={this.onChange}
                                        placeholder="Enter new password"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>

                                <Row className="mb-2">
                                    <Label>Confirm Password: </Label>
                                    <Input 
                                        type="password"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.onChange}
                                        placeholder="Confirm password"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.changePassword}>Change Password</Button>
                        <Button color="secondary" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
})

export default connect(mapStateToProps, {})(ChangePassword);