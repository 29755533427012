import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { damagedProducts } from '../../../actions/ProductActions';
import { customProductsDamaged } from '../../../actions/CustomProductActions';
import { addReport, submitReport } from '../../../actions/ReportActions';
import PropTpes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DAMAGED_PRODUCTS } from '../../../actions/types';
import { ADMINISTRATION_MANAGEMENT } from '../../constVarAndFunc/UserLevel';
import { faDownload, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

class DamagedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        product: '',
        invalidProduct: false,
        productsList: [],
        alertMsg: '',

        reportName: '',

        // Proceed to add stock to inventory
        // Show save with checkbox when proceed = true
        proceed: false,
    }

    static propTypes = {
        user: PropTpes.object.isRequired,
        product: PropTpes.object.isRequired,
        customProduct: PropTpes.object.isRequired,
        addReport: PropTpes.func.isRequired,
        submitReport: PropTpes.func.isRequired,
        damagedProducts: PropTpes.func.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    onChangeTable = e => {
        if(e.target.value < 0) return;
        // Update newStock input
        // name === " newStock"
        let [index, name] = (e.target.name).split("-");
        let productsList = this.state.productsList;
        productsList.forEach((p, i) => {
            if(i.toString() === index) {
                p[name] = e.target.value
            }
        })
        this.setState({
            productsList,
        })
    }

    toggle = () => {
        let productsList = [];
        if(this.props.report) {
            if(this.props.report.payload.products) {
                productsList = this.props.report.payload.products;
            }
        }
        this.setState({
            modal: !this.state.modal,
            productsList,
        })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.addProductToList();
        }
    }

    addProductToList = () => {
        if(!this.state.product) return;
        // find product and dont add again
        let product = this.props.product.activeProducts.find(p => p.code.toLowerCase() === this.state.product.toLowerCase());
        // If product not found check for custom products
        if(!product) product = this.props.customProduct.activeCustomProducts.find(p => p.code.toLowerCase() === this.state.product.toLowerCase());
        // No duplicates
        if(product) if(this.state.productsList.find(p => p.code === product.code)) return;

        if(product) {
            this.setState({
                invalidProduct: false,
            })
        } else {
            this.setState({
                invalidProduct: true,
            })
            return;
        }
        product.damagedStock = 0;
        this.setState({
            productsList: [...this.state.productsList, product],
            product: '',
        })
    }

    removeProductsFromList = index => {
        this.setState({
            productsList: this.state.productsList.filter((p,i) => i !== index),
        })
    }

    clearSearch = () => {
        this.setState({
            product: '',
        })
    }

    downloadReport = () => {
        this.setState({
            productsList: this.props.report.payload.products,
        },() => {
            this.saveReportAsPDF();
        })
    }

    saveReportAsPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text(7, 15, "Damaged Products report: " + this.props.report.reportName);
        doc.setFontSize(15);
        doc.text(7, 25, "Date and time: " + moment(this.props.report.reportDate).utcOffset("+05:30").format('DD MMM YYYY (LTS)'));
        doc.setFontSize(15);
        doc.text(7, 33, "Reported by: " + this.props.report.user.name);
        doc.setFontSize(10);
        doc.autoTable({
            startY: 37,
            margin: { horizontal: 7 },
            head: [['#', 'Products', 'Code', 'Pages', 'GSM', 'P.Size', 'Damaged Stock', 'Quantity', '']],
            body: this.state.productsList.map((product, i) => (
                [`${i+1}`, `${product.name} | ${product.productType}`, `${product.code}`, `${product.pages}`, `${product.gsm}`, `${product.packageSize}`, `${product.damagedStock}`, `${product.damagedStock * product.packageSize}`]
                ))
            });
        
        doc.text(`Report ID: ${this.props.report._id}`, 7, doc.autoTable.previous.finalY + 25);
        doc.save('damaged_products_' + moment(this.props.report.reportDate).format('YYYY-MM-DD'));
    }

    saveChanges = () => {
        this.setState({
            alertMsg: 'Saving changes...',
        })

        this.saveReportAsPDF();
        this.state.productsList.forEach(product => {
            product.stock -= parseFloat(product.damagedStock);

            // Check if product is custom or market product
            let isCustomProduct = this.props.customProduct.activeCustomProducts.find(p => p.code === product.code);
            if(isCustomProduct) {
                this.props.customProductsDamaged(product);
            } else {
                this.props.damagedProducts(product);
            }
        })

        // Update report to submitted after updating
        const report = {
            _id: this.props.report._id,
            isSubmitted: true,
            payload: {
                products: this.state.productsList,
            },
        }
        this.props.submitReport(report);
        setTimeout(() => {
            this.cancelChanges();
        }, 2000)
    }

    submitReport = () => {
        // Validation
        if(this.state.productsList.length === 0) {
            this.setState({
                alertMsg: 'Products list is empty.'
            })
            return;
        }

        const report = {
            reportName: this.state.reportName,
            user: this.props.user,
            reportType: DAMAGED_PRODUCTS,
            payload: {
                products: this.state.productsList,
            }
        }

        this.props.addReport(report);
        this.cancelChanges();
    }

    proceedChanges = () => {
        this.setState({
            proceed: true,
        })
    }

    goBack = () => {
        this.setState({
            proceed: false,
        })
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        let privilege = false;
        if(this.props.user.userLevel === ADMINISTRATION_MANAGEMENT) {
            privilege = true;
        }

        return (
            <div>
                {this.props.download ? (
                    <button className="btn-style" onClick={this.downloadReport}>
                        {/* Only download the report that has been submitted */}
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                ) : (
                    <Button color="success" onClick={this.toggle} >
                        Damaged Products
                    </Button>
                )}

                <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl" backdrop="static"  >
                    <ModalHeader>
                        Damaged Products Report
                    </ModalHeader>

                    <ModalBody style={{height:"70vh", maxHeight: "70vh", overflowY: "scroll"}}>
                        <Row>
                            <Col md={6}>
                                <Input 
                                    type="text"
                                    autoComplete="off"
                                    list="products"
                                    name="product"
                                    value={this.state.product}
                                    onChange={this.onChange}
                                    onKeyDown={this.handleKeyDown}
                                    invalid={this.state.invalidProduct}
                                    placeholder="Enter Product Code | Name"
                                />
                                <datalist id="products">
                                    {this.props.product.activeProducts.map(product => (
                                        <option key={product.code} value={product.code} > {product.code} | {product.name} | {product.productType} </option>
                                    ))}
                                    {this.props.customProduct.activeCustomProducts.map(product => (
                                        <option key={product.code} value={product.code} > {product.code} | {product.name} | {product.productType} </option>
                                    ))}
                                </datalist>
                            </Col>

                            <Col md={2}>
                                <Button color="primary" onClick={this.addProductToList}>
                                    Add
                                </Button>
                                <Button color="link" onClick={this.clearSearch}>
                                    Clear
                                </Button>
                            </Col>

                            <Col md={4} >
                                {this.props.report ? (
                                    <Label> {this.props.report.reportName} </Label>
                                ) : (
                                    <Row>
                                        <Col md={5} >
                                            <Label>Report Name: </Label>
                                        </Col>
                                        <Col md={7} >
                                            <Input type="text" name="reportName" value={this.state.reportName} onChange={this.onChange} />
                                        </Col>
                                    </Row>
                                )}
                            </Col>

                        </Row>

                        <Row className="mt-4 p-3">
                            <Table hover >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Code</th>
                                        <th>Pages</th>
                                        <th>GSM</th>
                                        <th>P.Size</th>
                                        <th>Damaged Stock</th>
                                        <th>Quantity</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.productsList.map((product, i) => (
                                        <tr>
                                            <td>{i+1}</td>
                                            <td> {product.name} ({product.productType}) </td>
                                            <td> {product.code} </td>
                                            <td> {product.pages} </td>
                                            <td> {product.gsm} </td>
                                            <td> {product.packageSize} </td>
                                            <td>
                                                <Input 
                                                    style={{width: "6rem"}}
                                                    type="number"
                                                    name={`${i}-damagedStock`}
                                                    value={product.damagedStock}
                                                    onChange={this.onChangeTable}
                                                    disabled={this.state.proceed}
                                                />
                                            </td>
                                            <td> {((parseFloat(product.damagedStock) || 0)) * product.packageSize} </td>
                                            {this.state.proceed ? (
                                                <td></td>
                                            ) : (
                                                <td>
                                                    <Button color="danger" size="sm" onClick={this.removeProductsFromList.bind(this, i)}>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        {this.state.alertMsg ? (
                            <Label className="float-left" >
                                {this.state.alertMsg}
                            </Label>
                        ) : null}
                        {!this.props.report ? (
                            <Button color ="primary" onClick={this.submitReport} >Submit Report</Button>
                        ) : null}
                        {privilege && this.props.report ? (
                            <div>
                                {this.state.proceed ? (
                                    <div>
                                        <div className="float-right">
                                            <Button color="primary" onClick={this.saveChanges}>Save Chnages</Button>
                                        </div>
                                        <div className="float-left mr-4">
                                            <Button color="secondary" onClick={this.goBack} >Back</Button>
                                        </div>
                                    </div>
                                ) : (
                                    <Button color="primary" onClick={this.proceedChanges} >Proceed</Button>
                                )}
                            </div>
                        ) :null}
                        <Button color="cancel" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    product: state.product,
    customProduct: state.customProduct,
})

export default connect(mapStateToProps, { addReport, damagedProducts, customProductsDamaged, submitReport })(DamagedProducts);