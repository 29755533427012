import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateCustomer } from '../../../../actions/CustomerActions';

class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;

        // Creating category options with id
        this.categoryOptions = [];
        this.categoryMap = new Map();
        this.categoryMap.set('College/University', '100');
        this.categoryMap.set('Community', '101');
        this.categoryMap.set('Corporation', '102');
        this.categoryMap.set('Export', '103');
        this.categoryMap.set('Government', '104');
        this.categoryMap.set('Person', '105');
        this.categoryMap.set('Retail', '106');
        this.categoryMap.set('School', '107');
        this.categoryMap.set('Whole Sale', '108');
        this.categoryMap.set('Others', '109');
        for(const key of this.categoryMap.keys()) {
            this.categoryOptions.push(key);
        }
    }
    
    static propTypes = {
        updateCustomer: PropTypes.func.isRequired,
    }

    state = {
        modal: false,
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });

        let { customer } = this.props;
        this.setState({
            category: customer.category,
            customerName: customer.customerName,
            customerNameError: '',
            email: customer.email,
            emailError: '',
            mobileNumber: customer.mobileNumber,
            mobileNumberError: '',
            homeNumber: customer.homeNumber,
            organizationName: customer.organizationName,

            line1: customer.address.line1,
            street: customer.address.street,
            landmark: customer.address.landmark,
            city: customer.address.city,
            state: customer.address.state,
            country: customer.address.country,
            zipCode: customer.address.zipCode,

            igst: customer.igst,
            cgst: customer.cgst,
            notes: customer.notes,
        })
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });

        // Removing error form feeedback on change
        if(e.target.name === 'customerName') {
            this.setState({
                customerNameError: ''
            })
        }

        if(e.target.name === 'mobileNumber') {
            this.setState({
                mobileNumberError: ''
            })
        }

        if(e.target.name === 'email') {
            this.setState({
                emailError: ''
            })
        }
    }

    editCustomerProfile = () => {
        { // Validate input fields. return if error true
            let error = false;
            if (!this.state.category) {
                this.setState({
                    categoryError: 'Please select a category'
                })
                error = true;
            }

            if(!this.state.customerName) {
                this.setState({
                    customerNameError: 'Please Enter a name'
                })
                error = true;
            } else {
                if(this.state.customerName.length < 3) {
                    this.setState({
                    customerNameError: 'Please Enter a name'
                })
                error = true;
                }
            }

            if(this.state.mobileNumber) {
                if(this.state.mobileNumber.length < 10) {
                    this.setState({
                        mobileNumberError: 'Minimum length of 10 is required'
                    })
                    error = true;
                }
            }

            if(this.state.email) {
                if(!this.state.email.includes('@') || !this.state.email.includes('.')) {
                    this.setState({
                        emailError: 'Invalid Email'
                    })
                    error = true;
                }
            }

            if(error) return;
        } // end of validation

        // Create Customer object
        // 1. Create customerId
        let customerId = this.props.customer.customerId;
        // 2. If addressId is null and new address updated add addressId
        let addressId = this.props.customer.address.addressId;
        if(!addressId) {
            if(this.state.line1 || this.state.street || this.state.landmark || this.state.city || this.state.state || this.state.country || this.state.zipCode) {
                console.log(customerId + 'a');
                addressId = customerId + 'a';
            }
        }
        // 2. Customer Data
        let updateCustomer = {
            // ...this.props.customer,
            _id: this.props.customer._id,
            customerId,
            category: this.state.category,
            customerName: this.state.customerName,
            organizationName: this.state.organizationName,
            mobileNumber: this.state.mobileNumber,
            homeNumber: this.state.homeNumber,
            email: this.state.email,
            address: {
                addressId,
                line1: this.state.line1,
                street: this.state.street,
                landmark: this.state.landmark,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                zipCode: this.state.zipCode,
            },
            date: this.props.customer.date,
            gstin: this.state.gstin,
            uin: this.state.uin,
            notes: this.state.notes,
        }
        
        // Add customer to redux and DB
        this.props.updateCustomer(updateCustomer);
        this.cancelCustomer();
    }

    cancelCustomer = () => {
        this.setState( this.initialState )
    }

    render() {
        return(
            <div>
                <button
                    className="btn-style"
                    onClick={this.toggle}
                ><FontAwesomeIcon icon={faEdit} /></button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    <ModalHeader>
                        Edit customer
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Category *</Label>
                                        <Input 
                                            invalid={this.state.categoryError} 
                                            value={this.state.category} 
                                            onChange={this.onChange} 
                                            type="select" 
                                            name="category" 
                                            placeholder="(Contact Type)"
                                        >
                                            <option selected disabled>Select a Category...</option>
                                            {this.categoryOptions.map(option => (
                                                <option>{option}</option>
                                            ))}
                                        </Input>
                                        <FormFeedback> {this.state.categoryError} </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Person Name: *</Label>
                                            <Input 
                                                type="text"
                                                invalid={this.state.customerNameError}
                                                onChange={this.onChange}
                                                name="customerName"
                                                value={this.state.customerName} 
                                                placeholder="(Name)" />
                                             <FormFeedback > {this.state.customerNameError} </FormFeedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Organization Name:</Label>
                                            <Input 
                                                type="text"
                                                onChange={this.onChange}
                                                name="organizationName"
                                                value={this.state.organizationName} 
                                                placeholder="(Company Name)" /> 
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <br />
                            <Row >
                                <Col md={12} >
                                    <Label>Contact Number</Label>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Mobile:</Label>
                                            <Input 
                                                type="text"
                                                onChange={this.onChange}
                                                name="mobileNumber"
                                                value={this.state.mobileNumber}
                                                invalid={this.state.mobileNumberError} 
                                                placeholder="(Mobile Number)" />
                                            <FormFeedback> {this.state.mobileNumberError} </FormFeedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Home:</Label>
                                            <Input 
                                                type="text"
                                                onChange={this.onChange}
                                                name="homeNumber"
                                                value={this.state.homeNumber}
                                                placeholder="(Home Number)" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col md={12} >
                                    <Form>
                                        <FormGroup>
                                        <Label>Email:</Label>
                                        <Input 
                                            type="email"
                                            onChange={this.onChange}
                                            name="email"
                                            value={this.state.email}
                                            invalid={this.state.emailError} 
                                            placeholder="(email address)" />
                                            <FormFeedback> {this.state.emailError} </FormFeedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <br />
                            <Row >
                                <Col>
                                    <Label>Address</Label>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.line1} type="address" name="line1" placeholder="(Address (Unit/Area))" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.street} type="text" name="street" placeholder="(Street/Road)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.landmark} type="text" name="landmark" placeholder="(Landmark)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.city} type="text" name="city" placeholder="(City/Town)" />
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.state} type="text" name="state" placeholder="(State/Province)" />
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.country} type="text" name="country" placeholder="(Country)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4} >
                                    <Input onChange={this.onChange} value={this.state.zipCode} type="text" name="zipCode" placeholder="(Zip/Pin Code)" />
                                </Col>
                            </Row>
                            
                            <Row form className="mt-3">
                                <Col md={6} >
                                    <Label>IGST</Label>
                                    <Input onChange={this.onChange} value={this.state.igst} type="text" name="igst" placeholder="(IGST)" />
                                </Col>

                                <Col md={6} >
                                    <Label>CGST</Label>
                                    <Input onChange={this.onChange} value={this.state.cgst} type="text" name="cgst" placeholder="(CGST)" />
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col md={12} >
                                    <Label>Notes:</Label>
                                    <Input 
                                        type="textarea"
                                        onChange={this.onChange}
                                        name="notes"
                                        value={this.state.notes}
                                        placeholder="(Enter notes here...)" /> 
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Label className="mr-auto" color="red"> {this.state.errorMsg} </Label>
                        <Button className="float-right" color="primary" onClick={this.editCustomerProfile}>Edit Customer</Button>
                        <Button className="float-right" color="secondary" onClick={this.cancelCustomer}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { updateCustomer })(EditProfile);