import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import {
  ModalFooter,
} from "react-bootstrap";
import { Button, Card, CardBody, Col, Label, Modal, ModalBody, Row } from "reactstrap";
import { deleteProduct, archiveProduct } from '../../../actions/ProductActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class DeleteProductModal extends Component {

    state ={
        modal: false
    }

    static propTypes = {
        deleteProduct: PropTypes.func.isRequired,
        archiveProduct: PropTypes.func.isRequired
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    deleteSelectedProduct = id => {
        // this.props.deleteProduct(id);
      let product = {
        _id: id,
        isActive: false,
      }
      this.props.archiveProduct(product);
      this.toggle();
    }

    render() {
      let { product } =this.props;
        return (
          <div>
            <Button
              variant="link"
              size="sm"
              className="hover-btn"
              onClick={this.toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>

            <Modal isOpen={this.state.modal} toggle={this.toggle}>
              <ModalBody>
                <h4>Are you sure you want to delete this?</h4>
                <Card>
                  <CardBody>
                      <Row>
                          <Col md={4} >
                              <Label><strong>Code: </strong></Label>
                          </Col>
                          <Col md={8} >
                              <Label> {product.code} </Label>
                          </Col>
                      </Row>
                      <Row>
                          <Col md={4} >
                              <Label><strong>Product Type: </strong></Label>
                          </Col>
                          <Col md={8} >
                              <Label> {product.productType} </Label>
                          </Col>
                      </Row>
                      <Row>
                          <Col md={4} >
                              <Label><strong>Prodcut Name: </strong></Label>
                          </Col>
                          <Col md={8} >
                              <Label> {product.name} </Label>
                          </Col>
                      </Row>
                  </CardBody>
              </Card>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-danger ml-auto"
                  id="btn-apply"
                  onClick={this.deleteSelectedProduct.bind(
                    this,
                    this.props.product._id
                  )}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={this.toggle}
                >
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </div>
        );
    }
}

export default connect(null, { deleteProduct, archiveProduct })(DeleteProductModal);