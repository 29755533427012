import React from 'react';
import { Alert } from 'reactstrap';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { DELIVERED } from '../../../../constVarAndFunc/StatusOptions';

const ConfirmStatusChange = (props) => {
    // statusModal -> current modal state
    // toggle -> current modal toggle func
    // order -> update order status 
    // status -> new status (to be updated)
    // changeStatusConfirm -> func that will change the status of the order
    // dod -> Date of Delivery
    // statusUpdateError -> Error msg to display
    let { statusModal, toggle, order, status, changeStatusConfirm, onChange, statusUpdateNotes, dod, statusUpdateError } = props;

    return (
        <div>
            <Modal
                isOpen={statusModal}
                toggle={toggle}
            >
                <ModalHeader>
                    <h5>Change status: </h5>
                </ModalHeader>
                <ModalBody >
                    {order ? (
                        <div style={{whiteSpace: 'pre-wrap'}}>
                            <Form>
                                {status === DELIVERED ? (
                                    <Alert color="info">
                                        Inventory will be updated
                                    </Alert>
                                ) : null}
                                <Row form>
                                    <Col md={4}>
                                        <Label><strong>Order ID:</strong></Label>
                                    </Col>
                                    <Col md={8}>
                                        <Label> {(order._id).slice(-10)} </Label>
                                    </Col>
                                </Row>

                                <Row form className="mt-2">
                                    <Col md={4}>
                                        <Label><strong>New Status:</strong></Label>
                                    </Col>
                                    <Col md={8}>
                                        <Label> {status} </Label>
                                    </Col>
                                </Row>

                                <Row form className="mt-2">
                                    <Col md={4}>
                                        <Label><strong>Delivery Date:</strong></Label>
                                    </Col>
                                    <Col md={8}>
                                        <Input type="date" value={dod} name="dod" onChange={onChange} />
                                    </Col>
                                </Row>

                                <Row form className="mt-2">
                                    <Col md={4}>
                                        <Label><strong>Notes: </strong></Label>
                                    </Col>
                                    <Col md={8}>
                                        <Input type="textarea" value={statusUpdateNotes} onChange={onChange} name="statusUpdateNotes" placeholder="Add notes..." />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    {statusUpdateError ? (
                        <Label className="float-left mr-auto" color="red"> {statusUpdateError} </Label>
                    ) : null}
                    <Button color="primary" onClick={changeStatusConfirm}>
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ConfirmStatusChange;