import {
    GET_CUSTOM_PRODUCTS,
    DELETE_CUSTOM_PRODUCT,
    ADD_CUSTOM_PRODUCT,
    UPDATE_CUSTOM_PRODUCT,
    LOADING_CUSTOM_PRODUCTS,
    ARCHIVE_CUSTOM_PRODUCT,
    UNARCHIVE_CUSTOM_PRODUCT,
    CUSTOM_PRODUCTS_ADD_STOCK,
    CUSTOM_PRODUCTS_INVENTORY_COUNT,
    CUSTOM_PRODUCTS_DAMAGED,
} from '../actions/types';

const initialState = {
    customProducts: [],
    activeCustomProducts: [],
    isLoading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_CUSTOM_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CUSTOM_PRODUCTS:
            return {
                ...state,
                isLoading: false,
                customProducts: action.payload,
                activeCustomProducts: action.payload.filter(product => product.isActive),
            }
        case ADD_CUSTOM_PRODUCT:
            return {
                ...state,
                customProducts: [action.payload, ...state.customProducts],
                activeCustomProducts: [action.payload, ...state.activeCustomProducts],
            }
        case ARCHIVE_CUSTOM_PRODUCT:
        case UNARCHIVE_CUSTOM_PRODUCT:
        case CUSTOM_PRODUCTS_DAMAGED:
        case CUSTOM_PRODUCTS_INVENTORY_COUNT:
        case CUSTOM_PRODUCTS_ADD_STOCK:
        case UPDATE_CUSTOM_PRODUCT:
            let isFound = false;
            state.activeCustomProducts.forEach(product => {
                if(product._id === action.payload._id) {
                    isFound = true;
                }
            })
            
            let customProducts = state.customProducts.map(customProduct => customProduct._id === action.payload._id ? action.payload : customProduct);
            
            let activeCustomProducts = customProducts.filter(product => product.isActive)
            return {
                ...state,
                customProducts,
                activeCustomProducts,
            }
        case DELETE_CUSTOM_PRODUCT:
            return {
                ...state,
                customProducts: state.customProducts.filter(customProduct => customProduct._id !== action.payload),
                activeCustomProducts: state.activeCustomProducts.filter(product => product._id !== action.payload),
            }
        default:
            return state;
    }
}