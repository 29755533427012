/***** PRODUCTS ******/
export const GET_PRODUCTS = "GET_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const LOADING_PRODUCTS = "LOADING_PRODUCTS";
export const ADD_STOCK = 'ADD_STOCK';
export const INVENTORY_COUNT = 'INVENTORY_COUNT';
export const ARCHIVE_PRODUCT = 'ARCHIVE_PRODUCT';
export const UNARCHIVE_PRODUCT = 'UNARCHIVE_PRODUCT';
export const DAMAGED_PRODUCTS = 'DAMAGED_PRODUCTS';

/***** CUSTOMERS ******/
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const ADD_SHIPPING_ADDRESS = 'ADD_SHIPPING_ADDRESS';
export const ARCHIVE_SHIPPING_ADDRESS = 'ARCHIVE_SHIPPING_ADDRESS';
export const UNARCHIVE_SHIPPING_ADDRESS = 'UNARCHIVE_SHIPPING_ADDRESS';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';

/***** ORDERS *******/
export const GET_ORDERS = "GET_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const UPDATE_ORDER_NOTES = 'UPDATE_ORDER_NOTES';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const UPDATE_ORDER_ADDRESS = 'UPDATE_ORDER_ADDRESS';
export const UPDATE_ORDER_PRODUCT = 'UPDATE_ORDER_PRODUCT';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_DOD = 'UPDATE_ORDER_DOD';
export const STATUS_SHIPPING_CHALLAN = 'UPDATE_ORDER_STATUS_CHALLAN';
export const UPDATE_ORDER_CHALLAN = 'UPDATE_ORDER_CHALLAN';
export const ADD_ORDER_SPLIT = 'ORDER_SPLIT';
export const ARCHIVE_ORDER = 'ARCHIVE_ORDER';

/***** CUSTOM_PRODUCTS *******/
export const GET_CUSTOM_PRODUCTS = "GET_CUSTOM_PRODUCTS";
export const DELETE_CUSTOM_PRODUCT = "DELETE_CUSTOM_PRODUCT";
export const ADD_CUSTOM_PRODUCT = "ADD_CUSTOM_PRODUCT";
export const UPDATE_CUSTOM_PRODUCT = "UPDATE_CUSTOM_PRODUCT";
export const LOADING_CUSTOM_PRODUCTS = "LOADING_CUSTOM_PRODUCTS";
export const ARCHIVE_CUSTOM_PRODUCT = "ARCHIVE_CUSTOM_PRODUCT";
export const UNARCHIVE_CUSTOM_PRODUCT = "UNARCHIVE_CUSTOM_PRODUCT";
export const CUSTOM_PRODUCTS_ADD_STOCK = 'CUSTOM_PRODUCTS_ADD_STOCK';
export const CUSTOM_PRODUCTS_INVENTORY_COUNT = 'CUSTOM_PRODUCTS_INVENTORY_COUNT';
export const CUSTOM_PRODUCTS_DAMAGED = 'CUSTOM_PRODUCTS_DAMAGED'

/********* USER AND AUTH *********/
export const USER_LOADING = 'USER_LOADING;'
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL ='REGISTER_FAIL';
export const GET_ERRORS ='GET_ERRORS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

/****** EMPLOYEE ********/
export const LOADING_EMPLOYEES = 'LOADING_EMPLOYEES';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const ADD_EMPLOYEE ='ADD_EMPLOYEES';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEES';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEES';

/******** LOGS ********/
export const LOADING_LOGS = 'LOADING_LOGS';
export const GET_LOGS = 'GET_LOGS';
export const ADD_LOG = 'ADD_LOG';

/********* REPORTS **********/
export const LOADING_REPORTS = 'LOADING_REPORTS';
export const GET_REPORTS = 'GET_REPORTS';
export const ADD_REPORT = 'ADD_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const SUBMIT_REPORT = 'SUBMIT_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';

/******* USERS **********/
export const LOADING_USERS = 'LOADING_USERS';
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';