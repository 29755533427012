import {
    LOADING_ORDERS,
    GET_ORDERS,
    ADD_ORDER,
    DELETE_ORDER,
    UPDATE_ORDER,
    ADD_PAYMENT,
    UPDATE_ORDER_ADDRESS,
    UPDATE_ORDER_PRODUCT,
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_DOD,
    UPDATE_ORDER_NOTES,
    DELETE_PAYMENT,
    STATUS_SHIPPING_CHALLAN,
    UPDATE_ORDER_CHALLAN,
    ADD_ORDER_SPLIT,
    ARCHIVE_ORDER,
} from '../actions/types'
import axios from 'axios';
import { addLog } from './LogActions';
import { tokenConfig } from './AuthActions';

export const getOrders = () => (dispatch, getState) => {
    dispatch(loadingOrders());
    axios
        .get('/api/orders/', tokenConfig(getState))
        .then(res => {
            let orders = [];
            res.data.forEach(order => {
                order = {
                    ...order,
                    orderId: (order._id).slice(-10)
                }
                orders.push(order);
            })
            dispatch({
                type: GET_ORDERS,
                payload: orders,
            })
        })
        .catch(err => console.log(err))
}

export const loadingOrders = () => {
    return {
        type: LOADING_ORDERS,
    }
}

export const addOrder = newOrder => (dispatch, getState) => {
    axios
        .post('/api/orders/', newOrder, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_ORDER,
                payload: res.data,
            })

            // Log
            const log = {
                type: ADD_ORDER,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: newOrder,
            }
            addLog(log, dispatch);
        })
        .catch(err => console.log(err))
}

export const addOrderSplit = (newOrder, previousOrderId) => (dispatch, getState) => {
    axios
        .post('/api/orders/', newOrder, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_ORDER_SPLIT,
                payload: res.data,
            })

            // Log
            const log = {
                type: ADD_ORDER_SPLIT,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: {
                    previousOrderId,
                    newOrder,
                }
            }
        })

}

const updateOrderWithType = (order, type, dispatch, getState) => {
    let id = order._id;
    axios
        .put(`/api/orders/${id}`, order, tokenConfig(getState))
        .then(res => {
            dispatch({
                type,
                payload: res.data
            })

            // Log
            const log = {
                type,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: order,
            }
            addLog(log, dispatch);
        })
        .catch(err => console.log(err));
}

export const updateOrder = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER, dispatch, getState);
}

export const addPayment = order => (dispatch, getState) => {
    updateOrderWithType(order, ADD_PAYMENT, dispatch, getState);
}

export const deletePayment = order => (dispatch, getState) => {
    updateOrderWithType(order, DELETE_PAYMENT, dispatch, getState);
}

export const updateOrderAddress = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER_ADDRESS, dispatch, getState);
}

export const updateOrderProduct = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER_PRODUCT, dispatch, getState);
}

export const updateOrderDod = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER_DOD, dispatch, getState);
}

export const updateOrderStatus = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER_STATUS, dispatch, getState);
}

export const updateOrderNotes = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER_NOTES, dispatch, getState);
}

export const updateOrderChallan = order => (dispatch, getState) => {
    updateOrderWithType(order, UPDATE_ORDER_CHALLAN, dispatch, getState);
}

export const archiveOrder = order => (dispatch, getState) => {
    updateOrderWithType(order, ARCHIVE_ORDER, dispatch, getState);
}

export const deleteOrder = order => (dispatch, getState) => {
    let id = order._id;
    axios
        .delete(`/api/orders/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_ORDER,
                payload: id,
            })

            // Log
            const log = {
                type: DELETE_ORDER,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: id,
            }
            addLog(log, dispatch);
        })
        .catch(err => console.log(err))
}