import {
    LOADING_REPORTS,
    GET_REPORTS,
    ADD_REPORT,
    UPDATE_REPORT,
    DELETE_REPORT,
    SUBMIT_REPORT,
} from '../actions/types';

const initialState = {
    isLoading: false,
    reports: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_REPORTS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_REPORTS:
            return {
                ...state,
                isLoading: false,
                reports: action.payload,
            }
        case ADD_REPORT: 
            return {
                ...state,
                reports: [...state.reports, action.payload],
            }
        case UPDATE_REPORT:
        case SUBMIT_REPORT:
            return {
                ...state,
                reports: state.reports.map(report => action.payload._id === report._id ? action.payload : report)
            }
        case DELETE_REPORT:
            return {
                ...state,
                reports: state.reports.filter(report => action.payload !== report._id)
            }
        default:
            return state;
    }
}