import {
    GET_CUSTOM_PRODUCTS,
    DELETE_CUSTOM_PRODUCT,
    ADD_CUSTOM_PRODUCT,
    UPDATE_CUSTOM_PRODUCT,
    LOADING_CUSTOM_PRODUCTS,
    ARCHIVE_CUSTOM_PRODUCT,
    UNARCHIVE_CUSTOM_PRODUCT,
    CUSTOM_PRODUCTS_ADD_STOCK,
    CUSTOM_PRODUCTS_INVENTORY_COUNT,
    CUSTOM_PRODUCTS_DAMAGED,
} from './types';
import axios from 'axios';
import { addLog } from './LogActions';
import { tokenConfig } from './AuthActions';

export const loadingCustomProducts = () => dispatch => {
    dispatch({
        type: LOADING_CUSTOM_PRODUCTS,
    })
}

export const getCustomProducts = () => (dispatch, getState) => {
    dispatch(loadingCustomProducts);
    axios
        .get('/api/customProducts', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_CUSTOM_PRODUCTS,
                payload: res.data
            })
        })
}

export const addCustomProduct = customProduct => (dispatch, getState) => {
    axios
        .post('/api/customProducts', customProduct, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CUSTOM_PRODUCT,
                payload: res.data,
            })

            // Log
            const log = {
                type: ADD_CUSTOM_PRODUCT,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: customProduct,
            }
            addLog(log, dispatch);
        })
}

const updateCustomProductWithType = (customProduct, type, dispatch, getState) => {
    let id = customProduct._id;
    axios
        .put(`/api/customProducts/${id}`, customProduct, tokenConfig(getState))
        .then(res => {
            dispatch({
                type,
                payload: res.data,
            })

            // Log
            const log = {
                type,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: customProduct,
            }
            addLog(log, dispatch);
        })
}

export const updateCustomProduct = customProduct => (dispatch, getState) => {
    updateCustomProductWithType(customProduct, UPDATE_CUSTOM_PRODUCT, dispatch, getState);
}

export const archiveCustomProduct = customProduct => (dispatch, getState) => {
    updateCustomProductWithType(customProduct, ARCHIVE_CUSTOM_PRODUCT, dispatch, getState);
}

export const unarchiveCustomProduct = customProduct => (dispatch, getState) => {
    updateCustomProductWithType(customProduct, UNARCHIVE_CUSTOM_PRODUCT, dispatch, getState);
}

export const customProductsAddStock = customProduct => (dispatch, getState) => {
    updateCustomProductWithType(customProduct, CUSTOM_PRODUCTS_ADD_STOCK, dispatch, getState);
}

export const customProductsInventoryCount = customProduct => (dispatch, getState) => {
    updateCustomProductWithType(customProduct, CUSTOM_PRODUCTS_INVENTORY_COUNT, dispatch, getState);
}

export const customProductsDamaged = customProduct => (dispatch, getState) => {
    updateCustomProductWithType(customProduct, CUSTOM_PRODUCTS_DAMAGED, dispatch, getState);
}

export const deleteCustomProduct = id => (dispatch, getState) => {
    axios
        .delete(`/api/customProducts/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_CUSTOM_PRODUCT,
                payload: id
            })

            // Log
            const log = {
                type: DELETE_CUSTOM_PRODUCT,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: id,
            }
            addLog(log, dispatch);
        })
}