import {
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  LOADING_CUSTOMERS,
  UPDATE_CUSTOMER,
  ADD_SHIPPING_ADDRESS,
  ARCHIVE_SHIPPING_ADDRESS,
  UNARCHIVE_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS,
} from "../actions/types";

const initialState = {
    customers: [],
    isLoading: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CUSTOMERS:
            return {
                ...state,
                isLoading: false,
                customers: action.payload
            }
        case ADD_CUSTOMER:
            return {
                ...state,
                customers: [...state.customers, action.payload]
            }
        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.filter(customer => customer.customerId !== action.payload)
            }
        case ADD_SHIPPING_ADDRESS:
        case UPDATE_SHIPPING_ADDRESS:
        case ARCHIVE_SHIPPING_ADDRESS:
        case UNARCHIVE_SHIPPING_ADDRESS:
        case UPDATE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.map(customer => (
                    customer._id === action.payload._id ? action.payload : customer
                ))
            };
        default: 
            return state;
    }
}