export const FACTORY_SALE = 'Factory Sale';
export const RETAIL = 'Retail';
export const WHOLESALE = 'Wholesale';
export const SCHOOL = 'School';
export const COLLEGE_UNIVERSITY = 'College/University';
export const ONLINE = 'Online';
export const EXPORT = 'Export';
export const COMMUNITY = 'Community';
export const CORPORATION = 'Corporation';
export const PERSON = 'Person';
export const GOVERNMENT = 'Government';
export const TENDER = 'Tender';
export const OTHERS = 'Others';

// import { 
//     COLLEGE_UNIVERSITY, 
//     COMMUNITY, 
//     CORPORATION, 
//     EXPORT, 
//     FACTORY_SALE, 
//     GOVERNMENT, 
//     ONLINE, 
//     PERSON, 
//     RETAIL, 
//     SCHOOL, 
//     TENDER, 
//     WHOLESALE, 
//     OTHERS, 
// } from '';