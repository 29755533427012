import React, { Component } from 'react';
import { FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { faCartPlus, faPlus, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addProduct } from '../../../actions/ProductActions';

class NewProductModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state;
  }

  state = {
    modal: false,
    isLoading: false,
    error: false,
    msg: "",

    productType: null,
    code: null,
    name: null,
    pages: null,
    gsm: null,
    finalSize: null,
    packageSize: null,
    price: null,
    stock: null,
  };

  static propTypes = {
    product: PropTypes.object.isRequired,
    addProduct: PropTypes.func.isRequired,
  };  

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  applyChanges = () => {
    this.setState({
      isLoading: true,
      msg: "Validating...",
      error: false,
    });
    //Check necessary input fields
    if (!this.state.productType) {
      this.setState({
        error: true,
        msg: "Please enter the product type",
        isLoading: false,
      });
      return;
    }
    if (!this.state.code) {
      this.setState({
        error: true,
        msg: "Please enter the code",
        isLoading: false,
      });
      return;
    }
    if (!this.state.name) {
      this.setState({
        error: true,
        msg: "Please enter name",
        isLoading: false,
      });
      return;
    }

    // Check if code is unique
    const isUniqueCheck = (product) =>
    product.code.toUpperCase() !== this.state.code.toUpperCase();
    const isUnique = this.props.product.products.every(isUniqueCheck);

    if (isUnique) {
      this.setState({
        error: false,
        msg: "Successfully added!",
      });
    } else {
      this.setState({
        error: true,
        isLoading: false,
        msg: "Code already in use. Try again.",
      });
      return;
    }

    const newProduct = {
      productType: this.state.productType,
      code: this.state.code,
      name: this.state.name,
      pages: this.state.pages || 0,
      gsm: this.state.gsm || 0,
      finalSize: this.state.finalSize,
      packageSize: this.state.packageSize || 0,
      price: this.state.price || 0,
      stock: this.state.stock || 0,
    };
    console.log(newProduct);

    this.props.addProduct(newProduct);
    this.cancelChanges();
  };

  cancelChanges = () => {
    this.setState(this.initialState)
  };

  render() {
    let productTypes = new Set();
    this.props.product.products.forEach(product => {
      productTypes.add(product.productType);
    });
    productTypes = [...productTypes];
    return (
      <div>
        <button className="btn-style" onClick={this.toggle}>
          <FontAwesomeIcon icon={faCartPlus} size="2x" color="#0275d8" />
        </button>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          //   size="sm"
          id="modal"
        >
          <ModalBody>
            <InputGroup size="lg" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** Product type **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Product Type
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="large"
                aria-describedby="inputGroup-sizing-sm"
                name="productType"
                value={this.state.productType}
                onChange={this.onChange}
                type="text"
                list="productTypes"
                autoComplete="off"
              >
              </FormControl>
                <datalist id="productTypes">
                  {productTypes.map(option => (
                    <option key={option}> {option} </option>
                  ))}
                </datalist>
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** CODE **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Code
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Enter unique code"
                name="code"
                value={this.state.code}
                onChange={this.onChange}
                type="text"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** NAME **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Name of the product"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
                type="text"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** PAGES **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Pages
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                name="pages"
                value={this.state.pages}
                onChange={this.onChange}
                type="number"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** GSM **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">GSM</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                name="gsm"
                value={this.state.gsm}
                onChange={this.onChange}
                type="number"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** FINAL SIZE **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Final Size
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                name="finalSize"
                value={this.state.finalSize}
                onChange={this.onChange}
                type="text"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** PACKAGE SIZE **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Package Size
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                name="packageSize"
                value={this.state.packageSize}
                onChange={this.onChange}
                type="number"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** PRICE **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Price <FontAwesomeIcon className="ml-2" icon={faRupeeSign} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                name="price"
                value={this.state.price}
                onChange={this.onChange}
                type="number"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3 mt-3">
              <InputGroup.Prepend>
                {" "}
                {/******** STOCK **********/}
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Initial Stock
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                name="stock"
                value={this.state.stock}
                onChange={this.onChange}
                type="number"
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            {/*******DISPLAY SPINNER ******* */}
            {this.state.isLoading && (
              <div>
                <Spinner animation="border" role="status"></Spinner>
              </div>
            )}
            
            <Label
              className={
                this.state.error ? "text-danger ml-3" : "text-primary ml-3"
              }
            >
              {this.state.msg}
            </Label>

            <button
              type="button"
              className="btn btn-primary ml-auto"
              id="btn-apply"
              onClick={this.applyChanges}
            >
              Apply
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.cancelChanges}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    product: state.product
});

export default connect(mapStateToProps, { addProduct })(NewProductModal);