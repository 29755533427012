import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getOrders } from '../../../actions/OrderActions';
import { getCustomers } from '../../../actions/CustomerActions';
import { Card, CardBody, CardHeader, Label } from 'reactstrap';
import OrderDetails from '../CustomersTab/Orders/OrderDetails';

class Sales extends React.Component {
    state = {
        currentPage: 1,
        ordersPerPage: 10,
    }

    componentDidMount = () => {
        this.props.getOrders();
        this.props.getCustomers();
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        getOrders: PropTypes.func.isRequired,
        getCustomers: PropTypes.func.isRequired,
    }

    paginate = pageNumber => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    render() {
        const { orders } = this.props.order;

        return (
            <div className="mt-3">
                <OrderDetails orders={orders} showCustomerName={true} paginate={true} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    order: state.order,
    customer: state.customer,
})

export default connect(mapStateToProps, { getOrders, getCustomers })(Sales)