import React, { useState } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

const AddCustomProductProperty = ({ addProps }) => {

    const [modal, setModal] = useState(false);
    const [ propName, setPropName] = useState('');
    const [ propValue, setPropValue] = useState('');

    const toggle = () => setModal(!modal);

    const applySetting = () => {
        if(!propName || !propValue) {
            return;
        }
        addProps(propName, propValue);
        setPropName('');
        setPropValue('');
        toggle();
    }

    const handleKeyDown = e => {
        if(e.keyCode === 13) {
            applySetting();
        }
    }

    return (
        <div>
            <Button color="link" onClick={toggle} >Add properties...</Button>

            <Modal isOpen={modal} toggle={toggle} backdrop="static">
                <ModalHeader>
                    Add Property
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col md={6} >
                            <Label>
                                Property Name:
                            </Label>
                            <Input 
                                autoComplete="off"
                                type="text"
                                name="propName"
                                value={propName}
                                onChange={e => setPropName(e.target.value)}
                                placeholder="Property Name"
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        <Col md={6} >
                            <Label>
                                Property Value:
                            </Label>
                            <Input 
                                autoComplete="off"
                                type="text"
                                name="propValue"
                                value={propValue}
                                onChange={e => setPropValue(e.target.value)}
                                placeholder="Property Value"
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={applySetting} >Add Property</Button>
                    <Button color="secondary" onClick={toggle} >Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default AddCustomProductProperty;