import { faFilter, faMinusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Badge, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import moment from 'moment';
import { AWAITING_PICKUP, DECLINE, DELIVERED, PARTIAL, PENDING, RETURNED, SHIPPED, VOID, OTHERS } from '../../../../constVarAndFunc/StatusOptions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class FilterOrders extends React.Component {
    constructor(props) {
        super(props);
        // State used on toggle -> updates when changes are applied
        this.initialState = this.state;
        // Staet used on clear settings 
        this.resetState = this.state;
    }

    state = {
        modal: false,
        orderId: '',
        status: null,
        statusList: [],
        customer: null,
        invalidCustomer: false,
        customersList: [],
        product: null,
        invalidProduct: false,
        productsList: [],
        salesPerson: null,
        invalidSalesPerson: false,
        salesPersonsList: [],
        grandTotalMin: '',
        grandTotalMinCheck: null,
        grandTotalMax: '',
        grandTotalMaxCheck: null,
        orderDateMin: '',
        orderDateMinCheck: null,
        orderDateMax: '',
        orderDateMaxCheck: null,
        deliveryDateMin: '',
        deliveryDateMinCheck: null,
        deliveryDateMax: '',
        deliveryDateMaxCheck: null,

        isFiltered: false,
    }

    static propTypes = {
        customer: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        employee: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })

        // Adds status to the list when user clicks on select option
        if(e.target.name === 'status') {
            this.setState({
                statusList: [...this.state.statusList, e.target.value],
            })
        }
    }

    onChangeCheckBox = e => {
        let isChecked = null;
        if(e.target.checked) {
            isChecked = true
        } else {
            isChecked = false
        }
        this.setState({
            [e.target.name]: isChecked,
        })

        // checkbox will disable Correspoding input -> to get minimum and maximum values
        let name = [e.target.name];
        name = name.toString().replace('Check', '');
        this.setState({
            [name]: '',
        })
    }

    toggle = () => {
        // Set state with previously saved state (applied settings)
        this.setState(this.initialState);
        // Toggle
        this.setState({
            modal: !this.state.modal,
        })
    }

    handleKeyDownSales = e => {
        if(e.keyCode === 13) {
            this.addSalesPersonToList();
        }
    }

    addSalesPersonToList = () => {
        if(!this.state.salesPerson) return;
        let salesPerson = this.props.employee.employees.find(e => e.fullName.toLowerCase() === this.state.salesPerson.toLowerCase());
        if(salesPerson) {
            this.setState({
                invalidSalesPerson: false,
            })
        } else {
            this.setState({
                invalidSalesPerson: true,
            })
            return;
        }
        let salesPersonsList = [];
        if(this.state.salesPersonsList.includes(salesPerson.fullName)) return;
        if(this.state.salesPersonsList.length !== 0) {
            salesPersonsList = [...this.state.salesPersonsList, salesPerson.fullName];
        } else {
            salesPersonsList = [salesPerson.fullName];
        }        
        this.setState({
            salesPersonsList,
            salesPerson: '',
        })
    }

    removeSalesPersonFromList = name => {
        this.setState({
            salesPersonsList: this.state.salesPersonsList.filter(n => n !== name)
        })
    }

    handleKeyDownProduct = e => {
        if(e.keyCode === 13) {
            this.addProductToList();
        }
    }

    addProductToList = () => {
        if(!this.state.product) return;
        let product = this.props.product.products.find(p => p.code.toLowerCase() === this.state.product.toLowerCase());
        if(!product) product = this.props.customProduct.activeCustomProducts.find(p => p.code.toLowerCase() === this.state.product.toLowerCase());
        if(product) {
            this.setState({
                invalidProduct: false
            })
        } else {
            this.setState({
                invalidProduct: true,
            })
            return;
        }
        let productsList = [];
        if(this.state.productsList.includes(product.code)) return;
        if(this.state.productsList.length !== 0) {
            productsList = [...this.state.productsList, product.code];
        } else {
            productsList = [product.code];
        }        
        this.setState({
            productsList,
            product: '',
        })
    }

    removeProductFromList = productCode => {
        this.setState({
            productsList: this.state.productsList.filter(p => p !== productCode)
        })
    }

    handleKeyDownCustomer = e => {
        if(e.keyCode === 13) {
            this.addCustomerToList();
        }
    }

    addCustomerToList = () => {
        if(!this.state.customer) return;
        let customer = this.props.customer.customers.find(customer => customer.customerName.toLowerCase() === this.state.customer.toLowerCase())
        if(!customer) {
            this.setState({
                invalidCustomer: true,
            })
            return;
        } else {
            this.setState({
                invalidCustomer: false
            })
        }
        let customersList = [];
        if(this.state.customersList.includes(customer.customerName)) return;
        if(this.state.customersList.length !== 0) {
            customersList = [...this.state.customersList, customer.customerName];
        } else {
            customersList = [customer.customerName];
        }        
        this.setState({
            customersList,
            customer: '',
        })
    }

    removeCustomerFromList = (customerName) => {
        this.setState({
            customersList: this.state.customersList.filter(c => c !== customerName)
        })
    }

    addStatusToList = () => {
        if(!this.state.status) return;
        let statusList = [];
        if(this.state.statusList.includes(this.state.status)) return;
        if(this.state.statusList.length !== 0) {
            statusList = [...this.state.statusList, this.state.status];
        } else {
            statusList = [this.state.status];
        }        
        this.setState({
            statusList,
        })
    }

    removeStatusFromList = (status) => {
        this.setState({
            statusList: this.state.statusList.filter(s => s !== status)
        })
    }

    statusOptions = [
        VOID, 
        DECLINE,
        RETURNED,
        PENDING, 
        AWAITING_PICKUP,
        PARTIAL,
        SHIPPED, 
        DELIVERED,
        //COMPLETED,
        OTHERS, 
    ]

    getCustomerId = customerName => {
        let customersIds = [];
        this.props.customer.customers.forEach(customer => {
            if(customer.customerName === customerName) {
                customersIds = [...customersIds, customer.customerId];
            }
        })
        return customersIds;
    }

    getEmployeeId = employeeFullName => {
        let employeeIds = [];
        this.props.employee.employees.forEach(employee => {
            if(employee.fullName === employeeFullName) {
                employeeIds = [...employeeIds, employee.employeeId]
            }
        })
        return employeeIds;
    }

    matchSearch = (string1, string2) => {
        if(string1 && string2){
            if((String(string1).toUpperCase()).includes((string2).toUpperCase())) {
                return true;
            };
        }
        return false;
    }

    filterOrders = () => {
        // let orders = this.props.orders;
        let results = this.props.orders;

        let isFiltered = false;
        // Filter orders and setState
        // Order Id
        if(this.state.orderId) {
            isFiltered = true;
            results = results.filter(order => {
                if(this.matchSearch(order._id, this.state.orderId)) {
                    return order;
                }
            })
        }

        // SalesPerson
        if(this.state.salesPersonsList.length !== 0) {
            isFiltered = true;
            let employeeIds = [];
            this.state.salesPersonsList.forEach(salesPerson => {
                employeeIds = [...employeeIds, ...this.getEmployeeId(salesPerson)]
            })
            results = results.filter(order => {
                if(employeeIds.includes(order.employeeId)) {
                    return order;
                }
            })
        }

        // Customers
        if(this.state.customersList.length !== 0) {
            isFiltered = true;
            let customerIds = [];
            this.state.customersList.forEach(customerName => {
                customerIds = [...customerIds, ...this.getCustomerId(customerName)]
            })
            results = results.filter(order => {
                if(customerIds.includes(order.customerId)) {
                    return order;
                }
            })
        }

        // Products
        if(this.state.productsList.length !== 0) {
            isFiltered = true;
            results = results.filter(order => {
                for(let i=0; i<order.products.length; i++) {
                    if(this.state.productsList.includes(order.products[i].code)){
                        return order;
                    }
                }
            })
        }

        // Grand Total Min
        if(this.state.grandTotalMin) {
            isFiltered = true;
            results = results.filter(order => {
                if(this.state.grandTotalMin < order.orderSummary.grandTotal) {
                    return order;
                }
            })
        }
        
        // Grand Total Max
        if(this.state.grandTotalMax) {
            isFiltered = true;
            results = results.filter(order => {
                if(this.state.grandTotalMax > order.orderSummary.grandTotal) {
                    return order;
                }
            })
        }

        // Status List
        if(this.state.statusList.length !== 0) {
            isFiltered = true;
            results = results.filter(order => {
                if(this.state.statusList.includes(order.status)) {
                    return order;
                }
            })
        }

        // Order Date Max
        if(this.state.orderDateMax) {
            isFiltered = true;
            let maxDate = moment.utc(this.state.orderDateMax);
            results = results.filter(order => {
                let orderDate = moment.utc(order.orderDate);
                if(orderDate.diff(maxDate, 'days') <= 0) {
                    return order;
                }
            })
        }

        // Order Date Min
        if(this.state.orderDateMin) {
            isFiltered = true;
            let minDate = moment.utc(this.state.orderDateMin);
            results = results.filter(order => {
                let orderDate = moment.utc(order.orderDate);
                if(minDate.diff(orderDate, 'days') <= 0) {
                    return order;
                }
            })
        }

        // Delivery Date Max
        if(this.state.deliveryDateMax) {
            isFiltered = true;
            let maxDate = moment.utc(this.state.deliveryDateMax);
            results = results.filter(order => {
                let deliveryDate = moment.utc(order.deliveryDate);
                if(deliveryDate.diff(maxDate, 'days') <= 0) {
                    return order;
                }
            })
        }

        // Delivery Date Max
        if(this.state.deliveryDateMin) {
            isFiltered = true;
            let minDate = moment.utc(this.state.deliveryDateMin);
            results = results.filter(order => {
                let deliveryDate = moment.utc(order.deliveryDate);
                if(minDate.diff(deliveryDate, 'days') <= 0) {
                    return order;
                }
            })
        }             
        
        return { results, isFiltered };
    }

    applyChanges = () => {
        // Get orders after filter and set orders
        let { results, isFiltered } = this.filterOrders();
        this.props.applyFilter(results);
        // highlight filter button
        this.setState({
            isFiltered,
            modal: false,
        }, () => {
            // Save the filter state and reset when toggle true
            this.initialState = this.state;
        })
        
        // this.toggle();
    }

    cancelChanges = () => {
        this.toggle();
    }

    removeFilter = () => {
        // Remove changes in filter
        this.props.removeFilter();
        // Reset changes in this state
        this.initialState = this.resetState;
        this.setState(this.resetState);
    }

    render() {
        return(
            <div>
                <div className="float-right" >
                    <Button color={this.state.isFiltered ? "info" : 'link'} onClick={this.toggle}>
                        <FontAwesomeIcon icon={faFilter} className="mr-2" />
                        filter
                    </Button>
                    <button className="btn-style" onClick={this.removeFilter} >
                        <FontAwesomeIcon icon={faTimes} color="blue"/>
                    </button>
                </div>

                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" backdrop="static">
                    <ModalHeader>
                        Filter Order
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md={3} >
                                <Label >OrderId: </Label>
                            </Col>
                            <Col md={9} >
                                <Input
                                    type="tect"
                                    placeholder="Match OrderId"
                                    name="orderId"
                                    value={this.state.orderId}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col md={3} >
                                <Label>Sales Person:</Label>
                            </Col>
                            <Col md={6} >
                                <Input 
                                    type="text"
                                    autoComplete="off"
                                    list="employeeNames"
                                    name="salesPerson"
                                    value={this.state.salesPerson}
                                    onChange={this.onChange}
                                    placeholder="Sales Person Fullname"
                                    invalid={this.state.invalidSalesPerson}
                                    onKeyDown={e => this.handleKeyDownSales(e)}
                                />
                                    <datalist id="employeeNames" >
                                        {this.props.employee.employees.map(employee => (
                                            <option value={employee.fullName}>{employee.fullName} | {employee.jobTitle}</option>
                                        ))}
                                    </datalist>
                            </Col>
                            <Col md={3} >
                                <Button color="primary" onClick={this.addSalesPersonToList}>Add</Button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}></Col>
                            <Col md={9}>
                                <div>
                                    {this.state.salesPersonsList.map(name => (
                                        <Badge color="light" onClick={this.removeSalesPersonFromList.bind(this, name)}>
                                            {name}
                                            <button className="btn-style"> <FontAwesomeIcon icon={faTimes}/> </button>
                                        </Badge>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                        
                        {this.props.showCustomerName ? (
                            <div>
                                <Row className="mb-2">
                                    <Col md={3} >
                                        <Label>Customers:</Label>
                                    </Col>
                                    <Col md={6} >
                                        <Input 
                                            type="text"
                                            autoComplete="off"
                                            list="customerNames"
                                            name="customer"
                                            value={this.state.customer}
                                            onChange={this.onChange}
                                            placeholder="Customer Fullname"
                                            invalid={this.state.invalidCustomer}
                                            onKeyDown={e => this.handleKeyDownCustomer(e)}
                                        />
                                            <datalist id="customerNames" >
                                                <option selected disabled>Select customers</option>
                                                {this.props.customer.customers.map(customer => (
                                                    <option value={customer.customerName}>{customer.customerName} | {customer.organizationName}</option>
                                                ))}
                                            </datalist>
                                    </Col>
                                    <Col md={3} >
                                        <Button color="primary" onClick={this.addCustomerToList}>Add</Button>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={3}></Col>
                                    <Col md={9}>
                                        <div>
                                            {this.state.customersList.map(customerName => (
                                                <Badge color="light" onClick={this.removeCustomerFromList.bind(this, customerName)}>
                                                    {customerName}
                                                    <button className="btn-style"> <FontAwesomeIcon icon={faTimes}/> </button>
                                                </Badge>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}

                        <Row className="mb-2">
                            <Col md={3} >
                                <Label>Products:</Label>
                            </Col>
                            <Col md={6} >
                                <Input 
                                    type="text"
                                    autoComplete="off"
                                    list="productNames"
                                    name="product"
                                    value={this.state.product}
                                    onChange={this.onChange}
                                    placeholder="Enter Product Code"
                                    invalid={this.state.invalidProduct}
                                    onKeyDown={e => this.handleKeyDownProduct(e)}
                                />
                                    <datalist id="productNames" >
                                        {this.props.product.activeProducts.map(product => (
                                            <option value={product.code}>{product.code} | {product.name} | {product.productType}</option>
                                        ))}
                                        {this.props.customProduct.activeCustomProducts.map(product => (
                                            <option value={product.code}>{product.code} | {product.name} | {product.productType}</option>
                                        ))}
                                    </datalist>
                            </Col>
                            <Col md={3} >
                                <Button color="primary" onClick={this.addProductToList}>Add</Button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}></Col>
                            <Col md={9}>
                                <div>
                                    {this.state.productsList.map(productCode => (
                                        <Badge color="light" onClick={this.removeProductFromList.bind(this, productCode)}>
                                            {productCode}
                                            <button className="btn-style"> <FontAwesomeIcon icon={faTimes}/> </button>
                                        </Badge>
                                    ))}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col md={3} >
                                <Label>Status</Label>
                            </Col>
                            <Col md={6} >
                                <Input 
                                    type="select"
                                    name="status"
                                    value={this.state.status}
                                    onChange={this.onChange}
                                >
                                    <option selected disabled>Select Status</option>
                                    {this.statusOptions.map(status => (
                                        <option key={status}>{status}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col md={3} >
                                <Button color="primary" onClick={this.addStatusToList}>Add</Button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}></Col>
                            <Col md={9}>
                                <div>
                                    {this.state.statusList.map(status => (
                                        <Badge color="light" onClick={this.removeStatusFromList.bind(this, status)}>
                                            {status}
                                            <button className="btn-style"> <FontAwesomeIcon icon={faTimes}/> </button>
                                        </Badge>
                                    ))}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3} >
                                <Label>Grand Total</Label>
                            </Col>
                            <Col md={4} >
                                <Input 
                                    type="checkbox"
                                    name="grandTotalMinCheck"
                                    checked={this.state.grandTotalMinCheck}
                                    onChange={this.onChangeCheckBox}                                    
                                />
                                <Input 
                                    type="number"
                                    placeholder="min"
                                    name="grandTotalMin"
                                    value={this.state.grandTotalMin}
                                    onChange={this.onChange}
                                    disabled={this.state.grandTotalMinCheck}
                                />
                            </Col>
                            <Col md={1}>TO</Col>
                            <Col md={4} >
                                <Input 
                                    type="checkbox"
                                    name="grandTotalMaxCheck"
                                    checked={this.state.grandTotalMaxCheck}
                                    onChange={this.onChangeCheckBox}
                                />
                                <Input 
                                    type="number"
                                    placeholder="max"
                                    name="grandTotalMax"
                                    value={this.state.grandTotalMax}
                                    onChange={this.onChange}
                                    disabled={this.state.grandTotalMaxCheck}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3} >
                                <Label>Order Date</Label>
                            </Col>
                            <Col md={4} >
                                <Input 
                                    type="checkbox"
                                    name="orderDateMinCheck"
                                    checked={this.state.orderDateMinCheck}
                                    onChange={this.onChangeCheckBox}
                                />
                                <Input 
                                    type={this.state.orderDateMinCheck ? "text" : "date"}
                                    // type="date"
                                    placeholder="min"
                                    name="orderDateMin"
                                    value={this.state.orderDateMin}
                                    onChange={this.onChange}
                                    disabled={this.state.orderDateMinCheck}
                                />
                            </Col>
                            <Col md={1}>TO</Col>
                            <Col md={4} >
                                <Input 
                                    type="checkbox"
                                    name="orderDateMaxCheck"
                                    checked={this.state.orderDateMaxCheck}
                                    onChange={this.onChangeCheckBox}
                                />
                                <Input 
                                    // type="date"
                                    type={this.state.orderDateMaxCheck ? "text" : "date"}
                                    placeholder="max"
                                    name="orderDateMax"
                                    value={this.state.orderDateMax}
                                    onChange={this.onChange}
                                    disabled={this.state.orderDateMaxCheck}
                                    min={this.state.orderDateMin}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3} >
                                <Label>Delivery Date</Label>
                            </Col>
                            <Col md={4} >
                                <Input 
                                    type="checkbox"
                                    name="deliveryDateMinCheck"
                                    checked={this.state.deliveryDateMinCheck}
                                    onChange={this.onChangeCheckBox}
                                />
                                <Input 
                                    // type="date"
                                    type={this.state.deliveryDateMinCheck ? "text" : "date"}
                                    placeholder="min"
                                    name="deliveryDateMin"
                                    value={this.state.deliveryDateMin}
                                    onChange={this.onChange}
                                    disabled={this.state.deliveryDateMinCheck}
                                />
                            </Col>
                            <Col md={1}>TO</Col>
                            <Col md={4} >
                                <Input 
                                    type="checkbox"
                                    name="deliveryDateMaxCheck"
                                    checked={this.state.deliveryDateMaxCheck}
                                    onChange={this.onChangeCheckBox}
                                />
                                <Input 
                                    // type="date"
                                    type={this.state.deliveryDateMaxCheck ? "text" : "date"}
                                    placeholder="max"
                                    name="deliveryDateMax"
                                    value={this.state.deliveryDateMax}
                                    onChange={this.onChange}
                                    disabled={this.state.deliveryDateMaxCheck}
                                    min={this.state.deliveryDateMin}
                                />
                            </Col>
                        </Row>
                        
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.applyChanges} className="float-right">Filter</Button>
                        <Button color="secondary" onClick={this.cancelChanges} className="float-right">Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer,
    product: state.product,
    employee: state.employee,
    customProduct: state.customProduct,
})

export default connect(mapStateToProps, {})(FilterOrders);