import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateEmployee } from '../../../actions/EmployeeActions';

class Employeeprofile extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state
    }

    state = {
        modal:false,
        disableInput: true,
    }

    static propTypes = {
        updateEmployee: PropTypes.func.isRequired,
    }

    toggle = () => {
        let { fullName, jobTitle, contact, email, jobStatus, startDate, address, notes } = this.props.employee;
        let { line1, street, landmark, city, state, country, zipCode } = address;
        startDate = startDate.split('T')[0];
        this.setState({
            modal: !this.state.modal,
            fullName,
            jobTitle,
            contact,
            email,
            jobStatus,
            startDate,
            notes,

            line1,
            street,
            landmark,
            city,
            state,
            country,
            zipCode,
        })
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleEdit = () => {
        this.setState({
            disableInput: !this.state.disableInput
        })
    }

    applyChanges = () => {
        {// Validation
            if(!this.state.fullName) {
                this.setState({
                    errorMsg: 'Please enter full name'
                })
                return;
            }

            if(!this.state.contact) {
                this.setState({
                    errorMsg: 'Please enter contact number'
                })
                return;
            }
        }

        // Update existing employee
        // 2. Recreate employee
        let employee = {
            _id: this.props.employee._id,
            fullName: this.state.fullName,
            address: {
                line1: this.state.line1,
                street: this.state.street,
                landmark: this.state.landmark,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                zipCode: this.state.zipCode,
            },
            jobTitle: this.state.jobTitle,
            email: this.state.email,
            contact: this.state.contact,
            jobStatus: this.state.jobStatus,
            startDate: this.state.startDate,
            notes: this.state.notes,
        }
        this.props.updateEmployee(employee);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <Button color="info" onClick={this.toggle}>
                     <FontAwesomeIcon icon={faEdit} className="mr-2" /> Profile
                </Button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader>
                        <Label className="mr-auto" >Employee Profile</Label>
                        <Button className="ml-3 float-right" color="outline-info" onClick={this.toggleEdit}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Full name: *</Label>
                                <Input 
                                    type="text"
                                    name="fullName"
                                    value={this.state.fullName}
                                    onChange={this.onChange}
                                    placeholder="Enter full name"
                                    disabled={this.state.disableInput}
                                />
                            </Col>
                            <Col md={6}>
                                <Label>Job Title:</Label>
                                <Input 
                                    type="select"
                                    name="jobTitle"
                                    value={this.state.jobTitle}
                                    onChange={this.onChange}
                                    disabled={this.state.disableInput}
                                >
                                    <option selected disabled>Select job jobTitle</option>
                                    <option>Sales Executive</option>
                                    <option>Operator</option>
                                    <option>Accountant</option>
                                    <option>Manager</option>
                                </Input>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Contact Number: *</Label>
                                <Input 
                                    type="text"
                                    name="contact"
                                    value={this.state.contact}
                                    onChange={this.onChange}
                                    placeholder="Mobile Number"
                                    disabled={this.state.disableInput}
                                />
                            </Col>
                            <Col md={6}>
                                <Label>Email:</Label>
                                <Input 
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    placeholder="Email"
                                    disabled={this.state.disableInput}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Job Status:</Label>
                                <Input 
                                    type="select"
                                    name="jobStatus"
                                    value={this.state.jobStatus}
                                    onChange={this.onChange}
                                    disabled={this.state.disableInput}
                                >
                                    <option selected disabled>Select job status</option>
                                    <option>Contract</option>
                                    <option>Part-time</option>
                                    <option>Full-time</option>
                                </Input>
                            </Col>
                            <Col md={6}>
                                <Label>Start Date:</Label>
                                <Input 
                                    type="date"
                                    name="startDate"
                                    value={this.state.startDate}
                                    onChange={this.onChange}
                                    disabled={this.state.disableInput}
                                />
                            </Col>
                        </Row>
                        
                        <Row >
                            <Col>
                                <Label>Address</Label>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.line1} type="address" name="line1" placeholder="(Address Line1)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={12}>
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.street} type="text" name="street" placeholder="(Street/Road)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={12}>
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.landmark} type="text" name="landmark" placeholder="(Landmark)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={4}>
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.city} type="text" name="city" placeholder="(City/Town)" />
                            </Col>

                            <Col md={4}>
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.state} type="text" name="state" placeholder="(State/Province)" />
                            </Col>

                            <Col md={4}>
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.country} type="text" name="country" placeholder="(Country)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={4} >
                                <Input 
                                    disabled={this.state.disableInput} onChange={this.onChange} value={this.state.zipCode} type="text" name="zipCode" placeholder="(Zip/Pin Code)" />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={12}>
                                <Label>Notes:</Label>
                                <Input 
                                    type="textarea"
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={this.onChange}
                                    placeholder="Add notes..."
                                    disabled={this.state.disableInput}
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    {!this.state.disableInput ? (
                        <ModalFooter>
                            <Button color="primary" onClick={this.applyChanges} >Apply Changes</Button>
                            <Button color="secondary" onClick={this.cancelChanges} >Cancel</Button>
                        </ModalFooter>
                    ) : null}
                </Modal>
            </div>
        )
    }
}

export default connect(null, { updateEmployee })(Employeeprofile);