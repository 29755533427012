import React from 'react';
import { Button, Card, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOrderProduct } from '../../../../../actions/OrderActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamationCircle, faRupeeSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatBackToNumber, formatAmount } from '../../../../constVarAndFunc/formats';

class ChangeOrderProducts extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        products: this.props.order.products,
        searchProduct: null,
        productSrarchError: null,

        //order summary
        totalAmount: this.props.order.orderSummary.totalAmount,
        shippingHandling: this.props.order.orderSummary.shippingHandling,
        discount: this.props.order.orderSummary.discount,
        stateTax: this.props.order.orderSummary.stateTax,    //in percentage
        centralTax: this.props.order.orderSummary.centralTax,  //in percentage
        grandTotal: this.props.order.orderSummary.grandTotal,

        // Keep this unchanged
        p_totalAmount: this.props.order.orderSummary.totalAmount,
        p_shippingHandling: this.props.order.orderSummary.shippingHandling,
        p_discount: this.props.order.orderSummary.discount,
        p_taxableAmount: this.props.order.orderSummary.totalAmount + this.props.order.orderSummary.shippingHandling - this.props.order.orderSummary.discount,
        p_stateTax: this.props.order.orderSummary.stateTax,    //in percentage
        p_centralTax: this.props.order.orderSummary.centralTax,  //in percentage
        p_grandTotal: this.props.order.orderSummary.grandTotal,
    }

    static propTypes = {
        product: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
        updateOrderProduct: PropTypes.func.isRequired
    }

    componentDidMount = () => {
        this.updateOrderSummary();
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    onChange = e => {
        // Calclating and updating grand total when order details are changed (AFTER STATE CHANGE)
        this.setState({ [e.target.name]: e.target.value }, () => { this.updateOrderSummary(); });
        this.setState({ errorMsg: '' })

        if(e.target.name === 'searchProduct') {
            this.setState({
                productSearchError: null
            })
        }
    }

    onChangeProduct = e => {
        // Return if value less than 0
        if(e.target.value < 0) return;

        let [num, name] = e.target.name.split('-');
        num = parseInt(num)

        // Selecting product from products array and the key as name
        let newP = this.state.products[num];
        newP[name] = e.target.value;

        this.setState({
            products: this.state.products.map((p, i) => i === num ? newP : p)
        })
        this.updateOrderSummary();
    }

    findProductName = code => {
        let product = this.props.product.products.find(p => p.code === code);
        if(!product) product = this.props.customProduct.customProducts.find(p => p.code === code);
        return `${product.name} (${product.productType})`;
    }

    updateOrderSummary = () => {
        let total = 0;
        this.state.products.forEach(p => {
            total += (p.packageSize * p.numPackage * p.rate);
        })

        let totalAmount = total;
        let taxableAmount = parseFloat(totalAmount) + parseFloat(this.state.shippingHandling) - parseFloat(this.state.discount);
        let stateTaxAmount = (parseFloat(taxableAmount) * parseFloat(this.state.stateTax / 100)).toFixed(2);
        let centralTaxAmount = (parseFloat(taxableAmount) * parseFloat(this.state.centralTax / 100)).toFixed(2);
        let grandTotal = parseFloat(stateTaxAmount) + parseFloat(centralTaxAmount) + parseFloat(taxableAmount);

        this.setState({
            totalAmount: formatAmount(totalAmount),
            taxableAmount: formatAmount(taxableAmount), 
            stateTaxAmount: formatAmount(stateTaxAmount), 
            centralTaxAmount: formatAmount(centralTaxAmount), 
            grandTotal: (formatAmount(grandTotal)+"/-"),
        })
    }
    
    handleKeyDown = e => {
        if(e.keyCode === 13) {
            this.addProductBtn();
        }
    }

    addProductBtn = () => {
        let found = false;
        let code = this.state.searchProduct.toLowerCase();
        this.props.product.products.forEach(product => {
            if(product.isActive) {
                if (code === product.code.toLowerCase()) {
                    found = true;
                    let newProduct = {
                        name: product.name,
                        productType: product.productType,
                        code: product.code,
                        stock: product.stock,
                        packageSize: product.packageSize,
                        numPackage: 0,
                        rate: product.price,
                    }
                    
                    this.setState({
                        products: [...this.state.products, newProduct]
                    });
                }
            }
        })

        // If not found -> search in customer's customProduct list. 
        if(!found) {
            this.props.customProduct.customProducts.forEach(product => {
                if(product.isActive) {
                    if (code === product.code.toLowerCase()) {
                        found = true;
                        let newProduct = {
                            name: product.name,
                            productType: product.productType,
                            code: product.code,
                            stock: product.stock,
                            packageSize: product.packageSize,
                            numPackage: 0,
                            rate: product.price,
                        }
                        
                        this.setState({
                            products: [...this.state.products, newProduct]
                        });
                    }
                }
            })
        }

        if(!found) { //did not find the product
            this.setState({
                productSrarchError: 'Product Not Found'
            })
        } else {    //product found
            this.setState({
                productSrarchError: '',
                searchProduct: '',
            })
            this.updateOrderSummary();
        }
    }

    removeProductFromList = index => {
        let productsBU = this.state.products.filter((p, i) => i !== index);
        this.setState({
            products: []
        });

        // Update Order summary when the products list is empty! (i.e total = 0) (Sync)
        if(productsBU.length === 0) {
            this.setState({
                products: []
            }, () => {
                this.updateOrderSummary();
            })   
        }

        // set timeout so react does not re render due to deletion in setState
        productsBU.forEach(p => {
            setTimeout(() => {
                this.setState({
                    products: [...this.state.products, p]
                }, () => {
                    this.updateOrderSummary();
                })
            }, 20)
        })
    }

    clearProductsTable = () => {
        this.setState({
            products: [],
            totalAmount: 0,
        })
    }


    applyChanges = () => {
        // Validate all fields
        if(this.state.products.length === 0) {
            this.setState({ errorMsg: 'No products in the order list' });
            return;
        }
        if(this.state.totalAmount == 0) {
            this.setState({ errorMsg: 'Cannot make order with amount 0/-' });
            return;
        }
        // Create order
        let products = [];
        this.state.products.forEach(product => {
            let newProduct = {
                code: product.code,
                packageSize: parseInt(product.packageSize),
                numPackage: parseInt(product.numPackage),
                rate: parseFloat(product.rate),
            }
            products.push(newProduct);
        })
        let order = {
            _id: this.props.order._id,
            products,
            orderSummary: {
                totalAmount: formatBackToNumber(this.state.totalAmount),
                shippingHandling: this.state.shippingHandling,
                discount: this.state.discount,
                stateTax: this.state.stateTax,
                centralTax: this.state.centralTax,
                grandTotal: formatBackToNumber(this.state.grandTotal),
            },
        }
        // Add to state and DB and toggle the modal
        this.props.updateOrderProduct(order);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        let { products } = this.props.product;
        products = products.filter(p => p.isActive);
        this.props.customProduct.customProducts.forEach(customProduct => {
            if(customProduct.customerId === this.props.order.customerId) {
                if(customProduct.isActive) {
                    products.push(customProduct);
                }
            }
        })
        return (
            <div>
                <Button 
                    className="float-right" 
                    color="info" 
                    onClick={this.toggle}    
                >
                    <FontAwesomeIcon icon={faEdit} /> Edit</Button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl">
                    <ModalHeader>
                        Change Produts Details
                    </ModalHeader>
                    <ModalBody>
                        <h5><strong>Products: </strong></h5>
                        <Row>
                            <Col md={6} >
                                <Form onSubmit={e => e.preventDefault()}>
                                    <Input type="text" 
                                        autoComplete="off"
                                        onChange={this.onChange} 
                                        name="searchProduct"
                                        value={this.state.searchProduct} 
                                        invalid={this.state.productSrarchError}
                                        onKeyDown={this.handleKeyDown}
                                        list="productList"
                                        placeholder="Search Products (Code)..."/>
                                    <datalist id="productList">
                                        {products.map(product => (
                                            <option key={product.code} value={product.code}>{product.name} | {product.productType}</option>
                                        ))}
                                    </datalist>
                                </Form>
                            </Col>
                            <Col md={6}>
                                <Button color="primary" onClick={this.addProductBtn}> Add</Button>
                                <Button color="link" onClick={this.clearProductsTable} > clear all </Button>
                                <Label className="ml-2"> {this.state.productSrarchError} </Label>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={12} >
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Code</th>
                                            <th>Package Size</th>
                                            <th>Num Package</th>
                                            <th>Quantity</th>{/***Autofill ***/}
                                            <th>Rate</th>
                                            <th>Amount</th>{/***Autofill ***/}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.products.map((prod, i) => (
                                            <tr key={`${prod.code}${i}`}>
                                                <td>{i+1}</td>
                                                <td> {this.findProductName(prod.code)} </td>
                                                <td> {prod.code} </td>
                                                <td> <Input type="number" name={i+"-packageSize"} onChange={this.onChangeProduct} value={prod.packageSize} style={{width: "75px"}}></Input> </td>
                                                <td><Input type="number" name={i+"-numPackage"} onChange={this.onChangeProduct} value={prod.numPackage} style={{width: "75px"}}/></td>
                                                <td> {prod.packageSize * prod.numPackage} </td>
                                                <td><Input type="number" name={i+"-rate"} onChange={this.onChangeProduct} value={prod.rate} style={{width: "75px"}}/> </td>
                                                <td> {prod.packageSize * prod.numPackage * prod.rate} </td>
                                                <td> <button
                                                        style={{background: "none", border: "none"}}
                                                        color="red"
                                                        onClick={this.removeProductFromList.bind(this, i)}
                                                    ><FontAwesomeIcon icon={faTrash} /> </button> 
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <h5>New Order summary</h5>
                                        <Row className="mt-1"> 
                                            <Col md={6}>
                                                <Label>Total Amount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>{this.state.totalAmount}</Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Shipping and Handling: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Input type="number" value={this.state.shippingHandling} name="shippingHandling" onChange={this.onChange} />
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Discount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Input type="number" value={this.state.discount} name="discount" onChange={this.onChange} />
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col md={6}>
                                                <h6>Taxable amount: </h6>
                                            </Col>
                                            <Col md={6}>
                                                <Label> {this.state.taxableAmount} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Label>State tax: %</Label>
                                            </Col>
                                            <Col md={3}>
                                                <Input type="number" value={this.state.stateTax} name="stateTax" onChange={this.onChange} />
                                            </Col>
                                            <Col md={6} >
                                                <Label> {this.state.stateTaxAmount} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Label>Central tax: %</Label>
                                            </Col>
                                            <Col md={3} >
                                                <Input type="number" value={this.state.centralTax} name="centralTax" onChange={this.onChange} />
                                            </Col>
                                            <Col md={6} >
                                                <Label> {this.state.centralTaxAmount} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <h3>Grand Total: </h3>
                                            </Col>
                                            <Col md={6}>
                                                <h3><FontAwesomeIcon icon={faRupeeSign}/> {this.state.grandTotal} </h3>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card className="text-muted">
                                    <CardBody style={{background: '#eee'}}>
                                        <h5>Original Order summary</h5>
                                        <Row className="mt-1"> 
                                            <Col md={6}>
                                                <Label>Total Amount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>{formatAmount(this.state.p_totalAmount)}</Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Shipping and Handling: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label > {this.state.p_shippingHandling} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Discount: </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label> {this.state.p_discount} </Label>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col md={6}>
                                                <h6>Taxable amount: </h6>
                                            </Col>
                                            <Col md={6}>
                                                <Label> {formatAmount(this.state.p_taxableAmount)} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>State tax: {this.state.p_stateTax}%</Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount((this.state.p_stateTax / 100) * (this.state.p_taxableAmount))} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <Label>Central tax: {this.state.p_centralTax}%</Label>
                                            </Col>
                                            <Col md={6} >
                                                <Label> {formatAmount((this.state.p_centralTax / 100) * (this.state.p_taxableAmount))} </Label>
                                            </Col>
                                        </Row>

                                        <Row className="mt-1">
                                            <Col md={6}>
                                                <h3>Grand Total: </h3>
                                            </Col>
                                            <Col md={6}>
                                                <h3><FontAwesomeIcon icon={faRupeeSign}/> {formatAmount(this.state.p_grandTotal)}/- </h3>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.errorMsg ? (
                            <h5 color="danger" className="mr-auto ml-2">
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {this.state.errorMsg} 
                            </h5>
                        ) : (null)}
                        <Button color="primary" onClick={this.applyChanges}> Apply Changes </Button>
                        <Button color="secondary" onClick={this.cancelChanges}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    product: state.product,
    customProduct: state.customProduct,
})

export default connect(mapStateToProps, { updateOrderProduct })(ChangeOrderProducts);