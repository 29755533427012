import React from 'react';
import { Button, Card, CardBody, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { unarchiveShippingAddress } from '../../../../actions/CustomerActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { formatAddress } from '../../../constVarAndFunc/formats';

class UndoDeleteShippingAddress extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
    }

    static propTypes = {
        unarchiveShippingAddress: PropTypes.func.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    undoDelete = () => {
        let { customer, address } = this.props;
        address = {
            ...address,
            isActive: true,
        }
        customer = {
            // ...customer,
            _id: customer._id,
            shippingAddress: customer.shippingAddress.map(add => add.addressId === address.addressId ? address : add),
        }

        this.props.unarchiveShippingAddress(customer);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button className="btn-style float-right" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faUndo} />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Undo Delete Shipping address
                    </ModalHeader>

                    <ModalBody style={{whiteSpace: 'pre-wrap'}}>
                        <Label>Undo: </Label>
                        <Card className="shadow-sm m-3">
                            <CardBody>
                                {formatAddress(this.props.address)}
                            </CardBody>
                        </Card>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="info" onClick={this.undoDelete} > Undo</Button>
                        <Button color="secondary" onClick={this.cancel} > Cancel</Button>
                    </ModalFooter>
                    
                </Modal>
            </div>
        )
    }
}

export default connect(null, { unarchiveShippingAddress })(UndoDeleteShippingAddress);