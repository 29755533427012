import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppTabs from './components/AppTabs';
import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { loadUser } from './actions/AuthActions';
import AppNavbar from './components/AppNavbar';
import LoginPage from './components/LoginPage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import TryDemo from './components/TryDemo';
import PrintChallan from './components/Printables/PrintChallan';
import NotFound from './components/NotFound';

class App extends React.Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Switch>
                {/* <AppTabs tab={window.location.hash.replace('#', '')} /> */}
                <Route exact path="/" render={() => < LoginPage /> }/>
                <Route exact path="/login" render={() => < LoginPage /> }/>
                <Route exact path="/products" render={() => < AppTabs key="products" tab={"products"} /> }/>
                <Route exact path="/customers/" render={() => < AppTabs key="customers" tab={"customers"} /> }/>
                <Route exact path="/employees/" render={() => < AppTabs key="employees" tab={"employees"} /> }/>
                <Route exact path="/dashboard/" render={() => < AppTabs key="dashboard" tab={"dashboard"} /> }/>
                <Route exact path="/quickorder/" render={() => < AppTabs key="quickorder" tab={"quickorder"} /> }/>
                <Route exact path="/dispatch/" render={() => < AppTabs key="dispatch" tab={"dispatch"} /> }/>
                <Route exact path="/sales/" render={() => < AppTabs key="sales" tab={"sales"} /> }/>
                <Route exact path="/reports/" render={() => < AppTabs key="reports" tab={"reports"} /> }/>
                <Route exact path="/users/" render={() => < AppTabs key="users" tab={"users"} /> }/>
                <Route exact path="/forgot/" render={() => < ForgotPassword /> }/>
                <Route path="/forgot/reset" render={() => < ResetPassword /> }/>
                <Route path="/demo/" render={() => < TryDemo /> }/>
                <Route path="/*/print/challan" render={() => < PrintChallan /> }/>
                <Route render={() => <NotFound /> } />
            </Switch>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
