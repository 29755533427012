export const VOID = "Void";
export const DECLINE = "Decline";
export const RETURNED = "Returned";
export const PENDING = "Pending";
export const AWAITING_PICKUP = "Awaiting Pickup";
export const PARTIAL = "Partial";
export const SHIPPED = "Shipping";
export const DELIVERED = "Delivered";
export const COMPLETED = "Completed";
export const OTHERS = "Others";

// import { VOID, DECLINE, RETURNED, PENDING, AWAITING_PICKUP, PARTIALLY_SHIPPED, SHIPPED, DELIVERED, COMPLETED, OTHERS } from '';
export const ALL_STATUS = {
    VOID: "Void",
    DECLINE: "Decline",
    RETURNED: "Returned",
    PENDING: "Pendnig",
    AWAITING_PICKUP: "Awaiting Pickup",
    PARTIALLY_SHIPPED: "Partially Shipped",
    SHIPPED: "Shipped",
    DELIVERED: "Delivered",
    COMPLETED: "Completed",
    OTHERS: "Others",
};
