import React from 'react';
import { Label, Row, Col, Card, CardBody, Table, CardHeader, Input, CardFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteCustomProduct from './DeleteCustomProduct';
import ProductCardModal from '../../ProductTab/ProductCardModal';
import UndoDeleteCustomProduct from './UndoDeleteCustomProduct';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ADMINISTRATION_MANAGEMENT } from '../../../constVarAndFunc/UserLevel';

class CustomProducts extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        showArchive: false,
    }

    static propTypes = {
        user: PropTypes.object.isRequired,
    }

    onChangeCheck = e => {
        let isChecked = false;
        if(e.target.checked) {
            isChecked = true;
        }
        this.setState({
            showArchive: isChecked,
        })
    }

    render() {
        // Disable delete if user not privileged
        let privilege = false;
        if(this.props.user.userLevel === ADMINISTRATION_MANAGEMENT) {
            privilege = true;
        }

        let products = [];
        if(this.state.showArchive) {
            products = this.props.customProducts;
        } else {
            products = this.props.activeCustomProducts;
        }

        return (
            <div>
                <Card className="mb-3">
                    <CardHeader>
                        <Label className="float-left mr-auto">Custom Products</Label>
                        <div className="float-right" >
                            <Label className="float-right">Show archived</Label>
                            <Input 
                                type="checkbox"
                                name="showArchive"
                                checked={this.state.showArchive}
                                onChange={this.onChangeCheck}
                            />
                        </div>
                        
                    </CardHeader>
                    <CardBody>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Code</th>
                                    <th>Product Type</th>
                                    <th>Name</th>
                                    <th>GSM</th>
                                    <th>Package Size</th>
                                    <th>Stock</th>
                                    <th>Quantity</th>
                                    {privilege ? (
                                        <th></th>
                                    ) : null}
                                </tr>
                            </thead>

                            <tbody>
                                {products.map(product => (
                                // {/* {(this.state.showArchive ? (customProducts) : (activeCustomProducts)).map(product => ( */}
                                    <tr className={product.isActive ? "table-row" : "table-secondary"}>
                                        <td>
                                            <ProductCardModal customProduct={true} product={product}/>
                                        </td>
                                        <td>{product.code}</td>
                                        <td>{product.productType}</td>
                                        <td>{product.name}</td>
                                        <td>{product.gsm}</td>
                                        <td>{product.packageSize}</td>
                                        <td>{product.stock}</td>
                                        <td> {product.stock * product.packageSize} </td>
                                        {privilege ? (
                                            <td>
                                                {product.isActive ? (
                                                    <DeleteCustomProduct product={product} />
                                                ) : (
                                                    <UndoDeleteCustomProduct product={product} />
                                                )}
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps, {} )(CustomProducts);