import React from 'react';
import { Label, Nav, NavItem, NavLink, TabContent, TabPane, Card, Button, CardTitle, CardBody, Row, Col, } from 'reactstrap';
import Pending from './Pending/Pending';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AWAITING_PICKUP, PARTIALLY_SHIPPED, SHIPPED } from '../../constVarAndFunc/StatusOptions';
import moment from 'moment';
import OrderDetails from '../CustomersTab/Orders/OrderDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class Dispatch extends React.Component {
    state = {
        tab: 'Pending',
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
    }

    setTab = tab => {
        this.setState({
            tab,
        })
    }

    render() {
        let { orders } = this.props.order;
        let inProgressOrders = orders.filter(order => {
            if(order.status === AWAITING_PICKUP || order.status === SHIPPED) {
                return true;
            }
        })

        return (
            <div>
                <Nav justified tabs pills style={{ background: '#F5FFFA' }}>
                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Pending' ? 'active' : null}
                            onClick={() => {this.setTab('Pending')}}
                        >
                            Pending
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'inProcess' ? 'active' : null}
                            onClick={() => {this.setTab('inProcess')}}
                        >
                            In process
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.tab}>
                    <TabPane tabId="Pending">
                        <Pending />
                    </TabPane>

                    <TabPane tabId="inProcess">
                        <h4 className="mt-3">Orders in process</h4>
                        {inProgressOrders.length === 0 ? (
                            <h3> <FontAwesomeIcon color="green" icon={faCheck} /> All set here!</h3>
                        ) : null}
                        <OrderDetails showCustomerName={true} orders={inProgressOrders} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    order: state.order,
})

export default connect(mapStateToProps, {} )(Dispatch);