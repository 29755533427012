import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, Input, Label, Toast, ToastBody, ToastHeader, Tooltip } from 'reactstrap';
import NewOrderModal from '../CustomersTab/Orders/NewOrderModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addOrder } from '../../../actions/OrderActions';
import { formatAddress } from '../../constVarAndFunc/formats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FACTORY_SALE } from '../../constVarAndFunc/ContactType';

class QuickOrder extends React.Component {
    state = {
        search: '',
        searchResult: [],

        // order button tooltip
        tooltipOpen: false,

        // Show order success toast on order.
        orderSuccess: false,
        // Searched true when user hits the search button
        searched: false,
    }

    static propTypes = {
        addOrder: PropTypes.func.isRequired,
        customer: PropTypes.object.isRequired,
        order: PropTypes.object.isRequired,
    }

    componentDidUpdate = prevProps => {
        // Check if order added ? show toast
        if(prevProps.order !== this.props.order) {
            if(prevProps.order.orders.length !== this.props.order.orders.length) {
                this.setState({
                    orderSuccess: true
                })
            }

            setTimeout(() => {
                this.setState({
                    orderSuccess: false,
                })
            }, 4000)
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            searched: false,
        })
    }

    toggleTooltip = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        })
    }

    matchSearch = (string, stateProp) => {
        if(!stateProp) {
            stateProp = this.state.search;
        }
        if(string && stateProp){
            if((String(string).toUpperCase()).includes((stateProp).toUpperCase())) {        
                return true;
            };
        }
        return false;
    }

    getFactorySaleDate = () => {
        let { customers } = this.props.customer;
        let factorySale = {};
        customers.forEach(customer => {
            if(customer.category === FACTORY_SALE) {
                factorySale = customer;
            }
        })

        return factorySale;
    }

    // SEARCH CUSTOMER HELPER METHOD
    getResults = () => {
        let { customers } = this.props.customer;
        let results = [...customers];
        // General Search..
        if(this.state.search) {
            // Show results without advance search -> General Search
            results = results.filter(customer => {
                if(customer.category) {
                    if(this.matchSearch(customer.category)) {
                        return customer;
                    }
                }
                if(customer.customerId) {
                    if(this.matchSearch(customer.customerId)) {
                        return customer;
                    }
                }
                if(customer.customerName) {
                    if(this.matchSearch(customer.customerName)) {
                        return customer;
                    }
                }
                if(customer.email) {
                    if(this.matchSearch(customer.email)) {
                        return customer;
                    }
                }
                if(customer.mobileNumber) {
                    if(this.matchSearch(customer.mobileNumber)) {
                        return customer;
                    }
                }
                if(customer.homeNumber) {
                    if(this.matchSearch(customer.homeNumber)) {
                        return customer;
                    }
                }
                if(customer.address) {
                    if(customer.address.line1) {
                        if(this.matchSearch(customer.address.line1)) {
                            return customer;
                        }
                    }
                    if(customer.address.street) {
                        if(this.matchSearch(customer.address.street)) {
                            return customer;
                        }
                    }
                    if(customer.address.landmark) {
                        if(this.matchSearch(customer.address.landmark)) {
                            return customer;
                        }
                    }
                    if(customer.address.city) {
                        if(this.matchSearch(customer.address.city)) {
                            return customer;
                        }
                    }
                    if(customer.address.state) {
                        if(this.matchSearch(customer.address.state)) {
                            return customer;
                        }
                    }
                }
            });
        }
        return results;
    }

    searchCustomer = () => {
        let searchResult = this.getResults();
        this.setState({ searchResult, searched: true })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.searchCustomer();
        }
    }

    clearSearch = () => {
        this.setState({
            search: '',
            searchResult: [],
            searched: false,
        })
    }

    render() {
        let factorySale = this.getFactorySaleDate();
        return (
            <div>
                {/***** Show toast on order add *****/}
                <Toast isOpen={this.state.orderSuccess} style={{position: "fixed", bottom: "25px", left: "50%"}}>
                    <ToastHeader icon="success">
                        Succeess
                    </ToastHeader>
                    <ToastBody>
                        Order successfully added!
                    </ToastBody>
                </Toast>

                {/********* FACTORY SALE ********/}
                <div>
                    <div className="fab-container">
                        <button className="btn-style fab fab-icon-holder">
                            <FontAwesomeIcon icon={faPlus} size="2x" color="blue" />
                        </button> 

                        <ul className="fab-options">
                            <li>
                                <Label className="fab-label">Add factory sale</Label>
                                <NewOrderModal customer={factorySale} />
                            </li>
                        </ul>

                    </div>
                </div>

                {/******* SEARCH  *********/}
                <Card className="mt-3 mr-3 shadow">
                    <CardBody>
                        <Form inline onSubmit={e => e.preventDefault()}>
                            <Label>Search customer: </Label>
                            <Input
                                className="ml-2"
                                autoComplete="off"
                                type="text"
                                name="search"
                                value={this.state.search}
                                onChange={this.onChange}
                                placeholder="Search..."
                                onKeyDown={this.handleKeyDown}
                            />
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button className="float-right" color="primary" onClick={this.searchCustomer} >Search</Button>
                        <Button className="float-right" color="link" onClick={this.clearSearch} >Clear search</Button>
                    </CardFooter>
                </Card>

                {/******* SEARCH RESULT *********/}
                <div>
                    {this.state.searched && this.state.searchResult.length === 0 ? (
                        <h3 className="mt-3">No match found</h3>
                    ) : null}
                    {this.state.searchResult.map(customer => (
                        <Card className="text-left m-3 d-inline-flex shadow rounded" 
                                style={{ width: '18rem', whiteSpace: "pre-line" }}>
                            <CardHeader>
                                <strong>{customer.category}</strong>
                            </CardHeader>
                            <CardBody>
                                <Label className="mb-0"> {customer.customerName} </Label> {"\n"}
                                <small className="m-0">{customer.email} </small>
                                <hr className="mt-2 mb-4"/>
                                <Label>
                                    <strong>Address:</strong> {formatAddress(customer.address)} 
                                </Label> {"\n"}
                                <Label>
                                    <strong>Contact:</strong> {customer.primaryContact}
                                </Label>
                            </CardBody>
                            <CardFooter>
                                <Button color="link" className="float-left" onClick={() => document.location.href=`/customers/#${customer.customerId}`} >See profile </Button>
                                <div className="float-right" id="placeOrder" >
                                    <NewOrderModal customer={customer} />
                                </div>
                                <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="placeOrder" toggle={this.toggleTooltip} >
                                    Place order here.
                                </Tooltip>
                            </CardFooter>
                        </Card>
                    ))}  
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer,
    order: state.order,
})

export default connect(mapStateToProps, { addOrder } )(QuickOrder);