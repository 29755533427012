import {
    LOADING_EMPLOYEES,
    GET_EMPLOYEES,
    ADD_EMPLOYEE,
    UPDATE_EMPLOYEE,
    DELETE_EMPLOYEE
} from '../actions/types';

const initialState = {
    employees: [],
    activeEmployees: [],
    isLoading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_EMPLOYEES:
            return {
                ...state,
                isLoading: true,
            }
        case GET_EMPLOYEES:
            return {
                ...state,
                isLoading: false,
                employees: action.payload,
                activeEmployees: action.payload.filter(employee => employee.isActive),
            }
        case ADD_EMPLOYEE:
            return {
                ...state,
                employees: [...state.employees, action.payload],
                activeEmployees: [...state.activeEmployees, action.payload]
            }
        case UPDATE_EMPLOYEE:
            let employees = state.employees.map(employee => employee._id === action.payload._id ? action.payload : employee)
            let activeEmployees = employees.filter(employee => employee.isActive);
            return {
                ...state,
                employees,
                activeEmployees,
            }
        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter(employee => employee._id !== action.payload),
                activeEmployees: state.activeEmployees.filter(employee => employee._id !== action.payload)
            }
        default:
            return state;
    }
}