import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addCustomProduct, getCustomProducts } from '../../../../actions/CustomProductActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookMedical, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import AddCustomProductProperty from './AddCustomProductProperty';

class AddCustomProduct extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,

        code: '',
        productType: '',
        name: '',
        gsm: 0,
        pages: 0,
        packageSize: 0,
        finalSize: '',
        price: 0,
        stock: 0,

        properties: [],

        errorMsg: '',
    }

    static propTypes = {
        product: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
        getCustomProducts: PropTypes.func.isRequired,
        addCustomProduct: PropTypes.func.isRequired,
    }

    componentDidMount = () => {
        this.props.getCustomProducts();
    }

    onChange = e => {
        if(e.target.value < 0) return;
        this.setState({
            [e.target.name]: e.target.value,
            errorMsg: '',
        }, () => {
            this.autoGenerateCode();
        })

    }

    autoGenerateCode = () => {
        // code = `z${nameInitials}${uniqueNumber}${pages}`;
        let code = 'z';
        let nameInitials = ''
        let uniqueNumber = 0;
        let pages = this.state.pages;

        let name = this.state.name.toUpperCase().split(' ');
        name.forEach(n => { nameInitials += n.slice(0 ,1) })

        code = `z${nameInitials}${uniqueNumber}${pages}`;

        let codeSet = new Set();
        this.props.product.products.forEach(product => {
            codeSet.add(product.code);
        })
        this.props.customProduct.customProducts.forEach(product => {
            codeSet.add(product.code);
        })

        // Check if unique -> if not update unique number
        while(codeSet.has(code)) {
            uniqueNumber++;
            code = `z${nameInitials}${uniqueNumber}${pages}`;
        }


        this.setState({
            code,
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    removeProp = index => {
        this.setState({
            properties: this.state.properties.filter((p, i) => i !== index)
        })
    }

    addProps = (name, value) => {
        this.setState({
            properties: [...this.state.properties, {name, value}]
        })
    }

    addProduct = () => {
        { //Validation
            if(!this.state.code) {
                this.setState({
                    errorMsg: 'Undefined Code',
                })
                return;
            }

            if(!this.state.productType) {
                this.setState({
                    errorMsg: 'Enter Product Type',
                })
                return;
            }

            if(!this.state.name) {
                this.setState({
                    errorMsg: 'Enter Product Name',
                })
                return;
            }
        }

        let customProduct = {
            customerId: this.props.customer.customerId,
            code: this.state.code,
            productType: this.state.productType,
            name: this.state.name,
            gsm: this.state.gsm,
            pages: this.state.pages,
            packageSize: this.state.packageSize,
            finalSize: this.state.finalSize,
            price: this.state.price,
            stock: this.state.stock,
            props: this.state.properties,
        }
        this.props.addCustomProduct(customProduct);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        let productTypes = new Set();
        this.props.product.products.forEach(product => {
            productTypes.add(product.productType);
        });
        productTypes = [...productTypes];
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}> 
                    <FontAwesomeIcon icon={faBookMedical} size="2x" color="#0275d8" /> 
                </button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <ModalHeader>
                        Add Custom Product
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-2">
                            <Col md={2}>
                                <Label>Code: </Label>
                            </Col>
                            <Col md={10}>
                                <Input 
                                    autoComplete="off"
                                    disabled={true}
                                    placeholder="Auto generated"
                                    type="text"
                                    name="code"
                                    value={this.state.code}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col md={6}>
                                <Label>Product Type: </Label>
                                <Input 
                                    type="text"
                                    autoComplete="off"
                                    name="productType"
                                    value={this.state.productType}
                                    onChange={this.onChange}
                                    list="customProductTypes"
                                    placeholder="Product Type"
                                />
                                    <datalist id="customProductTypes">
                                        {productTypes.map(type => (
                                            <option> {type} </option>
                                        ))}
                                    </datalist>
                                {/* </Input> */}
                            </Col>
                            <Col md={6}>
                                <Label>Name: </Label>
                                <Input 
                                    autoComplete="off"
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    placeholder="Product Name"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-2" >
                            <Col md={4} >
                                <Label>GSM:</Label>
                                <Input 
                                    autoComplete="off"
                                    type="number"
                                    name="gsm"
                                    value={this.state.gsm}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col md={4} >
                                <Label>Pages: </Label>
                                <Input 
                                    autoComplete="off"
                                    type="number"
                                    name="pages"
                                    value={this.state.pages}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col md={4} >
                                <Label>Package Size: </Label>
                                <Input 
                                    autoComplete="off"
                                    type="number"
                                    name="packageSize"
                                    value={this.state.packageSize}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4" >
                            <Col md={4}>
                                <Label>Final Size:</Label>
                                <Input 
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Cut Size"
                                    name="finalSize"
                                    value={this.state.finalSize}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col md={4}>
                                <Label>Price: </Label>
                                <Input 
                                    autoComplete="off"
                                    type="number"
                                    name="price"
                                    value={this.state.price}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col md={4} >
                                <Label>Stock</Label>
                                <Input 
                                    autoComplete="off"
                                    type="number"
                                    name="stock"
                                    value={this.state.stock}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-2" >
                            <Col md={4}>
                                {/* <Button color="link">Add properties...</Button> */}
                                <AddCustomProductProperty addProps={this.addProps} />
                            </Col>

                            <Col md={8} >
                                {this.state.properties.length !== 0 ? (
                                    <Table>
                                        <thead>
                                            <th>Property Name</th>
                                            <th>Property Value</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {this.state.properties.map((prop, i) => (
                                                <tr>
                                                    <td>{prop.name}</td>
                                                    <td>{prop.value}</td>
                                                    <td> 
                                                        <button className="btn-style" onClick={this.removeProp.bind(this, i)}>
                                                            <FontAwesomeIcon icon={faTimes} /> 
                                                        </button> 
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : null}
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        {this.state.errorMsg ? (
                            <Label className="float-left mr-auto" color="danger">
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"/>
                                {this.state.errorMsg}
                            </Label>
                        ) : null}
                        <Button className="float-right" color="primary" onClick={this.addProduct} >Add Product</Button>
                        <Button className="float-right" color="secondary" onClick={this.cancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customProduct: state.customProduct,
    product: state.product,
})

export default connect( mapStateToProps , { addCustomProduct, getCustomProducts })(AddCustomProduct);