import React, { Component } from 'react';
import { Card, FormControl, InputGroup, ModalFooter } from 'react-bootstrap';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateProduct } from '../../../actions/ProductActions';
import { updateCustomProduct } from '../../../actions/CustomProductActions'
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppTabs from '../../AppTabs';
import { ADMINISTRATION_MANAGEMENT } from '../../constVarAndFunc/UserLevel';
import AddCustomProductProperty from '../CustomersTab/CustomProducts/AddCustomProductProperty';

class ProductCardModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state;
  }

  state = {
    modal: false,
    isChanged: false,
    editProduct: false,
    
    newStock: null,

    // Current Tab
    tab: 'Properties',
  };

  static propTypes = {
    updateProduct: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      product: this.props.product,
      _id: this.props.product._id,
      pages: this.props.product.pages,
      gsm: this.props.product.gsm,
      finalSize: this.props.product.finalSize,
      packageSize: this.props.product.packageSize,
      price: this.props.product.price,
      stock: this.props.product.stock,
      props: this.props.product.props,
    });
  };

  setTab = tab => {
    this.setState({
      tab,
    })
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      isChanged: true
    });
    if(e.target.name === 'newStock'){
      this.setState({
        stock: parseFloat(this.props.product.stock) + (e.target.value ? parseFloat(e.target.value) : (0))
      })
    }
  };

  onChangeDetail = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      props: this.state.props.map(prop => prop.name === e.target.name ? {name, value} : prop)
    })
  }

  cancelChanges = () => {
    this.setState(this.initialState)
  };

  editProduct = () => {
    // Allow editing the product details. Enable input fields
    this.setState({
      editProduct: !this.state.editProduct
    })
  };

  addProductProp = (name, value) => {
    this.setState({
      props: [...this.state.props, {name, value}]
    })
  }

  removeProductProp = index => {
    this.setState({
      props: this.state.props.filter((p,i) => i !== index),
    })
  }

  applyChanges = () => {
    //apply changes and update the product detail
    const product = {
      productType: this.props.product.productType,
      _id: this.props.product._id,
      code: this.props.product.code,
      name: this.props.product.name,
      pages: this.state.pages,
      gsm: this.state.gsm,
      finalSize: this.state.finalSize,
      packageSize: this.state.packageSize,
      price: this.state.price,
      stock: this.state.stock,
      props: this.state.props,
    };
    this.setState({
      newStock: 0,
      isChanged: false,
      editProduct: false
    });
    if(this.props.customProduct) {
      this.props.updateCustomProduct(product)
    } else {
      this.props.updateProduct(product);
    }
    this.toggle();
  };

  render() {
    const product = this.props.product;
    const productProps = product.props;

    // Disable/Hide stock update if not a privilege user
    let privilege = false;
    if(this.props.auth.user.userLevel === ADMINISTRATION_MANAGEMENT) {
      privilege = true;
    }

    return (
      <div>
        <Button
          variant="link"
          size="sm"
          className={this.state.isChanged ? "" : "hover-btn"}
          onClick={this.toggle}
        >
          <FontAwesomeIcon icon={ faEdit }/>
        </Button>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          size="sm"
          id="modal"
        >
          <ModalBody>
            <Card className="card chart-card">
              <Card.Body className="card-body pb-0">
                <h4 className="card-title font-weight-bold">
                  {product.productType}
                </h4>
                <div className="d-inline-block">
                  <h5 className="card-text mb-4">
                    {product.name}
                    <small className="card-text text-muted ml-2">
                      {product.code}
                    </small>
                  </h5>
                </div>
                <div className="d-flex justify-content-between">
                  <h4 className="display-4 align-self-end">
                    {this.state.stock}
                  </h4>
                  {/******** STOCK **********/}
                  {privilege ? (
                    <InputGroup
                      className="mt-auto ml-auto mb-auto mr-0"
                      style={{ width: "8rem" }}
                    >
                      <FormControl
                        type="number"
                        onChange={this.onChange}
                        name="newStock"
                        placeholder="Input stock"
                        value={this.state.newStock} // ? this.state.newStock : null} // to show placeholder when 0
                      ></FormControl>
                    </InputGroup>
                  ) : null}

                </div>
                {/**** Quantity *****/}
                <h5 className="mb-3"><strong>Quantity: </strong> {this.state.stock * this.state.packageSize} </h5>

                <Nav tabs justified>
                    <NavItem>
                      <NavLink 
                        className={this.state.tab === 'Properties' ? 'active' : null}
                        onClick={() => { this.setTab('Properties')}}
                      >
                        Properties
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.tab === 'Details' ? 'active' : null}
                        onClick={() => { this.setTab('Details')}}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.tab}>
                  <TabPane tabId="Properties">
                    <InputGroup size="sm" className="mt-3 mb-3">
                      <InputGroup.Prepend>
                        
                        {/******** PAGES **********/}
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          Pages
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        name="pages"
                        value={this.state.pages}
                        onChange={this.onChange}
                        disabled={this.state.editProduct ? false : true}
                        type="number"
                      />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Prepend>
                        
                        {/******** GSM **********/}
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          GSM
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        name="gsm"
                        value={this.state.gsm}
                        onChange={this.onChange}
                        disabled={this.state.editProduct ? false : true}
                        type="number"
                      />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3" name="packageSize">
                      <InputGroup.Prepend>
                        
                        {/******** PACKAGE SIZE **********/}
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          Package Size
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        name="packageSize"
                        value={this.state.packageSize}
                        onChange={this.onChange}
                        disabled={this.state.editProduct ? false : true}
                        type="number"
                      />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Prepend>
                        
                        {/******** PRICE **********/}
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        name="price"
                        value={this.state.price}
                        onChange={this.onChange}
                        disabled={this.state.editProduct ? false : true}
                        type="number"
                      />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Prepend>
                        
                        {/******** FINISH SIZE **********/}
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          Finish Size
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        name="finalSize"
                        value={this.state.finalSize}
                        onChange={this.onChange}
                        disabled={this.state.editProduct ? false : true}
                      />
                    </InputGroup>
                  </TabPane>
                  
                  <TabPane tabId="Details">
                    <div className="mb-3"></div>
                    {this.state.props ? (
                      <div>
                        {this.state.props.map((prop, i) => (
                          <InputGroup size="sm" className="mb-3">
                            <InputGroup.Prepend>
                              
                              <InputGroup.Text id="inputGroup-sizing-sm">
                                {prop.name}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              name={prop.name}
                              value={prop.value}
                              onChange={this.onChangeDetail}
                              disabled={this.state.editProduct ? false : true}
                              type="text"
                            />
                            {privilege ? (
                              <InputGroup.Append>
                                <Button onClick={this.removeProductProp.bind(this, i)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </InputGroup.Append>
                            ) : null}
                          </InputGroup>
                        ))}
                      </div>
                    ) : null}

                    {privilege ? (
                      <AddCustomProductProperty addProps={this.addProductProp} />
                    ) : null}
                  </TabPane>
                </TabContent>
                
              </Card.Body>
            </Card>
          </ModalBody>

          
          {privilege ? (
            <ModalFooter className="m-0 p-0">
              <button
                type="button"
                className="btn btn-outline-secondary ml-3"
                onClick={this.editProduct}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-primary ml-auto"
                id="btn-apply"
                onClick={this.applyChanges}
              >
                Apply
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.cancelChanges}
              >
                Cancel
              </button>
            </ModalFooter>
          ) : null}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { updateProduct, updateCustomProduct })(ProductCardModal);