import React, { Component } from 'react';
import {
  Badge,
    Col,
    Nav,
    NavItem,
    Row,
  Tab,
} from "react-bootstrap";
import Products from './TabContents/ProductTab/Products';
import { faShoppingCart, faAddressBook, faUser, faFileInvoiceDollar, faIdCard, faHome, faStopwatch, faChartLine, faUsers, faTruck, faChartBar, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, NavLink } from 'react-router-dom'
import Customer from './TabContents/CustomersTab/Customer';
import Employees from './TabContents/EmployeesTab/Employees'
import AppNavbar from './AppNavbar';
import Dashboard from './TabContents/DashboardTab/Dashboard';
import QuickOrder from './TabContents/QuickOrder/QuickOrder';
import Sales from './TabContents/SalesTab/Sales';
import Users from './TabContents/UsersTab/Users';
import favicon from '../assets/images/favicon.png'
import Dispatch from './TabContents/DispatchTab/Dispatch';
import Reports from './TabContents/ReportsTab/Reports';

class AppTabs extends Component {
  state = {
    activeTab: this.props.tab || "products",

    stockLimit: 30,
  };

  static propTypes = {
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
  }

  componentDidUpdate = () => {
    const { auth } = this.props;
    if(!auth.isLoading) {
      if(!auth.isAuthenticated) {
        window.location.pathname = '/'
      }
    }
  }

  setTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  setStockLimit = limit => {
    this.setState({
      stockLimit: limit
    })
  }

  redirect = () => {
    if(window.location.hash) {
      window.location.href = '/customers/#';
    }
  }

  render() {
    // Dont render if user not authenticated
    if(!this.props.auth.isAuthenticated) {
      return null;
    }
    //Dont show employees tab if user privilege !== Administration Management
    let privilege = false
    const { userLevel } = this.props.auth.user;
    if(userLevel === 'Administration Management') {
      privilege = true;
    }

    return (
      <div style={{height: "100vh"}}>
        <AppNavbar />
        
        <Tab.Container defaultActiveKey={this.state.activeTab} >
          <Row style={{height: "100vh"}}>
            <Col sm={2} style={{padding: "0"}}>
              <Nav variant="pills" className="flex-column sticky-left" onSelect={(tab) => this.setTab(tab)} activeKey={this.state.activeTab}>
                <Nav.Item className="app-logo-link">
                  <img src={favicon} alt="favicon" style={{ marginTop: "3rem", height: '4rem'}} />
                </Nav.Item>
                <NavItem>
                  <NavLink eventKey="dashboard" className="pl-5 nav-link" to="/dashboard/" >
                    <FontAwesomeIcon className="mr-2" icon={faHome} />
                    Dashboard
                  </NavLink>
                </NavItem>
                
                <NavItem>
                  <NavLink eventKey="products" className="pl-5 nav-link" to="/products/" >
                    <FontAwesomeIcon className="mr-2" icon={faShoppingCart} />
                      Products 
                      <Badge className="ml-2" variant="primary">{this.props.product.activeProducts.length}</Badge>
                      <Badge className="ml-2" variant="danger"> {this.props.product.activeProducts.filter(p => {
                          return p.stock <= this.state.stockLimit;
                        }).length} </Badge>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink eventKey="customers" className="pl-5 nav-link" to="/customers/" onClick={this.redirect}>
                    <FontAwesomeIcon className="mr-2" icon={faUsers} />
                    Customers
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink eventKey="quickorder" className="pl-5 nav-link" to="/quickorder/" >
                    <FontAwesomeIcon className="mr-2" icon={faStopwatch} />
                    Quick Order
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink eventKey="dispatch" className="pl-5 nav-link" to="/dispatch/" >
                    <FontAwesomeIcon className="mr-2" icon={faTruck} />
                    Dispatch
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink eventKey="sales" className="pl-5 nav-link" to="/sales/" >
                    <FontAwesomeIcon className="mr-2" icon={faChartLine} />
                    Sales
                  </NavLink>
                </NavItem>

                {privilege ? (
                  <div>
                    <NavItem>
                      <NavLink eventKey="employees" className="pl-5 nav-link" to="/employees/" >
                        <FontAwesomeIcon className="mr-2" icon={faIdCard} />
                        Employees
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink eventKey="reports" className="pl-5 nav-link" to="/reports/" >
                        <FontAwesomeIcon className="mr-2" icon={faChartBar} />
                        Reports
                        <Badge className="ml-2" variant="primary" > {this.props.report.reports.filter(report => !report.isSubmitted).length} </Badge>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink eventKey="users" className="pl-5 nav-link" to="/users/" >
                        <FontAwesomeIcon className="mr-2" icon={faUserFriends} />
                        Users
                      </NavLink>
                    </NavItem>
                  </div>
                ) : null}
              </Nav>
            </Col>

            <Col sm={10} className="content-tab well well-lg">
              <Tab.Content>
                <Tab.Pane eventKey="dashboard" key="dashboard">
                  <Dashboard />
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content className="pr-0">
                <Tab.Pane eventKey="products" key="products"> 
                  <Products stockLimit={this.state.stockLimit} setStockLimit={this.setStockLimit}/> 
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content>
                <Tab.Pane eventKey="customers" key="customers">
                  <Customer />
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content>
                <Tab.Pane eventKey="quickorder" key="quickorder">
                  <QuickOrder />
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content>
                <Tab.Pane eventKey="dispatch" key="dispatch">
                  <Dispatch />
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content>
                <Tab.Pane eventKey="sales" key="sales">
                  <Sales />
                </Tab.Pane>
              </Tab.Content>

              {privilege ? (
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="employees" key="employees">
                      <Employees />
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="reports" key="reports">
                      <Reports />
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="users" key="users">
                      <Users />
                    </Tab.Pane>
                  </Tab.Content>                  
                </div>
              ) : null}

            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  product: state.product,
  auth: state.auth,
  report: state.report,
})

export default connect( mapStateToProps, {} )(AppTabs);