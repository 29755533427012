import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardFooter, CardHeader, Col, Input, Label, Nav, Navbar, NavItem, NavLink, Row, Toast } from 'reactstrap';
import PropTypes from 'prop-types';
import EditProfile from './EditProfile';
import AddShippingAddress from './AddShippingAddress';
import EditShoppingAddress from './EditShippingAddress';
import DeleteShippingAddress from './DeleteShippingAddress';
import NewOrderModal from '../Orders/NewOrderModal'
import AddCustomProduct from '../CustomProducts/AddCustomProduct';
import { getCustomProducts } from '../../../../actions/CustomProductActions'
import { getOrders, updateOrder } from '../../../../actions/OrderActions';
import OrderDetails from '../Orders/OrderDetails';
import { formatAddress } from '../../../constVarAndFunc/formats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomProducts from '../CustomProducts/CustomProducts';
import UndoDeleteShippingAddress from './UndoDeleteShippingAddress';

class CustomerProfile extends React.Component {
    state = {
        // Info (Collapse for every order -> opens 1 at a time)
        showInfo: false,

        // Show archive shipping address
        showArchiveAddress: false,
        shippingAddressIds: [],

        // Show modal confirmation for status update
        statusModal: false,

        // Orders pagination
        currentPage: 1,
        ordersPerPage: 10,
    }

    static propTypes = {
        customer: PropTypes.object.isRequired,
        order: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        customProduct :PropTypes.object.isRequired,
    }

    componentDidMount = () => {
        this.props.getOrders();
        this.props.getCustomProducts();
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCheckbox = e => {
        let isChecked = false;
        if(e.target.checked) {
            isChecked = true;
        }
        this.setState({
            [e.target.name]: isChecked
        })
    }

    findCustomer = () => {
        let c = {};
        let customerId = window.location.hash.replace('#', '');
        this.props.customer.customers.forEach(customer => {
            if(customer.customerId === customerId) {
                c = customer;
            }
        })
        return c;
    }

    scrollTo = e => {
        const element = document.getElementById(e.target.name);
        if(element) {
            setTimeout(() => {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }, 20)
        }
    }

    paginate = pageNumber => {
        this.setState({
            currentPage: pageNumber,
        })
    }

    render() {
        let { orders } = this.props.order;
        // Handle error when customer not found -> invalid hash (customerId)
        let customer = this.findCustomer();
        orders = orders.filter(order => order.customerId === customer.customerId);

        let { customProducts, activeCustomProducts } = this.props.customProduct;
        // For particular customer
        customProducts = customProducts.filter(product => product.customerId === customer.customerId);
        activeCustomProducts = activeCustomProducts.filter(product => product.customerId === customer.customerId);

        if(!customer) return;

        return (
            <div>
                <Navbar color="light" expand="md" light id="breadcrumb-navbar" className="sticky-top pr-4" >
                    <Nav>
                        <NavItem>
                            <NavLink name="customer-profile" tag="button" className="btn-style" onClick={this.scrollTo} style={{color: "blue"}} > Profile </NavLink>
                        </NavItem>
                        {customer.shippingAddress ? (
                            <NavItem >
                                {customer.shippingAddress.length !== 0 ? (
                                    <NavLink name="customer-shipping-address" tag="button" className="btn-style" onClick={this.scrollTo} style={{color: "blue"}} > Shipping Address </NavLink>
                                ) : null}
                            </NavItem>
                        ) : null}
                        {customProducts ? (
                            <NavItem>
                                {customProducts.length !== 0 ? (
                                    <NavLink name="customer-custom-products" tag="button" className="btn-style" onClick={this.scrollTo} style={{color: "blue"}}> Custom Products </NavLink>
                                ) : null}
                            </NavItem>
                        ) : (null)}
                        <NavItem>
                            <NavLink name="customer-orders" tag="button" className="btn-style" onClick={this.scrollTo} style={{color: "blue"}} > Orders </NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>

                <div data-spy="scroll" data-target="#breadcrumb-navbar" data-offset="200">

                    {/***** PROFILE ******/}
                    <Card id="customer-profile" className="shadow m-2" style={{whiteSpace: 'pre-wrap'}}>
                        <CardHeader>
                            <Row className="ml-2 mr-2">
                                <h5 className="mr-auto"> {customer.category} </h5>
                                <EditProfile customer={customer}/>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6} >
                                    {customer.organizationName ? (
                                        <div>
                                            <h5><strong>Organization Name:</strong> {customer.organizationName} </h5> {"\n"}
                                        </div>
                                    ) : (null)}
                                    <Label><strong>Person Name: </strong> {customer.customerName}</Label> {"\n"}
                                    <Label><strong>Address: </strong></Label> {"\n"}
                                    <Label> {formatAddress(customer.address)} </Label>
                                </Col>

                                <Col md={6} >
                                    <Label><strong>Email: </strong> </Label> <Label> {customer.email} </Label> {"\n"}
                                    <Label><strong>Mobile: </strong> </Label> <Label> {customer.mobileNumber} </Label> {"\n"}
                                    <Label><strong>Home: </strong> </Label> <Label> {customer.homeNumber} </Label> {"\n"}
                                    <br />
                                    <Label><strong>IGST: </strong> </Label> <Label> {customer.igst} </Label> {"\n"}
                                    <Label><strong>CGST: </strong> </Label> <Label> {customer.cgst} </Label> {"\n"}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {/***** SHIPPING ADDRESS ******/}
                    <Row id="customer-shipping-address" className="m-3 shadow" form>
                        <Col md={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Label className="ml-3 mr-auto">Shipping Addresses: </Label>
                                        <div className="mr-3">
                                            <Input 
                                                className="mr-2"
                                                type="checkbox"
                                                name="showArchiveAddress"
                                                checked={this.state.showArchiveAddress}
                                                onChange={this.onChangeCheckbox}
                                            />
                                            <Label>Show archive</Label>
                                        </div>
                                        <AddShippingAddress customer={customer}/>
                                    </Row>
                                </CardHeader>
                                {customer.shippingAddress ? (
                                    <CardBody >
                                        {customer.shippingAddress.length === 0 ? "No shipping address" : null}
                                        <Row >
                                            {customer.shippingAddress.map(sAddress => (
                                                (!this.state.showArchiveAddress && !sAddress.isActive ? null : (
                                                    <Col md={4} className="float-left mb-2">
                                                        <Card className="shadow-sm" style={{whiteSpace: 'pre-wrap'}}>
                                                            <CardBody style={!sAddress.isActive ? {background: 'lightgrey'} : null}>
                                                                <Label> {formatAddress(sAddress)} </Label>
                                                            </CardBody>
                                                            {sAddress.isActive ? (
                                                                <CardFooter>
                                                                    <EditShoppingAddress customer={customer} address={sAddress}/>
                                                                    <DeleteShippingAddress customer={customer} address={sAddress} />
                                                                </CardFooter>
                                                            ) : (
                                                                <CardFooter>
                                                                    <UndoDeleteShippingAddress customer={customer} address={sAddress} />
                                                                </CardFooter>
                                                            )}
                                                        </Card>
                                                    </Col>
                                                ))
                                            ))}
                                        </Row>
                                    </CardBody>
                                ) : null}
                            </Card>
                        </Col>
                    </Row>

                    {/*********** CUSTOM PRODUCTS **********/}
                    {customProducts.length !== 0 ? (
                        <div id="customer-custom-products">
                            <CustomProducts 
                                customProducts={customProducts}
                                activeCustomProducts={activeCustomProducts}
                            />
                        </div>    
                    ) : null}

                    {/********** ORDERS ********/}
                    {orders.length !== 0 ? (
                        <div id="customer-orders" >
                            <OrderDetails 
                                orders={orders}
                                showCustomerName={false}
                                paginate={true}
                            />
                        </div>

                    ) : null}

                    {/***** PLACE ORDER ******/}
                    {/* <NewOrderModal customer={customer} /> */}
                    <div className="fab-container">
                        <button className="btn-style fab fab-icon-holder">
                            <FontAwesomeIcon icon={faPlus} size="2x" color="blue" />
                        </button> 

                        <ul className="fab-options">
                            <li>
                                <Label className="fab-label">Add New Product</Label>
                                <AddCustomProduct customer={customer} />
                            </li>
                            <li>
                                <Label className="fab-label">Add New Order</Label>
                                <NewOrderModal customer={customer} />
                            </li>
                        </ul>

                    </div>

                    {/****** If customer not found with given custoemr Id (window.locatoin.hash) *****/}
                    {Object.keys(customer).length === 0 && !this.props.customer.isLoading ? (
                        <Toast style={{position: "fixed", bottom: "30px", left: "40%"}}>
                            <h4 className="p-3" > No customer with customer ID: {window.location.hash.replace('#', '')}</h4>
                        </Toast>
                    ) : (null)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer,
    order: state.order,
    product: state.product,
    customProduct: state.customProduct,
})

export default connect( mapStateToProps, { getOrders, updateOrder, getCustomProducts } )(CustomerProfile);