import React from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { unarchiveCustomProduct } from '../../../../actions/CustomProductActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

class UndoDeleteCustomProduct extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
    }

    static propTypes = {
        unarchiveCustomProduct: PropTypes.func.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    undoChanges = () => {
        let product = {
            _id: this.props.product._id,
            isActive: true,
        }

        this.props.unarchiveCustomProduct(product);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        const { product } = this.props;
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faUndo} />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Unarchive (undo)
                    </ModalHeader>
                    <ModalBody style={{whiteSpace: "pre-wrap"}}>
                        <Label>Are you sure you want to unarchive this product? </Label>{"\n"}
                        <Label><strong>Code: </strong> {product.code} </Label> {"\n"}
                        <Label><strong>Type: </strong> {product.productType} </Label> {"\n"}
                        <Label><strong>Name: </strong> {product.name} </Label> {"\n"}
                    </ModalBody>

                    <ModalFooter>
                        <Button color="info" onClick={this.undoChanges} >Undo</Button>
                        <Button color="secondary" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { unarchiveCustomProduct })(UndoDeleteCustomProduct);