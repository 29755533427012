import { faBoxTissue, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ModalBody } from 'react-bootstrap';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { addCustomer } from '../../../actions/CustomerActions'
import { 
    COLLEGE_UNIVERSITY, 
    COMMUNITY, 
    CORPORATION, 
    EXPORT, 
    FACTORY_SALE, 
    GOVERNMENT, 
    ONLINE, 
    PERSON, 
    RETAIL, 
    SCHOOL, 
    TENDER, 
    WHOLESALE, 
    OTHERS, 
} from '../../constVarAndFunc/ContactType';

class NewCustomerModal extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;

        // Creating category options with id
        this.categoryOptions = [
            COLLEGE_UNIVERSITY, 
            COMMUNITY, 
            CORPORATION, 
            EXPORT, 
            GOVERNMENT, 
            ONLINE, 
            PERSON, 
            RETAIL, 
            SCHOOL, 
            TENDER, 
            WHOLESALE, 
            OTHERS, 
        ];
    }

    state = {
        modal: false,

        category: null,
        categoryError: '',
        customerName: null,
        customerNameError: '',
        mobileNumber: null,
        mobileNumberError: '',
        homeNumber: null,
        organizationName: null,
        email: null,
        emailError: '',

        //address details
        line1: null,
        street: null,
        landmark: null,
        city: null,
        state: null,
        country: null,
        zipCode: null,

        date: null,

        igst: null,
        cgst: null,
        notes: null,
    }

    static propTypes = {
        addCustomer: PropTypes.func.isRequired,
        customer: PropTypes.object.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })

        // Removing error form feeedback on change
        if(e.target.name === 'category') {
            this.setState({
                categoryError: ''
            })
        }

        if(e.target.name === 'customerName') {
            this.setState({
                customerNameError: ''
            })
        }

        if(e.target.name === 'mobileNumber') {
            this.setState({
                mobileNumberError: ''
            })
        }

        if(e.target.name === 'email') {
            this.setState({
                emailError: ''
            })
        }
    }

    cancelCustomer = () => {
        this.setState( this.initialState )
    }

    addNewCustomer = () => {
        // Validate input fields. return if error true
        let error = false;
        if (!this.state.category) {
            this.setState({
                categoryError: 'Please select a category'
            })
            error = true;
        }

        if(!this.state.customerName) {
            this.setState({
                customerNameError: 'Please Enter a name'
            })
            error = true;
        } else {
            if(this.state.customerName.length < 3) {
                this.setState({
                customerNameError: 'Please Enter a name'
            })
            error = true;
            }
        }

        if(this.state.mobileNumber) {
            if(this.state.mobileNumber.length < 10) {
                this.setState({
                    mobileNumberError: 'Minimum length of 10 is required'
                })
                error = true;
            }
        }

        if(this.state.email) {
            if(!this.state.email.includes('@') || !this.state.email.includes('.')) {
                this.setState({
                    emailError: 'Invalid Email'
                })
                error = true;
            }
        }

        if(error) return;
        // Create Customer object
        // 1. Create customerId
        let customerId = new Date().valueOf() + (Math.floor(Math.random() * 9)).toString();
        customerId = customerId.slice(2);
        // 2. Creation Date
        let date = moment().locale('en').format('YYYY-MM-DD');
        // 3. Customer Data
        let addressId = null;
        if(this.state.line1 || this.state.street || this.state.landmark || this.state.city || this.state.state || this.state.country || this.state.zipCode) {
            addressId = customerId + 'a';
        }
        let newCustomer = {
            customerId,
            category: this.state.category,
            customerName: this.state.customerName,
            organizationName: this.state.organizationName,
            mobileNumber: this.state.mobileNumber,
            homeNumber: this.state.homeNumber,
            email: this.state.email,
            address: {
                addressId,
                line1: this.state.line1,
                street: this.state.street,
                landmark: this.state.landmark,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                zipCode: this.state.zipCode,
            },
            date,
            igst: this.state.igst,
            cgst: this.state.cgst,
            notes: this.state.notes,
        }
        // Add customer to redux and DB
        this.props.addCustomer(newCustomer);
        this.props.clearSearch();
        this.props.showToast(newCustomer);
        this.cancelCustomer();
    }

    render() {
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}>
                    <FontAwesomeIcon size="2x" icon={faUserPlus} color="#0275d8"> </FontAwesomeIcon>
                </button>

                <Modal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                >
                    <ModalHeader>
                        Add new customer
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Category *</Label>
                                        <Input 
                                            invalid={this.state.categoryError} 
                                            value={this.state.category} 
                                            onChange={this.onChange} 
                                            type="select" 
                                            name="category" 
                                            placeholder="(Contact Type)"
                                        >
                                            <option selected disabled>Select a Category...</option>
                                            {this.categoryOptions.map(option => (
                                                <option>{option}</option>
                                            ))}
                                        </Input>
                                        <FormFeedback> {this.state.categoryError} </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Person Name: *</Label>
                                            <Input 
                                                type="text"
                                                invalid={this.state.customerNameError}
                                                onChange={this.onChange}
                                                name="customerName"
                                                value={this.state.customerName} 
                                                placeholder="(Name)" />
                                             <FormFeedback > {this.state.customerNameError} </FormFeedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Organization Name:</Label>
                                            <Input 
                                                type="text"
                                                onChange={this.onChange}
                                                name="organizationName"
                                                value={this.state.organizationName} 
                                                placeholder="(Company Name)" /> 
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <br />
                            <Row >
                                <Col md={12} >
                                    <Label>Contact Number</Label>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Mobile:</Label>
                                            <Input 
                                                type="text"
                                                onChange={this.onChange}
                                                name="mobileNumber"
                                                value={this.state.mobileNumber}
                                                invalid={this.state.mobileNumberError} 
                                                placeholder="(Mobile Number)" />
                                            <FormFeedback> {this.state.mobileNumberError} </FormFeedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={6} >
                                    <Form >
                                        <FormGroup>
                                            <Label>Home:</Label>
                                            <Input 
                                                type="text"
                                                onChange={this.onChange}
                                                name="homeNumber"
                                                value={this.state.homeNumber}
                                                placeholder="(Home Number)" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col md={12} >
                                    <Form>
                                        <FormGroup>
                                        <Label>Email:</Label>
                                        <Input 
                                            type="email"
                                            onChange={this.onChange}
                                            name="email"
                                            value={this.state.email}
                                            invalid={this.state.emailError} 
                                            placeholder="(email address)" />
                                            <FormFeedback> {this.state.emailError} </FormFeedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <br />
                            <Row >
                                <Col>
                                    <Label>Address</Label>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.line1} type="address" name="line1" placeholder="(Address Line1)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.street} type="text" name="street" placeholder="(Street/Road)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={12}>
                                    <Input onChange={this.onChange} value={this.state.landmark} type="text" name="landmark" placeholder="(Landmark)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.city} type="text" name="city" placeholder="(City/Town)" />
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.state} type="text" name="state" placeholder="(State/Province)" />
                                </Col>

                                <Col md={4}>
                                    <Input onChange={this.onChange} value={this.state.country} type="text" name="country" placeholder="(Country)" />
                                </Col>
                            </Row>

                            <Row form className="mt-2">
                                <Col md={4} >
                                    <Input onChange={this.onChange} value={this.state.zipCode} type="text" name="zipCode" placeholder="(Zip/Pin Code)" />
                                </Col>
                            </Row>
                            
                            <Row form className="mt-3">
                                <Col md={6} >
                                    <Label>IGST</Label>
                                    <Input onChange={this.onChange} value={this.state.igst} type="text" name="igst" placeholder="(IGST)" />
                                </Col>

                                <Col md={6} >
                                    <Label>CGST</Label>
                                    <Input onChange={this.onChange} value={this.state.cgst} type="text" name="cgst" placeholder="(CGST)" />
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col md={12} >
                                    <Label>Notes:</Label>
                                    <Input 
                                        type="textarea"
                                        onChange={this.onChange}
                                        name="notes"
                                        value={this.state.notes}
                                        placeholder="(Enter notes here...)" /> 
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Label className="mr-auto" color="red"> {this.state.errorMsg} </Label>
                        <Button className="float-right" color="primary" onClick={this.addNewCustomer}>Add Customer</Button>
                        <Button className="float-right" color="secondary" onClick={this.cancelCustomer}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer
})

export default connect( mapStateToProps, { addCustomer })(NewCustomerModal);