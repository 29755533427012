import { LOADING_USERS, GET_USERS, ADD_USER, UPDATE_USER } from './types';
import axios from 'axios';
import { tokenConfig } from './AuthActions';
import { addLog } from './LogActions';

export const loadingUsers = () => {
    return {
        type: LOADING_USERS,
    }
}

export const getUsers = () => (dispatch, getState) => {
    dispatch(loadingUsers);

    axios
        .get('/api/users', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_USERS,
                payload: res.data,
            })
        })
        .catch(err => {
            console.error(err);
        })
}

export const addUser = user => (dispatch, getState) => {
    axios
        .post('/api/users/', user, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_USER,
                payload: res.data,
            })

            // Add log
            const log = {
                type: ADD_USER,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: user,
            }
            addLog(log, dispatch);
        })
        .catch(err => console.error(err))
}

export const updateUser = user => (dispatch, getState) => {
    let id = user._id;
    axios
        .put(`/api/users/${id}`, user, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_USER,
                payload: res.data,
            })

            // Add log
            const log = {
                type: UPDATE_USER,
                userId: getState().auth.user._id,
                userName: getState().auth.user.name,
                reqBody: user,
            }
            addLog(log, dispatch);
        })
        .catch(err => console.error(err))
}

