import React, { Component } from 'react';
import { Button, Form, FormControl, InputGroup, Navbar, OverlayTrigger, Popover, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getProducts } from '../../../actions/ProductActions';
import ProductCardModal from "./ProductCardModal";
import PropTypes from 'prop-types';
import NewProductModal from './NewProductModal';
import DeleteProductModal from "./DeleteProductModal";
import { faCog, faTrash, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, UncontrolledPopover, Row, Col } from 'reactstrap';
import UndoDeleteProduct from './UndoDeleteProduct';
import { ADMINISTRATION_MANAGEMENT } from '../../constVarAndFunc/UserLevel';
import InventoryCount from './InventoryCount';
import AddStock from './AddStock';
import DamagedProducts from './DamagedProducts';

class Products extends Component {
  state = {
    //search product (filter)
    search: '',

    //Show Columns on table
    productType: false,
    code: true,
    name: true,
    pages: true,
    gsm: false,
    finalSize: false,
    packageSize: true,
    price: false,
    stock: true,

    // Show arhived products
    showArchiveProducts: false,
  };

  componentDidMount() {
    this.props.getProducts();
  }

  searchProducts = (products) => {
    const filteredProducts = products.filter(product => {
      if((String(product.productType).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.code).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.name).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.pages).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.gsm).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.finalSize).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.packageSize).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.price).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      } else if((String(product.stock).toUpperCase()).includes((this.state.search).toUpperCase())) {
        return product;
      }
    })
    return filteredProducts;
  }

  clearSearch = () => {
    this.setState({
      search: ''
    })
    this.render();
  }

  static propTypes = {
    getProducts: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if(e.target.name === 'search') {
      this.render();
    }
  };

  onChangeCheckbox = e => {
    let isChecked = false;
    if(e.target.checked) {
      isChecked = true;
    }
    this.setState({
      showArchiveProducts: isChecked,
    })
  }

  deleteAllProduct = (type) => {
    this.props.product.products.forEach(product => {
      if(product.productType === type) {
        // deleteProduct(product._id);
      }
    })
  }

  toggleColumn = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  };

  getClassTableRow = (stock, isActive) => {
    let className = "table-row ";
    if(!isActive) {
      className += " table-secondary";
    } else if(stock <= this.props.stockLimit) {
      className += " table-danger";
    }
    return className;
  }

  render() {
    let products;
      // Show All products or Active products
    if(this.state.showArchiveProducts) {
      products = this.props.product.products;
    } else {
      products = this.props.product.activeProducts;
    }
    if(this.state.search) {
      products = this.searchProducts(products);
    }
    const types = new Set();
    products.forEach((product) => {
      types.add(product.productType);
    });

    // Disable delete, unarchieve and show archieve -> if user not privileged
    let privilege = false;
    if(this.props.user.userLevel === ADMINISTRATION_MANAGEMENT) {
      privilege = true;
    }


 //   {/*******POP OVER SETTINGS *************/}
    const popover = (
      <Popover style={{width: "250px"}}>
        <Popover.Title as="h3">Settings </Popover.Title>
        <Popover.Content>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              
              {/******** Stock limit **********/}
              <InputGroup.Text id="inputGroup-sizing-sm">
                Stock limit
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              name="stockLimit"
              value={this.props.stockLimit}
              onChange={e => this.props.setStockLimit(e.target.value)}
              type="number"
            />
          </InputGroup>

          {/* <Form.Check
            type="checkbox"
            className="mt-2 mb-2 mr-sm-2"
            label="Product Type"
            name="productType"
            onClick={this.toggleColumn}
            checked={this.state.productType}
          /> */}

          <Form.Check
            type="checkbox"
            className="mt-2 mb-2 mr-sm-2"
            label="Pages"
            name="pages"
            onClick={this.toggleColumn}
            checked={this.state.pages}
          />
          <Form.Check
            type="checkbox"
            className="mt-2 mb-2 mr-sm-2"
            label="GSM"
            name="gsm"
            onClick={this.toggleColumn}
            checked={this.state.gsm}
          />
          <Form.Check
            type="checkbox"
            className="mt-2 mb-2 mr-sm-2"
            label="Final Size"
            name="finalSize"
            onClick={this.toggleColumn}
            checked={this.state.finalSize}
          />
          <Form.Check
            type="checkbox"
            className="mt-2 mb-2 mr-sm-2"
            label="Package Size"
            name="packageSize"
            onClick={this.toggleColumn}
            checked={this.state.packageSize}
          />
          <Form.Check
            type="checkbox"
            className="mt-2 mb-2 mr-sm-2"
            label="Price"
            name="price"
            onClick={this.toggleColumn}
            checked={this.state.price}
          />
          <hr />
          {privilege ? (
              <Form.Check
                type="checkbox"
                className="mt-2 mb-2 mr-sm-2"
                label="Show Archive"
                name="showArchiveProducts"
                onChange={this.onChangeCheckbox}
                checked={this.state.showArchiveProducts}
              />
          ) : null}
        </Popover.Content>
      </Popover>
    );

    return (
      <div>
        {/***** Loading spinner *****/}
        {this.props.product.isLoading ? (
          <div className="loading-spinner">
            <Spinner animation="grow" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <Label className="ml-2" >Loading...</Label>
          </div>
        ) : null}

        {/********** NAVBAR SEARCH ***********/}
        <Navbar className="sticky-top" bg="success" variant="success" style={{ height: 40 }}>
          <InventoryCount />
          <AddStock />
          <Button id="PopoverSettings" variant="success" className="mr-auto float-left" >
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              Settings
          </Button>
          <UncontrolledPopover
            target="PopoverSettings"
            trigger="legacy"
            placement="bottom-end"
            // style={{width: "250px"}}
          >
            {popover}
          </UncontrolledPopover>
        <Form inline className="ml-auto" onSubmit={e => { e.preventDefault(); }}>
          <FormControl size="sm" type="text" placeholder="Search" className="mr-sm-2" name="search" onChange={this.onChange} value={this.state.search}/>
          <Button size="sm" variant="outline-primary" onClick={this.clearSearch}> <FontAwesomeIcon icon={faTimes}/> </Button>
        </Form>
        </Navbar>

        {/**** ADD NEW PRODUCT *****/}
        <div className="fab-container">
          <button className="btn-style fab fab-icon-holder">
              <FontAwesomeIcon icon={faPlus} size="2x" color="blue" />
          </button> 

          <ul className="fab-options">
              <li>
                  <Label className="fab-label">Add New Product</Label>
                  <NewProductModal />
              </li>
          </ul>

      </div>
        {/* <NewProductModal /> */}

        {/********* Load items **********/}
        {Array.from(types).map((type) => (
          <div
            key={type}
            className="rounded shadow mr-3"
            style={{ background: "white" }}
          >
            <div className="panel panel-default mt-3 mb-3 pr-3 pl-3 shadow-sm">
              <div
                className="panel-heading pt-3 pb-3 float-left shadow-sm"
                style={{ width: "100%" }}
              >
                <h5 className="float-left panel-title">{type}</h5>
                {/* <NewProductModal type={type} /> */}
              </div>
            </div>
            <div className="panel-body">
              
              
              {/************ Table view *************/}
              <Table hover size="sm">
                <thead>
                  {/**********Head***********/}
                  <tr>
                    <th style={{ width: 5 }}></th>
                    {this.state.productType ? <th> Type </th> : null}
                    {this.state.code ? <th> Code </th> : null}
                    {this.state.name ? <th> Name </th> : null}
                    {this.state.pages ? <th> Pages </th> : null}
                    {this.state.gsm ? <th> GSM </th> : null}
                    {this.state.finalSize ? <th> Final Size </th> : null}
                    {this.state.packageSize ? <th> Package Size </th> : null}
                    {this.state.price ? <th> Price </th> : null}
                    {this.state.stock ? <th> Stock </th> : null}
                    <th> Quantity </th>
                    {privilege ? (
                      <th style={{ width: 5 }}>
                        <Button size="sm" variant="default" onClick={this.deleteAllProduct.bind(this, type)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                {products.map((product, i) => (
                  <tbody key={product.code}>
                    
                    {/**********Body**********/}
                    {product.productType === type ? (
                      <tr
                        className={this.getClassTableRow(product.stock, product.isActive)}
                      >
                        <td>
                          {product.isActive ? (
                            <ProductCardModal product={product}/>
                          ) : null}
                        </td>
                        {this.state.productType ? <td> {product.productType} </td> : null}
                        {this.state.code ? <td> {product.code} </td> : null}
                        {this.state.name ? <td> {product.name} </td> : null}
                        {this.state.pages ? <td> {product.pages} </td> : null}
                        {this.state.gsm ? <td> {product.gsm} </td> : null}
                        {this.state.finalSize ? <td> {product.finalSize} </td> : null}
                        {this.state.packageSize ? <td> {product.packageSize} </td> : null}
                        {this.state.price ? <td> {product.price} </td> : null}
                        {this.state.stock ? <td className="table-row-stock"> {product.stock} </td> : null}
                        <td> {product.stock * product.packageSize} </td>
                        {privilege ? (
                          <td width="10px">
                            {product.isActive ? (
                              <DeleteProductModal product={product} />
                            ) : (
                              <UndoDeleteProduct product={product} />
                            )}
                          </td>
                        ) : null}
                      </tr>
                    ) : null}
                  </tbody>
                ))}
              </Table>
            </div>
          </div>
        ))}
        <Row className="m-3">
            <DamagedProducts />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    product: state.product,
    user: state.auth.user,
});

export default connect(mapStateToProps, { getProducts })(Products);