import { faPlus, faTrash, faRupeeSign, faExclamationCircle, faMinusSquare, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { addOrder } from '../../../../actions/OrderActions';
import { getEmployees } from '../../../../actions/EmployeeActions';
import { formatAddress, formatAmount, formatBackToNumber } from '../../../constVarAndFunc/formats';
import { AWAITING_PICKUP, COMPLETED, DECLINE, DELIVERED, PARTIAL, PENDING, RETURNED, SHIPPED } from '../../../constVarAndFunc/StatusOptions';
import { FACTORY_SALE } from '../../../constVarAndFunc/ContactType'


class NewOrderModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        //Customer and shippingAddress set in toggle();
        modal: false,

        //Sales Exec Name
        employeeId: null,
        employeeName: null,
        employeeJobTitle: null,

        //checkbox
        shippingSameAsAbove: true,
        //radio button to select shipping address
        shippingAddressId: '',

        //Add products
        searchProduct: '',  //Seach input
        products: [], //array of product object,
        productsBackup: [],
        productSrarchError: '',

        //order details (NOTE: change onChange to update grandTotal)
        totalAmount: 0,
        shippingHandling: 0,
        discount: 0,
        taxableAmount: 0,
        stateTax: 6,    //in percentage
        stateTaxAmount: 0,
        centralTax: 6,  //in percentage
        centralTaxAmount: 0,
        grandTotal: 0,

        orderDate: moment().locale('en').format('YYYY-MM-DD'),
        deliveryDate: '',
        status: PENDING,
        notes: '',

        errorMsg: '',
        
        // Payment info
        addPayment: false,
        dateOfPayment: moment().locale('en').format('YYYY-MM-DD'),
        method: null,
        amount: null,
        fullPayemnt: false,
        paymentNotes: null,
    };

    static propTypes = {
        product: PropTypes.object.isRequired,
        addOrder: PropTypes.func.isRequired,
        employee: PropTypes.object.isRequired,
        getEmployees: PropTypes.func.isRequired,
        customProduct: PropTypes.object.isRequired,
    };

    componentDidMount = () => {
        this.props.getEmployees();
    }

    statusList = [
        DECLINE, //'Decline',  // danger
        RETURNED, //'Returned & Refund',    //dark
        //'Returned & Replace',   //dark
        PENDING, //'Pending',  // Seconday
        AWAITING_PICKUP, //'Awaiting Pickup',      // secodary
        PARTIAL, //'Partially Shipped',    //info
        SHIPPED, //'Shipped',      //primary
        DELIVERED, //'Recieved',     //primary
        COMPLETED, //'Completed',    //success
    ]

    handleKeyPress = (event) => {
        // [Enter] should not submit the form when choosing an address.
        if (event.keyCode === 13) {
            event.preventDefault();
            this.addProductBtn();
        }
    }

    onChange = e => {
        // Return if value less than 0
        if(e.target.value < 0) return;

        // Calclating and updating grand total when order details are changed (AFTER STATE CHANGE)
        this.setState({ [e.target.name]: e.target.value }, () => { 
            this.updateOrderSummary();
            // Set employeeName by employeeId
            this.setEmployeeName();
        });
        this.setState({ errorMsg: '' })

        // Remove error from product search on changing search field
        if(e.target.name === "searchProduct") {
            this.setState({
                productSrarchError: ''
            })
        }
    }

    onChangeProduct = e => {
        // Return if value less than 0
        if(e.target.value < 0) return;

        let [num, name] = e.target.name.split('-');
        num = parseInt(num)

        // Selecting product from products array and the key as name
        let newP = this.state.products[num];
        newP[name] = e.target.value;

        this.setState({
            products: this.state.products.map((p, i) => i === num ? newP : p)
        })
        this.updateOrderSummary();
    }

    onChangeCheckBox = e => {
        if(e.target.name === 'fullPayment') {
            this.setState({
                fullPayemnt: !this.state.fullPayemnt,
                amount: formatBackToNumber(this.state.grandTotal),
            });
        }
    }

    getEmployee = employeeId => {
        return this.props.employee.employees.find(employee => employee.employeeId === employeeId);
    }

    setEmployeeName = () => {
        let employee = this.getEmployee(this.state.employeeId);
        if(employee) {
            this.setState({
                employeeName: employee.fullName,
                employeeJobTitle: employee.jobTitle
            })
        } else {
            this.setState({
                employeeName: '',
                employeeJobTitle: '',
            })
        }
    }

    updateOrderSummary = () => {
        let total = 0;
        this.state.products.forEach(p => {
            total += (p.packageSize * p.numPackage * p.rate);
        })

        let totalAmount = total;
        let taxableAmount = parseFloat(totalAmount) + parseFloat(this.state.shippingHandling) - parseFloat(this.state.discount);
        let stateTaxAmount = (parseFloat(taxableAmount) * parseFloat(this.state.stateTax / 100)).toFixed(2);
        let centralTaxAmount = (parseFloat(taxableAmount) * parseFloat(this.state.centralTax / 100)).toFixed(2);
        let grandTotal = parseFloat(stateTaxAmount) + parseFloat(centralTaxAmount) + parseFloat(taxableAmount);

        this.setState({
            totalAmount: formatAmount(totalAmount),
            taxableAmount: formatAmount(taxableAmount), 
            stateTaxAmount: formatAmount(stateTaxAmount), 
            centralTaxAmount: formatAmount(centralTaxAmount), 
            grandTotal: (formatAmount(grandTotal)+"/-"),
        })
    }

    addProductBtn = () => {
        let found = false;
        let code = this.state.searchProduct;
        this.props.product.products.forEach(product => {
            if(product.isActive) {
                if (code.toLowerCase() === product.code.toLowerCase()) {
                    found = true;
                    let newProduct = {
                        name: product.name,
                        productType: product.productType,
                        code: product.code,
                        stock: product.stock,
                        packageSize: product.packageSize,
                        numPackage: 0,
                        rate: product.price,
                    }
                    
                    this.setState({
                        products: [...this.state.products, newProduct]
                    });
                }
            }
        })

        if(!found) {
            let { activeCustomProducts } = this.props.customProduct;
            activeCustomProducts.forEach(product => {
                if (code === product.code) {
                    found = true;
                    let newProduct = {
                        name: product.name,
                        productType: product.productType,
                        code: product.code,
                        stock: product.stock,
                        packageSize: product.packageSize,
                        numPackage: 0,
                        rate: product.price,
                    }
                    
                    this.setState({
                        products: [...this.state.products, newProduct]
                    });
                }
            })
        }


        if(!found) { //did not find the product
            this.setState({
                productSrarchError: 'Product Not Found'
            })
        } else {    //product found
            this.setState({
                productSrarchError: '',
                searchProduct: '',
            })
            this.updateOrderSummary();
        }
    }

    removeProductFromList = index => {
        let productsBU = this.state.products.filter((p, i) => i !== index);
        this.setState({
            products: []
        });

        // Update Order summary when the products list is empty! (i.e total = 0) (Sync)
        if(productsBU.length === 0) {
            this.setState({
                products: []
            }, () => {
                this.updateOrderSummary();
            })   
        }

        // set timeout so react does not re render due to deletion in setState
        productsBU.forEach(p => {
            setTimeout(() => {
                this.setState({
                    products: [...this.state.products, p]
                }, () => {
                    this.updateOrderSummary();
                })
            }, 20)
        })
    }

    clearProductsTable = () => {
        this.setState({
            products: [],
            totalAmount: 0,
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            customer: this.props.customer,
        });
        if(this.state.shippingSameAsAbove) {
            this.setState({
                shippingAddressId: this.props.customer.address.addressId 
            })
        }
    }

    toggleCheckbox = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })

        if(e.target.checked) {
            this.setState({
                shippingAddressId: this.state.customer.address.addressId
            })
        } else {
            this.setState({
                shippingAddressId: ''
            })
        }
    };

    toggleCollapse = e => {
        e.preventDefault();
        this.setState({
            addPayment: !this.state.addPayment,
        })
    }

    slecteShippingAdd = e => {
        this.setState({
            shippingAddressId: e.target.value
        });
    }

    addOrder = () => {
        // Validate all fields
        {
            if(this.state.employeeId) {
                let employee = this.getEmployee(this.state.employeeId);
                if(!employee) {
                    this.setState({ errorMsg: 'Sales Person not found'});
                    return;
                }
            }
            if(!this.state.shippingAddressId) {
                this.setState({ errorMsg: 'Please select shipping address' });
                return;
            }
            if(this.state.products.length === 0) {
                this.setState({ errorMsg: 'No products in the order list' });
                return;
            }
            if(this.state.totalAmount == 0) {
                this.setState({ errorMsg: 'Cannot make order with amount 0/-' });
                return;
            }
            if(this.state.addPayment) {
                if(!this.state.dateOfPayment) {
                    this.setState({ errorMsg: 'Select date of payment'});
                    return;
                }
                if(!this.state.method) {
                    this.setState({ errorMsg: 'Select method of payment'});
                    return;
                }
                if(!this.state.amount) {
                    this.setState({ errorMsg: 'Enter payment amount'});
                    return;
                }

                if(formatBackToNumber(this.state.amount) < 0) {
                    this.setState({ errorMsg: 'Payment amount is invalid' });
                    return;
                }

                if(formatBackToNumber(this.state.amount) > formatBackToNumber(this.state.grandTotal)) {
                    this.setState({ errorMsg: 'Payment amount cannot exceed Grand Total' });
                    return;
                }
            }
        }
        
        // Create order
        let products = [];
        this.state.products.forEach(product => {
            let newProduct = {
                code: product.code,
                packageSize: parseInt(product.packageSize),
                numPackage: parseInt(product.numPackage),
                rate: parseFloat(product.rate),
            }
            products.push(newProduct);
        })
        let newOrder = {
            employeeId: this.state.employeeId,
            customerId: this.state.customer.customerId,
            addressId: this.state.shippingAddressId,
            products,
            orderSummary: {
                totalAmount: formatBackToNumber(this.state.totalAmount),
                shippingHandling: this.state.shippingHandling,
                discount: this.state.discount,
                stateTax: this.state.stateTax,
                centralTax: this.state.centralTax,
                grandTotal: formatBackToNumber(this.state.grandTotal),
            },
            orderDate: this.state.orderDate,
            deliveryDate: this.state.deliveryDate,
            status: this.state.status,
            notes: this.state.notes,
        }
        let payments = [];
        if(this.state.addPayment) {
            let payment = {
                dateOfPayment: this.state.dateOfPayment,
                method: this.state.method,
                amount: this.state.amount,
                notes: this.state.paymentNotes,
            }
            payments = [payment];
            newOrder.payments = payments;
        }
        // Add to state and DB and toggle the modal
        // console.log(newOrder);
        this.props.addOrder(newOrder);
        this.cancelOrder();
    }

    cancelOrder = () => {
        // Set state to initial and toggle the modal
        this.setState(this.initialState);
    }

    render() {
        const customer = this.props.customer;
        // Products and Custom Products
        const { products } = this.props.product;
        let { activeCustomProducts } = this.props.customProduct;
        activeCustomProducts = activeCustomProducts.filter(product => product.customerId === customer.customerId);
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}> 
                    <FontAwesomeIcon icon={faFileAlt} size="2x" color="#0275d8"/> 
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size="xl"
                >
                    <ModalHeader>Add Order</ModalHeader>

                    <ModalBody>
                        <Form >
                            {/***** SELECT SALESPERSON *******/}
                            <Row>
                                <Col md={6}>
                                    <Label>Sales Person ID: </Label>
                                    <Input 
                                        type="text"
                                        autoComplete="off"
                                        list="employees"
                                        name="employeeId"
                                        value={this.state.employeeId}
                                        onChange={this.onChange}
                                    />
                                    <datalist id="employees">
                                        {this.props.employee.activeEmployees.map(employee => (
                                            <option key={employee.employeeId} value={employee.employeeId}>{employee.employeeId} | {employee.fullName} | {employee.jobTitle}</option>
                                        ))}
                                    </datalist>
                                </Col>
                                <Col md={6} >
                                    <Row className="ml-3">
                                        <Label> <strong>{this.state.employeeJobTitle}</strong> </Label>
                                    </Row>
                                    <Row className="ml-3">
                                        <h4> {this.state.employeeName} </h4>
                                    </Row>
                                </Col>
                            </Row>
                            {/********** CONTACT INFO **********/}
                            <legend className="mt-3">Contact Info:</legend>
                            {customer ? (
                                <Row form>
                                    <Col md={12}>
                                        <Card>
                                            <CardBody>
                                                <Row form>
                                                    <Col md={8} >
                                                        <h4> {customer.customerName} </h4> 
                                                    </Col>
                                                    <Col md={4} className="mt-auto mb-auto">
                                                        <h6> {this.state.category} </h6>
                                                    </Col>
                                                </Row>
                                                
                                                <small> {customer.mobileNumber} </small>
                                            </CardBody>
                                            {/****** CONTACT DETAILS ******/}
                                            <CardBody className="pt-0">
                                                <Row style={{whiteSpace: "pre-wrap"}}>
                                                    <Col md={6} style={{paddingRight:"10px", borderRight: "2px solid #ccc"}} >
                                                        <strong>Address: </strong> {"\n"}
                                                        {formatAddress(customer.address)}
                                                    </Col>

                                                    <Col md={6} >
                                                        <strong>Email: </strong> {customer.email}{"\n"}
                                                        <strong>GSTIN: </strong> {customer.gstin}{"\n"}
                                                        <strong>UIN: </strong> {customer.uin}{"\n"}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : null}

                            {/*********** SHIPPING ADDRESS **********/}
                            {this.state.customer ? (
                                <Row className="mt-3" form>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>
                                                <Label>Shipping Address: </Label>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" onClick={this.toggleCheckbox} name="shippingSameAsAbove" checked={this.state.shippingSameAsAbove}/>
                                                        same as above
                                                    </Label>
                                                </FormGroup>    
                                            </CardHeader>
                                            {customer && !this.state.shippingSameAsAbove ? (
                                                <CardBody >
                                                    {this.state.customer.shippingAddress.length === 0 ? "No shipping address" : null}
                                                    <Row >
                                                        {this.state.customer.shippingAddress.map((sAddress, i) => (
                                                            <Col md={4} className="float-left mb-2">
                                                                <Card 
                                                                    style={{whiteSpace: 'pre-wrap'}}
                                                                    className={this.state.shippingAddressId === sAddress.addressId ? "shadow" : ""}
                                                                >
                                                                    <CardHeader>
                                                                        <FormGroup check>
                                                                            <Label check>
                                                                                <Input type="radio" name="radioAddress" value={sAddress.addressId} onChange={this.slecteShippingAdd}/>
                                                                                Shipping address {i+1} 
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <Label> {formatAddress(sAddress)} </Label>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </CardBody>
                                            ) : null}
                                        </Card>
                                    </Col>
                                </Row>
                            ) : null}

                            {/******** PRODUCTS DETAILS *********/}
                            <legend className="mt-3">Products:</legend>
                            <Row >
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md={6} >
                                                    <Form onSubmit={e => e.preventDefault()}>
                                                        <Input type="text" 
                                                            autoComplete="off"
                                                            onChange={this.onChange} 
                                                            name="searchProduct"
                                                            value={this.state.searchProduct} 
                                                            invalid={this.state.productSrarchError ? true : false}
                                                            onKeyUp={this.handleKeyPress}
                                                            list="productList"
                                                            placeholder="Search Products (Code)..."/>
                                                        <datalist id="productList">
                                                            {products.map(product => (
                                                                <div key={product.code}>
                                                                    {product.isActive ? (
                                                                        <option value={product.code}>{product.code} | {product.name} | {product.productType}</option>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                            {activeCustomProducts.map(product => (
                                                                <div>
                                                                    {product.isActive ? (
                                                                        <option value={product.code}>{product.name} | {product.productType}</option>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </datalist>
                                                    </Form>
                                                </Col>
                                                <Col md={6}>
                                                    <Button color="primary" onClick={this.addProductBtn}> Add</Button>
                                                    <Button color="link" onClick={this.clearProductsTable} > clear all </Button>
                                                    <Label className="ml-2"> {this.state.productSrarchError} </Label>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col md={12}>
                                                    <Table hover>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Product</th>
                                                                <th>Code</th>
                                                                <th>Package Size</th>
                                                                <th>Num Package</th>
                                                                <th>Quantity</th> {/***Autofill ***/}
                                                                <th>Rate</th>
                                                                <th>Amount</th> {/***Autofill ***/}
                                                                <th></th> {/** Show delete button**/}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.products.map((prod, i) => (
                                                                <tr>
                                                                    <td>{i+1}</td>
                                                                    <td> {prod.name} ({prod.productType}) </td>
                                                                    <td> {prod.code} </td>
                                                                    <td> <Input type="number" name={i+"-packageSize"} onChange={this.onChangeProduct} value={prod.packageSize} style={{width: "75px"}}></Input> </td>
                                                                    <td><Input type="number" name={i+"-numPackage"} onChange={this.onChangeProduct} value={prod.numPackage} style={{width: "75px"}}/></td>
                                                                    <td> {prod.packageSize * prod.numPackage} </td>
                                                                    <td><Input type="number" name={i+"-rate"} onChange={this.onChangeProduct} value={prod.rate} style={{width: "75px"}}/> </td>
                                                                    <td> {prod.packageSize * prod.numPackage * prod.rate} </td>
                                                                    <td> <button
                                                                            type="button"
                                                                            className="btn-style"
                                                                            onClick={this.removeProductFromList.bind(this, i)}
                                                                        ><FontAwesomeIcon icon={faTrash} /> </button> 
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Col> 
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                
                            </Row>

                            {/******** ORDER DETAILS *********/}
                            <legend className="mt-3">Order Details</legend>
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardBody>
                                            <h5>Order summary</h5>
                                            <Row className="mt-1"> 
                                                <Col md={6}>
                                                    <Label>Total Amount: </Label>
                                                </Col>
                                                <Col md={6}>
                                                    <Label>{this.state.totalAmount}</Label>
                                                </Col>
                                            </Row>

                                            <Row className="mt-1">
                                                <Col md={6}>
                                                    <Label>Shipping and Handling: </Label>
                                                </Col>
                                                <Col md={6}>
                                                    <Input type="number" value={this.state.shippingHandling} name="shippingHandling" onChange={this.onChange} />
                                                </Col>
                                            </Row>

                                            <Row className="mt-1">
                                                <Col md={6}>
                                                    <Label>Discount: </Label>
                                                </Col>
                                                <Col md={6}>
                                                    <Input type="number" value={this.state.discount} name="discount" onChange={this.onChange} />
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col md={6}>
                                                    <h6>Taxable amount: </h6>
                                                </Col>
                                                <Col md={6}>
                                                    <Label> {this.state.taxableAmount} </Label>
                                                </Col>
                                            </Row>

                                            <Row className="mt-1">
                                                <Col md={3}>
                                                    <Label>State tax: %</Label>
                                                </Col>
                                                <Col md={3}>
                                                    <Input type="number" value={this.state.stateTax} name="stateTax" onChange={this.onChange} />
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {this.state.stateTaxAmount} </Label>
                                                </Col>
                                            </Row>

                                            <Row className="mt-1">
                                                <Col md={3}>
                                                    <Label>Central tax: %</Label>
                                                </Col>
                                                <Col md={3} >
                                                    <Input type="number" value={this.state.centralTax} name="centralTax" onChange={this.onChange} />
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {this.state.centralTaxAmount} </Label>
                                                </Col>
                                            </Row>

                                            <Row className="mt-1">
                                                <Col md={6}>
                                                    <h3>Grand Total: </h3>
                                                </Col>
                                                <Col md={6}>
                                                    <h3><FontAwesomeIcon icon={faRupeeSign}/> {this.state.grandTotal} </h3>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6} >
                                    <Card>
                                        <CardBody>
                                            <Row className="mt-2">
                                                <Col md={4}>
                                                    <Label>Order Date: </Label>
                                                </Col>
                                                <Col md={8} >
                                                    <Input type="date" value={this.state.orderDate} name="orderDate" onChange={this.onChange} />
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col md={4}>
                                                    <Label>Expected Delivery Date: </Label>
                                                </Col>
                                                <Col md={8} >
                                                    <Input type="date" value={this.state.deliveryDate} name="deliveryDate" onChange={this.onChange} min={this.state.orderDate} />
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col md={4} >
                                                    <Label>Notes: </Label>
                                                </Col>
                                                <Col md={8} >
                                                    <Input type="textarea" value={this.state.notes} name="notes" onChange={this.onChange} placeholder="add notes..." />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    {/******* ADD PAYMENT (ADVANCE)*******/}
                                    <Card className="mt-2">
                                        <CardHeader>
                                            <lengend>Payment:</lengend>
                                            <button className="btn-style float-right" 
                                                type="button"
                                                onClick={this.toggleCollapse}> 
                                                <FontAwesomeIcon icon={faMinusSquare} /></button>
                                        </CardHeader>
                                    </Card>
                                    <Collapse isOpen={this.state.addPayment}>
                                        <Card>
                                            <CardBody>
                                                <Row className="mb-2" >
                                                    <Col md={4} >
                                                        <Label>Date of Payment: </Label>
                                                    </Col>
                                                    <Col md={8} >
                                                        <Input 
                                                            type="date" 
                                                            name="dateOfPayment"
                                                            value={this.state.dateOfPayment}
                                                            onChange={this.onChange}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2" >
                                                    <Col md={4} >
                                                        <Label>Method: </Label>
                                                    </Col>
                                                    <Col md={8} >
                                                        <Input 
                                                            type="select" 
                                                            name="method"
                                                            value={this.state.method}
                                                            onChange={this.onChange}
                                                        >
                                                            <option disabled selected> Select method of paymnet</option>
                                                            <option>Cash</option>
                                                            <option>Debit Card</option>
                                                            <option>Credit Card</option>
                                                            <option>Others</option>
                                                        </Input>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2" >
                                                    <Col md={4} >
                                                        <Label>Amount: </Label>
                                                    </Col>
                                                    <Col md={5} >
                                                        <Input 
                                                            type="number" 
                                                            disabled={this.state.fullPayemnt}
                                                            name="amount"
                                                            value={this.state.amount}
                                                            onChange={this.onChange}
                                                            placeholder="Enter Amount"
                                                        />
                                                    </Col>
                                                    <Col md={3} >
                                                        <Input 
                                                            type="checkbox"
                                                            name="fullPayment"
                                                            checked={this.state.fullPayemnt}
                                                            onChange={this.onChangeCheckBox}
                                                            placeholder="Notes For Payment"
                                                        />
                                                        <Label >Full payment</Label>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2" >
                                                    <Col md={4} >
                                                        <Label>Notes: </Label>
                                                    </Col>
                                                    <Col md={8} >
                                                        <Input 
                                                            type="textarea" 
                                                            name="paymentNotes"
                                                            value={this.state.paymentNotes}
                                                            onChange={this.onChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </Col>
                            </Row>

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.errorMsg ? (
                            <h5 color="danger" className="mr-auto ml-2">
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {this.state.errorMsg} 
                            </h5>
                        ) : (null)}
                        <Button color="primary" className="" onClick={this.addOrder}>Add Order</Button>
                        <Button color="secondary" className="ml-2" onClick={this.cancelOrder} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    product: state.product,
    employee: state.employee,
    customProduct: state.customProduct,
});

export default connect( mapStateToProps, { addOrder, getEmployees } )(NewOrderModal);