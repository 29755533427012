import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  LOADING_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_STOCK,
  INVENTORY_COUNT,
  ARCHIVE_PRODUCT,
  UNARCHIVE_PRODUCT,
  DAMAGED_PRODUCTS,
} from "./types";
import axios from 'axios';
import { tokenConfig } from './AuthActions';
import { addLog } from './LogActions';

export const getProducts = () => (dispatch, getState) => {
    dispatch(loadingProducts());
    axios
      .get('/api/products', tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data
        })
      })
}

const updateProductWithType = (updatedProduct, type, dispatch, getState) => {
  const id = updatedProduct._id;
  axios
    .put(`/api/products/${id}`, updatedProduct, tokenConfig(getState))
    .then(res => {
      dispatch({
        type,
        payload: res.data
      })

      // Log
      const log = {
        type,
        userId: getState().auth.user._id,
        userName: getState().auth.user.name,
        reqBody: updatedProduct,
      }
      addLog(log, dispatch);
    })
    .catch(err => console.log(`axios err ${err}`))
}

export const updateProduct = updatedProduct => (dispatch, getState) => {
  updateProductWithType(updatedProduct, UPDATE_PRODUCT, dispatch, getState);
}

export const addStock = updatedProduct => (dispatch, getState) => {
  updateProductWithType(updatedProduct, ADD_STOCK, dispatch, getState);
}

export const inventoryCount = updatedProduct => (dispatch, getState) => {
  updateProductWithType(updatedProduct, INVENTORY_COUNT, dispatch, getState);
}

export const archiveProduct = updatedProduct => (dispatch, getState) => {
  updateProductWithType(updatedProduct, ARCHIVE_PRODUCT, dispatch, getState);
}

export const unarchiveProduct = updatedProduct => (dispatch, getState) => {
  updateProductWithType(updatedProduct, UNARCHIVE_PRODUCT, dispatch, getState);
}

export const damagedProducts = updatedProduct => (dispatch, getState) => {
  updateProductWithType(updatedProduct, DAMAGED_PRODUCTS, dispatch, getState);
}

export const deleteProduct = id => (dispatch, getState) => {
  axios.delete(`api/products/${id}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DELETE_PRODUCT,
        payload: id
      })

      // Log
      const log = {
        type: DELETE_PRODUCT,
        userId: getState().auth.user._id,
        userName: getState().auth.user.name,
        reqBody: id,
      }
      addLog(log, dispatch);
    })
    .catch(err => console.log(`axios delete error: ${err}`));
}

export const addProduct = newProduct => (dispatch, getState) => {
  axios.post('/api/products/', newProduct, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ADD_PRODUCT,
        payload: res.data
      })

      // Log
      const log = {
        type: ADD_PRODUCT,
        userId: getState().auth.user._id,
        userName: getState().auth.user.name,
        reqBody: newProduct,
      }
    })
    .catch(err => console.log(`axios error: ${err}`));
}

export const loadingProducts = () => {
  return {
    type: LOADING_PRODUCTS,
  };
}