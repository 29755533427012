import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Label, Spinner, Row, Col, Button, Table, Card, CardBody, Input } from 'reactstrap';
import { getOrders } from '../../actions/OrderActions';
import { getCustomers } from '../../actions/CustomerActions';
import { getProducts } from '../../actions/ProductActions';
import { getCustomProducts } from '../../actions/CustomProductActions';
import { formatAddress, formatAmount, formatDate } from '../constVarAndFunc/formats';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { AWAITING_PICKUP, SHIPPED } from '../constVarAndFunc/StatusOptions';

class PrintChallan extends React.Component {
    state = {
        order: undefined,
        customer: undefined,
        productsName: undefined,
        // Set challan (challanDate = undefined) to not original when status !== shipped
        challanDate: undefined,

        deliveryAddress: undefined,
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
        getOrders: PropTypes.func.isRequired,
        getProducts: PropTypes.func.isRequired,
        getCustomers: PropTypes.func.isRequired,
        getCustomProducts: PropTypes.func.isRequired,
    }

    componentDidMount = () => {
        // Flow of the code is quite tricky
        // 1. Get selected order from url hash and set as state
        // 2. Get Customer, Product data from db (and set to props) after setting order state
        // 3. Set Customer to state in componentDidUpdate (and delivery address)
        // 4. When products are set to props get customProduct from db (and set to props)
        // 5. Set products and customProducts to state (order products)
        this.props.getOrders();

        // Prevent Inspec element.
        // prevents right clicking
        let printDoc = document.getElementById('printDocument');
        printDoc.addEventListener('contextmenu', e => e.preventDefault());

        document.onkeydown = function(e) {
            if(e.keyCode == 123) {
                console.log('You cannot inspect Element');
                return false;
            }
            if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                console.log('You cannot inspect Element');
                return false;
            }
            if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                console.log('You cannot inspect Element');
                return false;
            }
            if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                console.log('You cannot inspect Element');
                return false;
            }
            if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                console.log('You cannot inspect Element');
                return false;
            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.order !== this.props.order) {
            this.getOrderDetails();
        }

        if(prevProps.customer !== this.props.customer) {
            this.getCustomerDetails();
        }

        if(prevProps.product !== this.props.product) {
            this.props.getCustomProducts();
        }

        if(prevProps.customProduct !== this.props.customProduct) {
            this.getProductDetails();
        }
    }

    getOrderDetails = () => {
        let orderId = window.location.hash.replace('#', '');
        const order = this.props.order.orders.find(order => order._id === orderId);
        if(order) {
            // Set Challan date only when status === shipped
            // Set Challan valid only when status === shipped
            let challanDate = undefined;
            if(order.status === SHIPPED || order.status === AWAITING_PICKUP ) challanDate = moment().format('DD MMM YYYY');
            this.setState({
                order,
                challanDate,
            }, () => {
                this.props.getCustomers();
                this.props.getProducts();
            })
        }
    }

    getCustomerDetails = () => {
        const customer = this.props.customer.customers.find(customer => customer.customerId === this.state.order.customerId);
        if(customer) {
            let deliveryAddress;
            if(this.state.order.addressId === customer.address.addressId) {
                deliveryAddress = customer.address;
            } else {
                customer.shippingAddress.forEach(address => {
                    if(this.state.order.addressId === address.addressId) {
                        deliveryAddress = address
                    }
                })
            }
            this.setState({
                customer,
                deliveryAddress,
            })
        }
    }

    getProductDetails = () => {
        let productsName = new Map();
        this.props.product.products.forEach(p => {
            productsName.set(p.code, `${p.productType} - ${p.name}`)
        })
        this.props.customProduct.customProducts.forEach(p => {
            if(p.customerId === this.state.order.customerId) {
                productsName.set(p.code, `${p.productType} - ${p.name}`)
            }
        })
        this.setState({
            productsName,
        })
    }

    render() {
        const { order, customer } = this.state;
        
        // Set Document Title
        if(order) {
            if(order.challanNumber) {
                document.title = 'Challan_' + order.challanNumber;
            }
        }
        return (
            <div id="printDocument" >
                <div className="container" style={{whiteSpace: 'pre-wrap', height: '9in'}}>
                    {this.state.order && this.state.productsName && this.state.customer ? (
                        <div style={{height: '100%'}}>
                            <center>
                                <h4>Delivery Challan</h4>
                                <h1>Parul Enterprise</h1>
                                <Label>
                                    <u>
                                        Manufacturers of: Ex. Notebook, Answer Sheets, File, Calender, Diary and Other stationaries
                                    </u>  
                                </Label> {"\n"}
                                <Label>
                                    C/o Dwarkesh Farm, B/H Sainath Complex, Oppo. Tarsali Talav, Vadodara. 390009
                                </Label> {"\n"}
                                <Label>
                                    Factory: 0265-2493373, Mob: 96380 58369 | 9033038629 Email: info@parulenterprise.com
                                </Label> {"\n"}
                                <Label>
                                    Website: <a href="http://www.parulenterprise.com" >www.parulenterprise.com</a>
                                </Label> {"\n"}
                            </center>
                            <Row> 
                                {/* **** Buyer Info ***** */}
                                <Col md={8}>
                                    <Card style={{height: '100%'}}>
                                        <CardBody >
                                            <Row>
                                                <Col md={2}>
                                                    Name: 
                                                </Col>
                                                <Col md={10} >
                                                    {customer.organizationName ? (
                                                        <u> {customer.organizationName} </u>
                                                    ) : (
                                                        <u> {customer.customerName} </u> 
                                                    )} {"\n"}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    Address: 
                                                </Col>
                                                <Col md={10} >
                                                    <u> {formatAddress(this.state.deliveryAddress, true)} </u> {"\n"}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    GSTIN: 
                                                </Col>
                                                <Col md={10} >
                                                    <u> {customer.igst} </u> {"\n"}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                {/* **** Challan Info **** */}
                                <Col md={4}style={{height: '100%'}} >
                                    <Label> GSTIN: 24AA123456789778 </Label>
                                    <Card >
                                        <CardBody >
                                            <Row>
                                                <Col md={5}>
                                                    <Label>Challan No.: </Label>
                                                </Col>
                                                <Col md={7}>
                                                    {/* <Input type='number' />     */}
                                                    <Label> {order.challanNumber} </Label>
                                                </Col>                                            
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={5} >
                                                    <Label>Date: </Label>
                                                </Col>
                                                <Col md={7}>
                                                    {/* <Input type="date" value={this.state.challanDate} /> */}
                                                    {order.challanDate ? (
                                                        <Label> {moment(order.challanDate).format('DD MMM YYYY')} </Label>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* *** Buyer Info **** */}
                            <Row className="mt-2" >
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <Label className="float-left" >Contact Person: <strong>{customer.customerName}</strong> </Label>
                                            <Input className="float-right" type="select" style={{width: '10rem'}}>
                                                <option selected> {customer.mobileNumber} </option>
                                                <option> {customer.homeNumber} </option>
                                            </Input>
                                            <Label className="float-right mr-2" >Mob: </Label>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={!2} >
                                    <Card>
                                        <CardBody>
                                            <h4>Grand Total: <FontAwesomeIcon className="mr-2" icon={faRupeeSign} /> {formatAmount(order.orderSummary.grandTotal)}/- </h4>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* ** Products info TABLE *** */}
                            <Row className="h-75">
                                <Col md={12}>
                                    <Card className="h-100 mb-3">
                                        <CardBody >
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Particulars</th>
                                                        <th>No. PKG</th>
                                                        <th>No. PKT</th>
                                                        <th>Qty</th>
                                                        <th>Rate</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {order.products.map((product, i) => (
                                                        <tr>
                                                            <td> {i+1} </td>
                                                            <td> {this.state.productsName.get(product.code)} </td>
                                                            <td> {product.packageSize} </td>
                                                            <td> {product.numPackage} </td>
                                                            <td> {product.numPackage * product.packageSize} </td>
                                                            <td> {product.rate} </td>
                                                            <td> {formatAmount(product.rate * product.numPackage * product.packageSize)}/- </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {/* *** Order Summary **** */}
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardBody>
                                            <h5>Order Summary</h5>
                                            <Row >
                                                <Col md={6} >
                                                    <Label><strong>Total Amount: </strong></Label>
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {formatAmount(order.orderSummary.totalAmount)}/- </Label>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6} >
                                                    <Label><strong>Shipping and Handling: </strong></Label>
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {formatAmount(order.orderSummary.shippingHandling)} </Label>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6} >
                                                    <Label><strong>Discount: </strong></Label>
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {formatAmount(order.orderSummary.discount)} </Label>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6} >
                                                    <Label><strong>State tax: </strong> {order.orderSummary.stateTax} %</Label>
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {formatAmount(order.orderSummary.totalAmount*(order.orderSummary.stateTax / 100))} </Label>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6} >
                                                    <Label><strong>Central Tax: </strong> {order.orderSummary.centralTax} %</Label>
                                                </Col>
                                                <Col md={6} >
                                                    <Label> {formatAmount(order.orderSummary.totalAmount*(order.orderSummary.centralTax / 100))} </Label>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6} >
                                                    <Label><strong>Grand Total: </strong></Label>
                                                </Col>
                                                <Col md={6} >
                                                    <Label> 
                                                        <strong> 
                                                            <FontAwesomeIcon className="mr-2" icon={faRupeeSign} />
                                                            {formatAmount(order.orderSummary.grandTotal)}/-
                                                        </strong> 
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6} >
                                    <Card style={{height: "100%"}}>
                                        <CardBody>
                                            <Row className="mb-2" style={{height: "75%"}}>
                                                <Col md={2} >
                                                    <Label>Notes: </Label>
                                                </Col> 
                                                <Col md={10} >
                                                    <Input type="textarea" rows="5"/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2" style={{height: "25%"}}>
                                                <Col md={12} >
                                                    <Label className="float-left mt-auto">Reciever Sign & Stamp</Label>
                                                    <Label className="float-right mt-auto"><h4>Parul Enterprise</h4></Label>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        ) : (
                            <center style={{position: 'absolute', bottom: '50%', right: '50%'}}>
                                <Spinner size="2x" color="primary" />
                            </center>
                        )}

                        {/* WATERMARK FOR NOT ORIGINAL */}
                        {!this.state.challanDate ? (
                            <div style={{position: 'fixed', top: '60%', left: '50%', transform: 'translate(-50%, -50%)', opacity: '0.3'}}>
                                <h1 style={{ fontSize: '48px' }}> NOT ORIGINAL COPY</h1>
                            </div>
                        ) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    order: state.order,
    product: state.product,
    customer: state.customer,
    customProduct: state.customProduct,
})

export default connect(mapStateToProps, { getOrders, getProducts, getCustomers, getCustomProducts })(PrintChallan);