import React from 'react';
import { Label, Button, Card, Row, Col, CardBody, Alert, Input } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';

class ForgotPassword extends React.Component {
    state = {
        email: '',
        alertMsg: '',
        alertMsgColor: '',
        sendingEmail: false,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    goHome = () => {
        window.location.pathname = "/"
    }

    resetPassword = () => {
        this.setState({
            alertMsg: '',
            alertMsgColor: '',
            sendingEmail: true,
        })
        axios.post(`/api/forgot/`, {email: this.state.email})
            .then(res => {
                if(res.status === 200) {
                    this.setState({
                        alertMsg: res.data.msg,
                        alertMsgColor: 'success',
                    })
                }
            })
            .catch(err => {
                if(err.response) {
                    this.setState({
                        alertMsgColor: 'danger',
                        alertMsg: err.response.data.msg
                    })
                }
            })
            .finally(() => {
                this.setState({
                    sendingEmail: false,
                })
            })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.resetPassword();
        }
    }

    render() {
        return (
            <div style={{background: "#F5F5DC"}}>
                <div className="login-page-image">
                    <div className="login-card shadow-lg">
                        <Card >
                            <CardBody >
                                <Button color="primary" className="float-left" onClick={this.goHome}>
                                    <FontAwesomeIcon size="1x" icon={faHome } />
                                </Button>
                                <h4 className="d-flex justify-content-center">Forgot password?</h4>
                                {this.state.alertMsg ? (
                                    <Alert color={this.state.alertMsgColor}>
                                        {this.state.alertMsg}
                                    </Alert>
                                ) : null}
                                <hr />
                                <Label>Don't worry, we will send you a link to reset your password. Please enter your email below</Label>
                                <Row className="p-3">
                                    <Label>Email</Label>
                                    <Input 
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        placeholder="Enter email"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3" >
                                    <Button block color="primary" disabled={this.state.sendingEmail} onClick={this.resetPassword} >Send Link</Button>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;