import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Row, Col} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteReport } from '../../../actions/ReportActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class DeleteReport extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
    }

    static propTypes = {
        deleteReport: PropTypes.func.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    deleteReport = () => {
        const { report } = this.props;

        this.props.deleteReport(report._id);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        const { report } = this.props;
        return (
            <div>
                <button className="btn-style" onClick={this.toggle} >
                    <FontAwesomeIcon icon={faTrash} />
                </button>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader>
                        Delete Report
                    </ModalHeader>

                    <ModalBody style={{whiteSpace: 'pre-wrap'}}>
                        <h4>
                            Are you sure you want to delete this report? {"\n"}
                        </h4>
                        <Row>
                            <Col md={4} >
                                Report ID:
                            </Col>
                            <Col md={8} >
                                {report._id}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={4} >
                                Report Type: 
                            </Col>
                            <Col md={8} >
                                {report.reportType}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={4} >
                                Reported by: 
                            </Col>
                            <Col md={8} >
                                {report.user.name}
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteReport} >Delete Report</Button>
                        <Button color="secondary" onClick={this.cancel} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { deleteReport })(DeleteReport);