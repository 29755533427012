import React from 'react';
import { Button, Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { unarchiveProduct } from '../../../actions/ProductActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

class UndoDeleteProduct extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false
    }

    static propTypes = {
        unarchiveProduct: PropTypes.func.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    undoDelete = () => {
        let product = {
            _id: this.props.product._id,
            isActive: true,
        }
        this.props.unarchiveProduct(product);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        let { product } = this.props;
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faUndo} />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Undo Product Delete
                    </ModalHeader>

                    <ModalBody>
                        <Label><strong>Unarchive Product: </strong></Label>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={4} >
                                        <Label><strong>Code: </strong></Label>
                                    </Col>
                                    <Col md={8} >
                                        <Label> {product.code} </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} >
                                        <Label><strong>Product Type: </strong></Label>
                                    </Col>
                                    <Col md={8} >
                                        <Label> {product.productType} </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} >
                                        <Label><strong>Prodcut Name: </strong></Label>
                                    </Col>
                                    <Col md={8} >
                                        <Label> {product.name} </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={this.undoDelete} > Undo </Button>
                        <Button color="secondary" onClick={this.cancel} > Cancel </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { unarchiveProduct })(UndoDeleteProduct);