import React from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { archiveCustomProduct } from '../../../../actions/CustomProductActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faTrash } from '@fortawesome/free-solid-svg-icons';

class DeleteCustomProduct extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
    }

    static propTypes = {
        archiveCustomProduct: PropTypes.func.isRequired,
    }
    
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    applyChanges = () => {
        let product = {
            _id: this.props.product._id,
            isActive: false,
        }
        this.props.archiveCustomProduct(product);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        let { product } = this.props;
        return (
            <div>
                <button className="hover-btn" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faArchive} />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Delete Product (Archive)
                    </ModalHeader>
                    <ModalBody style={{whiteSpace: "pre-wrap"}}>
                        <Label>Are you sure you want to archive this product? </Label>{"\n"}
                        <Label><strong>Code: </strong> {product.code} </Label> {"\n"}
                        <Label><strong>Type: </strong> {product.productType} </Label> {"\n"}
                        <Label><strong>Name: </strong> {product.name} </Label> {"\n"}
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" onClick={this.applyChanges} >Delete</Button>
                        <Button color="secondary" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { archiveCustomProduct })(DeleteCustomProduct);