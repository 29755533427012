import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes  from 'prop-types';
import { getEmployees } from '../../../actions/EmployeeActions';
import { FormControl, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import AddEmployee from './AddEmployee';
import EmployeeProfile from  './EmployeeProfile';
import { formatAddress } from '../../constVarAndFunc/formats';

class Employees extends React.Component {
    state ={
        search: '',
    }

    static propTypes = {
        getEmployees: PropTypes.func.isRequired,
        employee: PropTypes.object.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    componentDidMount = () => {
        this.props.getEmployees();
    }

    matchSearch = (string, stateProp) => {
        if(!stateProp) {
            stateProp = this.state.search;
        }
        if(string && stateProp){
            if((String(string).toUpperCase()).includes((stateProp).toUpperCase())) {        
                return true;
            };
        }
        return false;
    }

    searchEmployee = () => {
        let { employees } = this.props.employee;
        let results = [...employees];
        if(this.state.search) {
            results = results.filter(employee => {
                if(employee.jobTitle) {
                    if(this.matchSearch(employee.jobTitle)) {
                        return employee;
                    }
                }
                if(employee.employeeId) {
                    if(this.matchSearch(employee.employeeId)) {
                        return employee;
                    }
                }
                if(employee.fullname) {
                    if(this.matchSearch(employee.fullname)) {
                        return employee;
                    }
                }
                if(employee.email) {
                    if(this.matchSearch(employee.email)) {
                        return employee;
                    }
                }
                if(employee.contact) {
                    if(this.matchSearch(employee.contact)) {
                        return employee;
                    }
                }
                if(employee.secondaryContact) {
                    if(this.matchSearch(employee.secondaryContact)) {
                        return employee;
                    }
                }
                if(employee.jobStatus) {
                    if(this.matchSearch(employee.jobStatus)) {
                        return employee;
                    }
                }
                if(employee.startDate) {
                    if(this.matchSearch(employee.startDate)) {
                        return employee;
                    }
                }
                if(employee.address) {
                    if(employee.address.line1) {
                        if(this.matchSearch(employee.address.line1)) {
                            return employee;
                        }
                    }
                    if(employee.address.street) {
                        if(this.matchSearch(employee.address.street)) {
                            return employee;
                        }
                    }
                    if(employee.address.landmark) {
                        if(this.matchSearch(employee.address.landmark)) {
                            return employee;
                        }
                    }
                    if(employee.address.city) {
                        if(this.matchSearch(employee.address.city)) {
                            return employee;
                        }
                    }
                    if(employee.address.state) {
                        if(this.matchSearch(employee.address.state)) {
                            return employee;
                        }
                    }
                    if(employee.address.country) {
                        if(this.matchSearch(employee.address.country)) {
                            return employee;
                        }
                    }
                }
            });
        }

        return results;
    }

    clearSearch = () => {
        this.setState({
            search: '',
        })
    }

    render() {
        let { employees } = this.props.employee;
        if(this.state.search) {
            employees = this.searchEmployee();
        }

        return (
            <div>
                {/******* ADD NEW EMPLOYEE ************/}
                <div className="fab-container">
                    <button className="btn-style fab fab-icon-holder">
                        <FontAwesomeIcon icon={faPlus} size="2x" color="blue" />
                    </button> 

                    <ul className="fab-options">
                        <li>
                            <Label className="fab-label">Add New Employee</Label>
                            <AddEmployee />
                        </li>
                    </ul>

                </div>


                {/******* NAVBAR ************/}
                <Navbar className="sticky-top" bg="success" style={{ height: 40 }}>
                    <Form inline className="ml-auto" onSubmit={e => { e.preventDefault(); }}>
                        <FormControl size="sm" type="text" placeholder="Search" className="mr-sm-2" name="search" value={this.state.search} onChange={this.onChange} />
                        <Button size="sm" color="outline-primary" onClick={this.clearSearch} > <FontAwesomeIcon icon={faTimes}/> </Button>
                    </Form>
                </Navbar>

                {/******* EMPLOYEES ********/}
                <div>
                    {employees.map(employee => (
                        <Card key={employee.employeeId} className="text-left m-3 d-inline-flex shadow rounded" 
                                style={{ width: '18rem', whiteSpace: "pre-line" }}>
                            <CardHeader>
                                <strong>{employee.jobTitle}</strong>
                            </CardHeader>
                            <CardBody>
                                <small>ID: {employee.employeeId}</small> {"\n"}
                                <Label className="mb-0"><h5><strong>{employee.fullName}</strong></h5> </Label> {"\n"}
                                <small className="m-0">{employee.email} </small>
                                <hr className="mt-2 mb-4"/>
                                <Label>
                                    <strong>Address:</strong> {formatAddress(employee.address)} 
                                </Label> {"\n"}
                                <Label>
                                    <strong>Contact:</strong> {employee.contact}
                                </Label>
                            </CardBody>
                            <CardFooter>
                                <EmployeeProfile employee={employee}/>
                            </CardFooter>
                        </Card>
                    ))}  
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    employee: state.employee,
})

export default connect(mapStateToProps, { getEmployees })(Employees);