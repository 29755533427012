import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOrderDod } from '../../../../../actions/OrderActions';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../../../constVarAndFunc/formats';

class UpdateDeliveryDate extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
    }

    static propTypes = {
        updateOrderDod: PropTypes.func.isRequired,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            deliveryDate: moment.utc(this.props.order.deliveryDate).format('YYYY-MM-DD'),
        })
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    applyChanges = () => {
        let order = {
            _id: this.props.order._id,
            deliveryDate: this.state.deliveryDate,
        }
        this.props.updateOrderDod(order);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button className="btn-style" onClick={this.toggle} >
                    <FontAwesomeIcon icon={faEdit} size="xs" color="green" />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Delivery Date
                    </ModalHeader>
                    <ModalBody>
                        <Row className="m-2">
                            <Col md={4}>
                                <Label>Delivery Date:</Label>
                            </Col>
                            <Col md={8} >
                                <Input
                                    type="date"
                                    name="deliveryDate"
                                    value={this.state.deliveryDate}
                                    onChange={this.onChange}
                                >
                                    
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={!this.state.deliveryDate} color="primary" onClick={this.applyChanges}>Apply</Button>
                        <Button color="secondary" onClick={this.cancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null , { updateOrderDod } )(UpdateDeliveryDate);