import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faKey, faSignOutAlt, faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { Button, Card, CardBody, Label, Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../actions/AuthActions'
import ChangePassword from './ChangePassword';
import Clineties from '../assets/images/clienties_white1.png';

class AppNavbar extends Component {
  state = {
    logoutModal: false,
    profileModal: false,
  }
  
  static propTypes = {
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  }

  toggleLogout = () => {
    this.setState({
      logoutModal: !this.state.logoutModal,
    })
  }

  toggleProfile = () => {
    this.setState({
      profileModal: !this.state.profileModal,
    })
  }

  logoutUser = () => {
    this.props.logout();
  }

    render() {
      const { name, email } = this.props.user;
        return (
           <Navbar bg="primary" variant="dark" style={{ height: 40 }} className="sticky-top">
             <Navbar.Brand className="mr-auto" href="/dashboard/">
               {/* ClienTies */}
                <img src={Clineties} alt="Logo" style={{ padding: 5, height: 40 }} />
             </Navbar.Brand>
             
             <Label style={{ color: "white", fontWeight: "500", marginTop: "auto"}}>Welcome {name}!</Label>
             <button className="btn-style" onClick={this.toggleProfile} >
               <FontAwesomeIcon size="2x" color="blue" icon={faUserCircle} />
             </button>
             <button className="btn-style" onClick={this.toggleLogout}>
              <FontAwesomeIcon size="2x" color="white" icon={faSignOutAlt} />
             </button>

             <Modal isOpen={this.state.logoutModal} toggle={this.toggleLogout} centered={true} size="sm">
               <ModalBody>
                 <Button block color="danger" onClick={this.logoutUser}>
                  <h3>
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" />
                    Logout
                  </h3>
                 </Button>
               </ModalBody>
             </Modal>

             <Modal isOpen={this.state.profileModal} toggle={this.toggleProfile} centered={true} size="sm" >
               <ModalBody>
                 <Card>
                   <CardBody>
                      <FontAwesomeIcon size="6x" color="#2F4F4F" icon={faUserCircle} style={
                        {position: 'absolute', top: '-25%', left: 'calc(50% - 50px)', background: "#FFF", borderRadius: '50%'}
                      } />
                      <div style={{marginBottom: "30px"}} />
                      <center><h4>{name}</h4></center>
                      <center><Label>{email}</Label></center>

                      <ChangePassword email={email}/>
                      <Button block className="mt-2" color="danger" size="sm" onClick={this.logoutUser}>
                          <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" />
                          Logout
                      </Button>
                   </CardBody>
                 </Card>
               </ModalBody>
             </Modal>
           </Navbar>
        );
    }
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps , { logout } )(AppNavbar);