import React from 'react';

const NotFound = () => (
    <div>
        <center className="mt-4">
            <h1>
                Page Not Found!
            </h1>
            <h2>
                Error 404
            </h2>
            <h4>
                Please check the url. The requested url was not found on this server.
            </h4>
        </center>
    </div>
)

export default NotFound;