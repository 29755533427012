import React from 'react';
import { Label, Nav, NavItem, NavLink, TabContent, TabPane, Tooltip, Card, Button, CardTitle, CardBody, Row, Col, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import OrderDetails from '../../CustomersTab/Orders/OrderDetails';
import { PARTIAL, PENDING } from '../../../constVarAndFunc/StatusOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class Pending extends React.Component {
    state = {
        tab: 'Current',
        tooltipNext: false,
        tooltipThis: false,
        tooltipFuture: false,
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
    }

    setTab = tab => {
        this.setState({
            tab,
        })
    }

    toggleTooltip = e => {
        console.log(e.target.value);
        this.setState({
            [e.target.id]: !this.state[e.target.id],
        })
    }

    render() {
        let { orders } = this.props.order;

        let dates = {
            thisWeekStart: moment().startOf('week').format('DD MMM YYYY'),
            thisWeekEnd: moment().endOf('week').format('DD MMM YYYY'),
            nextWeekStart: moment.utc().add(1, 'weeks').startOf('week').format('DD MMM YYYY'),
            nextWeekEnd: moment.utc().add(1, 'weeks').endOf('week').format('DD MMM YYYY'),
            futureStart: moment.utc().add(2, 'weeks').startOf('week').format('DD MMM YYYY'),
        }

        let previousOrders = orders.filter(order => {
            if(order.status === PENDING || order.status === PARTIAL) {
                return moment.utc(order.deliveryDate).isBefore(moment().subtract(1, 'days'));
            }
        })

        let thisWeekOrders = orders.filter(order => {
            if(order.status === PENDING || order.status === PARTIAL) {
                return moment.utc(order.deliveryDate).isSame(moment(), 'week');
            }
        })

        let nextWeekOrders = orders.filter(order => {
            if(order.status === PENDING || order.status === PARTIAL) {
                return moment.utc(order.deliveryDate).isSame(moment().add(1, 'weeks'), 'week');
            }
        })

        let futureOrders = orders.filter(order => {
            if(order.status === PENDING || order.status === PARTIAL) {
                if(!moment.utc(order.deliveryDate).isSame(moment(), 'week') && 
                    !moment.utc(order.deliveryDate).isSame(moment().add(1, 'weeks'), 'week')) {
                        // Not this week or next week and check if only future
                        return moment.utc(order.deliveryDate).isAfter(moment());                        
                    }
            }
        })

        let unspecifiedOrders = orders.filter(order => {
            if(order.status === PENDING || order.status === PARTIAL) {
                return !order.deliveryDate;
            }
        })

        return (
            <div className="mt-2">
                <Nav tabs style={{ background: '#F0F8FF' }}>
                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Current' ? 'active' : null}
                            onClick={() => {this.setTab('Current')}}
                        >
                            <h5>
                                Current Deliveries
                            </h5>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Future' ? 'active' : null}
                            onClick={() => {this.setTab('Future')}}
                        >
                            <h5>
                                Future Deliveries
                            </h5>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={this.state.tab === 'Unspecified' ? 'active' : null}
                            onClick={() => {this.setTab('Unspecified')}}
                        >
                            <h5>
                                Not specified
                            </h5>
                        </NavLink>
                    </NavItem>                    
                </Nav>

                <TabContent activeTab={this.state.tab}>
                    <TabPane tabId="Current">
                        <h4 className="mt-3">Prvious orders</h4>
                        {previousOrders.length === 0 ? (
                            <h3> <FontAwesomeIcon color="green" icon={faCheck} /> All order are upto date.</h3>
                        ) : (
                            <h3> <FontAwesomeIcon color="red" icon={faExclamationCircle} /> These orders have missed the expected delivery date. </h3>
                        )}
                        <OrderDetails showCustomerName={true} orders={previousOrders} />
                        <Row>
                            <h4 className="mt-3 ml-3">This week</h4>
                            <button className="btn-style ml-2" id={'tooltipThis'} >
                                <FontAwesomeIcon color="blue" icon={faInfoCircle} />
                            </button>
                            <Tooltip 
                                target={'tooltipThis'}
                                isOpen={this.state.tooltipThis}
                                toggle={this.toggleTooltip}
                                placement="right"
                                style={{whiteSpace: 'pre-wrap'}}
                            >
                                Delivery dates {"\n"} FROM - {dates.thisWeekStart} {"\n"} TO - {dates.thisWeekEnd}
                            </Tooltip>
                        </Row>
                        {thisWeekOrders.length === 0 ? (
                            <h3> <FontAwesomeIcon color="green" icon={faCheck} /> All set for this week</h3>
                        ) : null}
                        <OrderDetails showCustomerName={true} orders={thisWeekOrders} />

                        <Row>
                            <h4 className="mt-3 ml-3">Next week</h4>
                            <button className="btn-style ml-2" id={'tooltipNext'} >
                                <FontAwesomeIcon color="blue" icon={faInfoCircle} />
                            </button>
                            <Tooltip 
                                target={'tooltipNext'}
                                isOpen={this.state.tooltipNext}
                                toggle={this.toggleTooltip}
                                placement="right"
                                style={{whiteSpace: 'pre-wrap'}}
                            >
                                Delivery dates {"\n"} FROM - {dates.nextWeekStart} {"\n"} TO - {dates.nextWeekEnd}
                            </Tooltip>
                        </Row>
                        {nextWeekOrders.length === 0 ? (
                            <h3> <FontAwesomeIcon color="green" icon={faCheck} /> All set for this week</h3>
                        ) : null}
                        <OrderDetails showCustomerName={true} orders={nextWeekOrders} />
                    </TabPane>

                    <TabPane tabId="Future">
                        <Row>
                            <h4 className="mt-3">Future Orders</h4>
                            <button className="btn-style ml-2" id={'tooltipFuture'} >
                                <FontAwesomeIcon color="blue" icon={faInfoCircle} />
                            </button>
                            <Tooltip 
                                target={'tooltipFuture'}
                                isOpen={this.state.tooltipFuture}
                                toggle={this.toggleTooltip}
                                placement="right"
                                style={{whiteSpace: 'pre-wrap'}}
                            >
                                Delivery dates - {dates.futureStart} and onwards
                            </Tooltip>
                        </Row>
                        {futureOrders.length === 0 ? (
                            <h3> <FontAwesomeIcon color="green" icon={faCheck} /> All set here!</h3>
                        ) : null}
                        <OrderDetails showCustomerName={true} orders={futureOrders} />
                    </TabPane>

                    <TabPane tabId="Unspecified">
                        <h4 className="mt-3">Orders without delivery date:</h4>
                        {unspecifiedOrders.length === 0 ? (
                            <h3> <FontAwesomeIcon color="green" icon={faCheck} /> All set here!</h3>
                        ) : null}
                        <OrderDetails showCustomerName={true} orders={unspecifiedOrders} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    order: state.order,
})

export default connect(mapStateToProps , {} )(Pending);