import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { inventoryCount } from '../../../actions/ProductActions';
import { addReport, submitReport } from '../../../actions/ReportActions';
import { customProductsInventoryCount } from '../../../actions/CustomProductActions';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown, faCaretSquareUp, faCheck, faDownload, faMinusSquare, faPrint, faSave, faSortDown, faSortUp, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { INVENTORY_COUNT } from '../../../actions/types';
import { ADMINISTRATION_MANAGEMENT } from '../../constVarAndFunc/UserLevel';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

class InventoryCount extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        // modal: this.props.modal,
        modal: false,
        reportName: '',
        product: '',
        invalidProduct: false,
        productsList: [],
        nonZeroProductsList: [],
        // Remove all products from table button tooltip
        tooltip: false,

        alertMsg: '',

        // Proceed to compare current inventory with inverntory count
        // Show save with checkbox when proceed = true
        proceed: false,
    }

    static propTypes = {
        product: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        addReport: PropTypes.func.isRequired,
        submitReport: PropTypes.func.isRequired,
        inventoryCount: PropTypes.func.isRequired,
        customProductsInventoryCount: PropTypes.func.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeTable = e => {
        if(e.target.value < 0) return;
        // Update newStock input
        // name === " newStock"
        let [index, name] = (e.target.name).split("-");
        let productsList = this.state.productsList;
        productsList.forEach((p, i) => {
            if(i.toString() === index) {
                p[name] = e.target.value
            }
        })
        this.setState({
            productsList,
        })
    }

    toggle = () => {
        let productsList = [];
        let nonZeroProductsList = [];
        if(this.props.report) {
            if(this.props.report.payload.products) {
                productsList = this.props.report.payload.products;
            }
            if(this.props.report.payload.resetProducts) {
                nonZeroProductsList = this.props.report.payload.resetProducts;
            }
        }
        this.setState({
            modal: !this.state.modal,
            productsList,
            nonZeroProductsList,
        })
    }

    toggleTooltip = () => {
        this.setState({
            tooltip: !this.state.tooltip,
        })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.addProductToList();
        }
    }

    removeAllNonZeroQuantity = () => {
        this.setState({
            nonZeroProductsList: [],
        })
    }

    removeNonZeroQuantity = index => {
        this.setState({
            nonZeroProductsList: this.state.nonZeroProductsList.filter((p,i) => i !== index),
        })
    }

    addNonZeroQuantitiy = () => {
        // After proceed add all other products to the list
        // Does not (and should not) apply when downloading a submitted report
        const { activeProducts } = this.props.product;
        const { activeCustomProducts } = this.props.customProduct;

        let allProducts = [...activeProducts, ...activeCustomProducts];

        let productsListCode = new Set();
        this.state.productsList.forEach(p => {
            productsListCode.add(p.code);
        })

        let nonZeroProductsList = [];
        allProducts.forEach(p => {
            // add to non zero products list if not included in the productslist
            if(!productsListCode.has(p.code)) {
                p.newStock = 0;
                nonZeroProductsList.push(p);
            }
        })

        return nonZeroProductsList;
    }

    addProductToList = () => {
        if(!this.state.product) return;
        // find product and dont add again
        let product = this.props.product.activeProducts.find(p => p.code.toLowerCase() === this.state.product.toLowerCase());
        // If product not found check for custom products
        if(!product) product = this.props.customProduct.activeCustomProducts.find(p => p.code.toLowerCase() === this.state.product.toLowerCase());
        // No duplicates
        if(product) if(this.state.productsList.find(p => p.code === product.code)) return;

        if(product) {
            this.setState({
                invalidProduct: false,
            })
        } else {
            this.setState({
                invalidProduct: true,
            })
            return;
        }
        product.newStock = 0;
        this.setState({
            productsList: [...this.state.productsList, product],
            product: '',
        })
    }

    removeProductsFromList = index => {
        this.setState({
            productsList: this.state.productsList.filter((p,i) => i !== index),
        })
    }

    clearSearch = () => {
        this.setState({
            product: '',
        })
    }

    saveChanges = () => {
        this.setState({
            alertMsg: 'Saving changes...',
        })

        this.saveReportAsPDF();
        this.state.productsList.forEach(product => {
            product.stock = parseFloat(product.newStock);

            // Check if product is custom or market product
            let isCustomProduct = this.props.customProduct.activeCustomProducts.find(p => p.code === product.code);
            if(isCustomProduct) {
                this.props.customProductsInventoryCount(product);
            } else {
                this.props.inventoryCount(product);
            }
        })

        // reset product to 0 stock
        this.state.nonZeroProductsList.forEach(product => {
            if(product.stock !== 0) {
                product.stock = parseFloat(product.newStock);
    
                // Check if product is custom or market product
                let isCustomProduct = this.props.customProduct.activeCustomProducts.find(p => p.code === product.code);
                if(isCustomProduct) {
                    this.props.customProductsInventoryCount(product);
                } else {
                    this.props.inventoryCount(product);
                }
            }
        })

        // Update report to submitted after updating
        const report = {
            _id: this.props.report._id,
            isSubmitted: true,
            payload: {
                products: this.state.productsList,
                resetProducts: this.state.nonZeroProductsList,
            }
        }
        this.props.submitReport(report);
        setTimeout(() => {
            this.cancelChanges();
        }, 2000)
    }

    proceedChanges = () => {
        // reset products with non zero stock
        let nonZeroProductsList = this.addNonZeroQuantitiy();
        this.setState({
            proceed: true,
            nonZeroProductsList,
        })
    }

    goBack = () => {
        this.setState({
            proceed: false,
        })
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    submitReport = () => {
        // Validation
        if(this.state.productsList.length === 0) {
            this.setState({
                alertMsg: 'Products list empty!',
            })
            return;
        }

        const report = {
            reportName: this.state.reportName,
            reportType: INVENTORY_COUNT,
            user: this.props.user,
            payload: {
                products: this.state.productsList,
            }
        }

        this.props.addReport(report)
        this.cancelChanges();
        
    }

    downloadReport = () => {
        this.setState({
            productsList: this.props.report.payload.products,
            nonZeroProductsList: this.props.report.payload.resetProducts,
        }, () => {
            this.saveReportAsPDF();
        })
    }

    saveReportAsPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text(7, 15, "Inventory count report: " + this.props.report.reportName);
        doc.setFontSize(15);
        doc.text(7, 25, "Date and time: " + moment(this.props.report.reportDate).utcOffset("+05:30").format('DD MMM YYYY (LTS)'));
        doc.setFontSize(15);
        doc.text(7, 33, "Reported by: " + this.props.report.user.name);
        doc.setFontSize(10);
        doc.autoTable({
            startY: 37,
            margin: { horizontal: 7 },
            head: [['#', 'Products', 'Code', 'Pages', 'GSM', 'P.Size', 'Stock', 'Quantity', '']],
            body: this.state.productsList.map((product, i) => (
                [`${i+1}`, `${product.name} | ${product.productType}`, `${product.code}`, `${product.pages}`, `${product.gsm}`, `${product.packageSize}`, `${product.newStock}`, `${product.newStock * product.packageSize}`, `${product.newStock - product.stock}`]
                ))
            });
        doc.setFontSize(20);
        doc.text("Inventory count report  (Reset stock to 0)", 7, doc.autoTable.previous.finalY + 15);
        doc.setFontSize(10);
        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 20,
            margin: { horizontal: 7 },
            head: [['#', 'Products', 'Code', 'Pages', 'GSM', 'P.Size', 'Stock', '']],
            body: this.state.nonZeroProductsList.map((product, i) => (
                [`${i+1}`, `${product.name} | ${product.productType}`, `${product.code}`, `${product.pages}`, `${product.gsm}`, `${product.packageSize}`, `${product.newStock}`, `${product.newStock - product.stock}`]
            ))
        });
        
        doc.text(`Report ID: ${this.props.report._id}`, 7, doc.autoTable.previous.finalY + 25);
        doc.save('inventory_count_' + moment(this.props.report.reportDate).format('YYYY-MM-DD'));
    }
    
    render() {
        let privilege = false;
        if(this.props.user.userLevel === ADMINISTRATION_MANAGEMENT) {
            privilege = true;
        }

        return (
            <div>
                {this.props.download ? (
                    <button className="btn-style" onClick={this.downloadReport}>
                        {/* Only download the report that has been submitted */}
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                ) : (
                    <Button color="success" onClick={this.toggle} >
                        Inventory Count
                    </Button>
                )}

                <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl" backdrop="static"  >
                    <ModalHeader >
                        Inventory Count
                    </ModalHeader>

                    <ModalBody style={{height:"70vh", maxHeight: "70vh", overflowY: "scroll"}}>
                        <Row>
                            <Col md={6}>
                                <Input 
                                    type="text"
                                    autoComplete="off"
                                    list="products"
                                    name="product"
                                    value={this.state.product}
                                    onChange={this.onChange}
                                    onKeyDown={this.handleKeyDown}
                                    invalid={this.state.invalidProduct}
                                    disabled={this.state.proceed}
                                    placeholder="Enter Product Code | Name"
                                />
                                <datalist id="products">
                                    {this.props.product.activeProducts.map(product => (
                                        <option key={product.code} value={product.code} > {product.code} | {product.name} | {product.productType} </option>
                                    ))}
                                    {this.props.customProduct.activeCustomProducts.map(product => (
                                        <option key={product.code} value={product.code} > {product.code} | {product.name} | {product.productType} </option>
                                    ))}
                                </datalist>
                            </Col>

                            <Col md={2}>
                                <Button color="primary" onClick={this.addProductToList}>
                                    Add
                                </Button>
                                <Button color="link" onClick={this.clearSearch}>
                                    Clear
                                </Button>
                            </Col>
                            <Col md={4}>
                                {this.props.report ? (
                                    <Label> {this.props.report.reportName} </Label>
                                ) : (
                                    <Row>
                                        <Col md={5} >
                                            <Label>Report Name: </Label>
                                        </Col>
                                        <Col md={7} >
                                            <Input type="text" name="reportName" value={this.state.reportName} onChange={this.onChange} />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>

                        <Row className="mt-4 p-3" >
                            <Table className="my-table" hover >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Code</th>
                                        <th>Pages</th>
                                        <th>GSM</th>
                                        <th>P.Size</th>
                                        <th>Stock</th>
                                        <th>Quantity</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.productsList.map((product, i) => (
                                        <tr>
                                            <td>{i+1}</td>
                                            <td> {product.name} ({product.productType}) </td>
                                            <td> {product.code} </td>
                                            <td> {product.pages} </td>
                                            <td> {product.gsm} </td>
                                            <td> {product.packageSize} </td>
                                            <td>
                                                <Input 
                                                    style={{width: "6rem"}}
                                                    type="number"
                                                    name={`${i}-newStock`}
                                                    value={product.newStock}
                                                    onChange={this.onChangeTable}
                                                    disabled={this.state.proceed}
                                                />
                                            </td>
                                            <td> {product.newStock * product.packageSize} </td>
                                            {this.state.proceed ? (
                                                <td>
                                                    {(product.newStock - product.stock) !== 0 ? (
                                                        <h4>
                                                            <Label>
                                                                {(product.newStock - product.stock) > 0 ? '+' : null}
                                                                {product.newStock - product.stock}
                                                                {(product.newStock - product.stock) > 0 ? (
                                                                    <FontAwesomeIcon className="ml-2" icon={faCaretSquareUp} color="blue" />
                                                                ) : (
                                                                    <FontAwesomeIcon className="ml-2" icon={faCaretSquareDown} color="red" />
                                                                )}
                                                            </Label>
                                                        </h4>
                                                    ) : (
                                                        <h4>
                                                            <FontAwesomeIcon color="green" icon={faCheck} />
                                                        </h4>
                                                    )}
                                                </td>
                                            ) : (
                                                <td>
                                                    <Button color="danger" size="sm" onClick={this.removeProductsFromList.bind(this, i)}>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                    
                        {/* Show all procuts with non zero stock which are not included in this list */}
                        {privilege && this.props.report && this.state.proceed ? (
                            <Row className="p-3">
                                <h5>Reset products to stock</h5>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Products</th>
                                            <th>Code</th>
                                            <th>Pages</th>
                                            <th>GSM</th>
                                            <th>P.size</th>
                                            <th></th>
                                            <th>
                                                <button id="removeAllNonZero" className="btn-style" onClick={this.removeAllNonZeroQuantity} >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                                <Tooltip placement="top" target="removeAllNonZero" isOpen={this.state.tooltip} toggle={this.toggleTooltip}>
                                                    Remove all products from list
                                                </Tooltip>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.nonZeroProductsList.map((product, i) => (
                                            <tr>
                                                <td>{i+1}</td>
                                                <td> {product.name} ({product.productType}) </td>
                                                <td> {product.code} </td>
                                                <td> {product.pages} </td>
                                                <td> {product.gsm} </td>
                                                <td> {product.packageSize} </td>
                                                <td>
                                                    {(product.newStock - product.stock) !== 0 ? (
                                                        <h4>
                                                            <Label>
                                                                {(product.newStock - product.stock) > 0 ? '+' : null}
                                                                {product.newStock - product.stock}
                                                                {(product.newStock - product.stock) > 0 ? (
                                                                    <FontAwesomeIcon className="ml-2" icon={faCaretSquareUp} color="blue" />
                                                                ) : (
                                                                    <FontAwesomeIcon className="ml-2" icon={faCaretSquareDown} color="red" />
                                                                )}
                                                            </Label>
                                                        </h4>
                                                    ) : (
                                                        <h4>
                                                            <FontAwesomeIcon color="green" icon={faCheck} />
                                                        </h4>
                                                    )}
                                                </td>
                                                <td>
                                                    <Button color="secondary" size="sm" onClick={this.removeNonZeroQuantity.bind(this, i)} >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        ) : null}
                    </ModalBody>

                    <ModalFooter>
                        {this.state.alertMsg ? (
                            <Label> {this.state.alertMsg} </Label>
                        ) : null}
                        {!this.props.report ? (
                            <Button color="primary" onClick={this.submitReport}>Submit Report</Button>
                        ) : null}
                        {privilege && this.props.report ? (
                            <div>
                                {this.state.proceed ? (
                                    <div>
                                        <div className="float-right">
                                            <Button color="primary" onClick={this.saveChanges}>Save Chnages</Button>
                                        </div>
                                        <div className="float-left mr-4">
                                            <Button color="secondary" onClick={this.goBack} >Back</Button>
                                        </div>
                                    </div>
                                ) : (
                                    <Button color="primary" onClick={this.proceedChanges} >Proceed</Button>
                                )}
                            </div>
                        ) : null}
                        <Button color="cancel" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    product: state.product,
    customProduct: state.customProduct,
    user: state.auth.user,
})

export default connect(mapStateToProps, { inventoryCount, customProductsInventoryCount, addReport, submitReport } )(InventoryCount);