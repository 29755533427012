import { combineReducers } from 'redux';
import productReducer from './productReducer';
import customerReducer from './customerReducer';
import orderReducer from './orderReducer';
import customProductReducer from './customProductReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import employeeReducer from './employeeReducer';
import logReducer from './logReducer';
import reportReducer from './reportReducer';
import userReducer from './userReducer';

export default combineReducers({
  product: productReducer,
  customer: customerReducer,
  order: orderReducer,
  customProduct: customProductReducer,
  error: errorReducer,
  auth: authReducer,
  employee: employeeReducer,
  report: reportReducer,
  user: userReducer,
});