import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect} from 'react-redux';
import PropTypes from 'prop-types';
import { unarchiveShippingAddress } from '../../../../actions/CustomerActions';
import { formatAddress } from '../../../constVarAndFunc/formats';

class DeleteShippingAddress extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false
    }

    static propTypes = {
        unarchiveShippingAddress: PropTypes.func.isRequired
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    deleteShippingAddress = () => {
        // Archive Customer address -> set isActive to false
        let addressId = this.props.address.addressId;
        let shippingAddress = this.props.customer.shippingAddress;
        let sAddress = {}

        shippingAddress = shippingAddress.map(address => {
            if(address.addressId === addressId) {
                sAddress = {
                    ...address,
                    isActive: false,
                }
                return sAddress;
            } else return address;
        })

        let customer = {
            _id: this.props.customer._id,
            shippingAddress,
        }
        this.props.unarchiveShippingAddress(customer);
        this.cancelDelete();
    }

    cancelDelete = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button 
                    className="btn-style float-right" 
                    onClick={this.toggle}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Are you sure?
                    </ModalHeader>
                    <ModalBody style={{whiteSpace: 'pre-wrap'}}>
                        <Card className="shadow-sm m-3">
                            <CardBody>
                                {formatAddress(this.props.address)}
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteShippingAddress} >Delete</Button>
                        <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { unarchiveShippingAddress })(DeleteShippingAddress);