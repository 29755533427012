import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUser } from '../../../actions/UserActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ADMINISTRATION_MANAGEMENT, MANAGER, USER } from '../../constVarAndFunc/UserLevel';

class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        name: '',
        status: undefined,
        userLevel: undefined,
    }

    static propTypes = {
        updateUser: PropTypes.func.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    toggle = () => {
        let { user } = this.props;
        this.setState({
            modal: !this.state.modal,
            name: user.name,
            status: user.isActive,
            userLevel: user.userLevel,
        })
    }

    toggleStatus = () => {
        this.setState({
            status: !this.state.status,
        })
    }

    userLevelOptions = [
        ADMINISTRATION_MANAGEMENT,
        USER,
    ]

    saveChanges = () => {
        if(!this.state.name) {
            this.setState({
                msg: 'Name field cannot be empty',
            })
            return;
        }

        let user = {
            _id: this.props.user._id,
            isActive: this.state.status,
            name: this.state.name,
            userLevel: this.state.userLevel,
        }

        this.props.updateUser(user);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        let { user } = this.props;
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                </button>

                <Modal toggle={this.toggle} isOpen={this.state.modal}>
                    <ModalHeader>
                        Edit User
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md={4} >
                                Status:
                            </Col>
                            <Col md={8} >
                                {this.state.status ? (
                                    <Button color="success" onClick={this.toggleStatus}>
                                        Active
                                    </Button>
                                ) : (
                                    <Button color="secondary" onClick={this.toggleStatus}>
                                        Inactive
                                    </Button>
                                )}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={4} >
                                Name:
                            </Col>
                            <Col md={8} >
                                <Input 
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={4} >
                                User Level:
                            </Col>
                            <Col md={8} >
                                <Input 
                                    type="select"
                                    name="userLevel"
                                    value={this.state.userLevel}
                                    onChange={this.onChange}
                                >
                                    {this.userLevelOptions.map(option => (
                                        <option> {option} </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.saveChanges}>Save changes</Button>
                        <Button color="secondary" onClick={this.cancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { updateUser })(EditUser);