import {
    LOADING_LOGS,
    GET_LOGS,
    ADD_LOG,
} from './types';
import { tokenConfig } from './AuthActions';
import axios from 'axios';

export const loadingLogs = () => {
    return {
        type: LOADING_LOGS,
    }
}

export const getLogs = dispatch => {
    dispatch(loadingLogs());
    axios
        .get('/api/logs')
        .then(res => {
            dispatch({
                type: GET_LOGS,
                payload: res.data,
            })
        })
}

export const addLog = (log, dispatch)  => {
    axios
        .post('/api/logs/', log)
        .then(res => {
            dispatch({
                type: ADD_LOG,
                payload: res.data,
            })
        })
}