import React from 'react';
import { Button, Card, CardBody, Label, Table, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsers } from '../../../actions/UserActions';
import { ADMINISTRATION_MANAGEMENT } from '../../constVarAndFunc/UserLevel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import EditUser from './EditUser';
import NewUserModal from './NewUserModal';

class Users extends React.Component {
    state = {

    }

    componentDidMount = () => {
        let isAdmin = this.props.auth.user.userLevel === ADMINISTRATION_MANAGEMENT;
        
        if(isAdmin) {
            this.props.getUsers();
        }
    }

    static propTypes = {
        user: PropTypes.object.isRequired,
        getUsers: PropTypes.func.isRequired,
    }

    render() {
        let { users } = this.props.user;
        return (
            <>

                {/* ADD NEW USER */}
                <div className="fab-container">
                    <button className="btn-style fab fab-icon-holder">
                        <FontAwesomeIcon icon={faPlus} size="2x" color="blue" />
                    </button> 

                    <ul className="fab-options">
                        <li>
                            <Label className="fab-label">Add New Product</Label>
                            <NewUserModal />
                        </li>
                    </ul>

                </div>

                {/* Display users */}
                <Card className="m-3">
                    <CardBody>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>User Level</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr class={user.isActive ? "" : "table-secondary"}>
                                        <td> {user.isActive ? (
                                            <div>
                                                <FontAwesomeIcon icon={faCircle} color="green" size="xs" /> Active
                                            </div>
                                        ) : "Inactive"} </td>
                                        <td> {user.name} </td>
                                        <td> {user.email} </td>
                                        <td> {user.userLevel} </td>
                                        <td>
                                            <EditUser user={user}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
})

export default connect(mapStateToProps, { getUsers })(Users);