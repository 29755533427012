import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { formatAmount } from '../../../../constVarAndFunc/formats';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addPayment } from '../../../../../actions/OrderActions';

class AddPaymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state ={
        modal: false,
        dateOfPayment: moment().locale('en').format('YYYY-MM-DD'),
        method: null,
        amount: null,
        // Greater than 0 -> text == Amount
        // Less than 0 -> text == Refund
        amountText: 'Amount: ',
        notes: null,
        fullPayment: false,
    }

    static propTypes = {
        addPayment: PropTypes.func.isRequired,
    }

    // Calculate Due payment amount when components are ready
    componentDidMount = () => {
        this.paymentDue();
    }

    componentDidUpdate = prevProps => {
        if(prevProps.order !== this.props.order) {
            this.paymentDue();
        }
    }

    // Modal toggle
    toggle = () => {
        this.setState({ modal: !this.state.modal });
        this.paymentDue();
    }

    // state onChange
    onChange = e => {
        // If amount less than 0 change amountText ('Amount: ) to 'Refund: '
        if(e.target.name ===  'amount') {
            if(e.target.value < 0) {
                this.setState({
                    amountText: 'Refund: ',
                })
            } else {
                this.setState({
                    amountText: 'Amount: ',
                })
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            errorMsg: null
        })
    }

    // input type select on Change
    onChangePaymentSelect = e => {
        // If amount less than 0 change amountText ('Amount: ) to 'Refund: '
        let amountText = 'Amount: ';
        if(this.state.paymentDue < 0) {
            amountText = 'Refund: ';
        }
        this.setState({
            fullPayment: !this.state.fullPayment,
            amount: this.state.paymentDue,
            amountText,
        })
    }

    // Calculate due payment amount
    // Set state and return due amount
    paymentDue = () => {
        let { order } = this.props;
        let due = this.props.order.orderSummary.grandTotal;
        order.payments.forEach(payment => {
            due -= payment.amount;
        });
        
        this.setState({
            paymentDue: due,
        })
        
        return due;
    }

    // Cancel btn action
    cancelPayment = () => {
        this.setState(this.initialState);
    }

    // Apply btn action
    addPayment = () => {
        // Validate
        {
            if(!this.state.dateOfPayment) {
                this.setState({
                    errorMsg: 'Please Select a Date of Payment',
                })
                return;
            }

            if(!this.state.method) {
                this.setState({
                    errorMsg: 'Please Select a Method of Payment',
                })
                return;
            }

            if(this.state.amount) {
                if (parseFloat(this.state.amount) > this.state.paymentDue) {
                    this.setState({
                        errorMsg: 'Amount exceeded'
                    })
                    return;
                }
            }

            if(!this.state.amount) {
                this.setState({
                    errorMsg: 'Please Enter an Amount',
                })
                return;
            }
        }

        // Updating Due payment amount 
        this.setState({
            paymentDue: this.state.paymentDue - this.state.amount,
        })

        // Creating payment object for payments array
        let payment = {
            dateOfPayment: this.state.dateOfPayment,
            method: this.state.method,
            amount: this.state.amount,
            notes: this.state.notes,
        }
        let payments = [...this.props.order.payments, payment];

        // Creating Order object to be updated
        let order = {
            _id: this.props.order._id,
            payments,
        }
        this.props.addPayment(order);
        this.cancelPayment();
    }

    render() {
        const { grandTotal } = this.props.order.orderSummary
        return (
            <div>
                <Button color="primary float-left" onClick={this.toggle} disabled={!this.state.paymentDue}>
                    <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                    Add Payment
                </Button>
                <Label className="text-muted float-left ml-2">Due Amount: {formatAmount(this.state.paymentDue)} </Label>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    backdrop="static"
                >
                    <ModalHeader>Add Payment</ModalHeader>

                    <ModalBody>
                        <h4><strong>Grand Total:</strong> {formatAmount(grandTotal)} </h4>
                        <Label><strong>Payment Due: </strong> {formatAmount(this.state.paymentDue)} </Label>
                        <hr />

                        <Row className="mb-2">
                            <Col md={4}>
                                <Label><strong>Payment Date: </strong></Label>
                            </Col>
                            <Col md={8} >
                                <Input 
                                    type="date" 
                                    name="dateOfPayment"
                                    onChange={this.onChange}
                                    value={this.state.dateOfPayment}/>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col md={4} >
                                <Label><strong>Method: </strong></Label>
                            </Col>
                            <Col md={8} >
                                <Input 
                                    type="select"
                                    name="method"
                                    onChange={this.onChange}
                                    value={this.state.method}
                                >
                                    <option selected disabled>Select method of payment</option>
                                    <option> {"Cash"} </option>
                                    <option> {"Debit Card"} </option>
                                    <option> {"Credit Card"} </option>
                                    <option> {"Cheque"} </option>
                                    <option> {"Round off"} </option>
                                </Input>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col md={4} >
                                <Label><strong>{this.state.amountText} </strong></Label>
                            </Col>
                            <Col md={5}>
                                <Input 
                                    type="number"
                                    disabled={this.state.fullPayment}
                                    name="amount"
                                    onChange={this.onChange}
                                    value={this.state.amount}
                                    placeholder="Enter Amount"
                                />
                            </Col>
                            <Col md={3}>
                                <Input 
                                    type="checkbox" 
                                    onChange={this.onChangePaymentSelect}
                                    checked={this.state.fullPayment}
                                />
                                <Label> Paid in full</Label>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col md={4} >
                                <Label><strong>Notes: </strong></Label>
                            </Col>
                            <Col md={8} >
                                <Input 
                                    type="textarea" 
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={this.onChange}
                                    placeholder="Add Notes"
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Label className="text-danger mr-auto" > {this.state.errorMsg} </Label>
                        <Button color="primary" onClick={this.addPayment}>Add Payment</Button>
                        <Button color="secondary" onClick={this.cancelPayment} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { addPayment })(AddPaymentModal);