import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, Label } from 'reactstrap';
import  { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOrderChallan } from '../../actions/OrderActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { PENDING, RETURNED, SHIPPED, VOID } from '../constVarAndFunc/StatusOptions';
import moment from 'moment';

class PrintChallanHandler extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        updateOrderChallan: PropTypes.func.isRequired,
    }

    componentDidUpdate = () => {
        if(this.state.modal) {
            if(this.props.order.status !== PENDING) {
                const id = this.props.order._id;
                window.open('./print/challan/#' + id);
            }
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    changeStatusToShipping = () => {
        const challanDate = moment().format('YYYY-MM-DD');
        let startNumber = 0;
        let challanNumber = moment.utc().format('YY') + String(startNumber).padStart(5, '0');

        let challanSet = new Set();
        this.props.orders.forEach(order => {
            challanSet.add(order.challanNumber);
        })

        while(challanSet.has(challanNumber)) {
            startNumber += 1;
            challanNumber = moment.utc().format('YY') + String(startNumber).padStart(5, '0');
        }

        let order = {
            _id: this.props.order._id,
            status: SHIPPED,
            challanNumber,
            challanDate,
        }

        console.log(order);
        this.props.updateOrderChallan(order);
        this.printChallan();
    }

    printChallan = () => {
        const id = this.props.order._id;
        window.open('./print/challan/#' + id);
        this.cancel();
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        const { order } = this.props;
        return (
            <div>
                {order.status === RETURNED || order.status === VOID ? null : (
                    <div>
                        {order.status === PENDING ? (
                            <button className="btn-style float-right mr-3" onClick={this.toggle}>
                                <FontAwesomeIcon icon={faPrint} className="mr-2" />
                                Challan
                            </button>
                        ) : (
                            <button className="btn-style float-right mr-3" onClick={this.printChallan}>
                                <FontAwesomeIcon icon={faPrint} className="mr-2" />
                                Challan
                            </button>
                        )}
                    </div>
                )}
                
                <Modal 
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalBody>
                        <h4>Update Status to shipping?</h4>
                        <Label>Click Shipping to change the status to shipping</Label>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.changeStatusToShipping}>Shipping</Button>
                        <Button color="warning" onClick={this.printChallan}>Challan (view only)</Button>
                        <Button color="secondary" onClick={this.cancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.order.orders,
})

export default connect(mapStateToProps, { updateOrderChallan } )(PrintChallanHandler);