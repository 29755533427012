import React from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Form, FormGroup, Input, Label, Row, Toast, ToastHeader } from 'reactstrap';
import { getCustomers } from '../../../actions/CustomerActions';
import NewCustomerModal from './NewCustomerModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerProfile from './CustomerProfile/CustomerProfile';
import { formatAddress } from '../../constVarAndFunc/formats';
import { 
    COLLEGE_UNIVERSITY, 
    COMMUNITY, 
    CORPORATION, 
    EXPORT, 
    FACTORY_SALE, 
    GOVERNMENT, 
    ONLINE, 
    PERSON, 
    RETAIL, 
    SCHOOL, 
    TENDER, 
    WHOLESALE, 
    OTHERS, 
} from '../../constVarAndFunc/ContactType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

class Customer extends React.Component {

    constructor(props) {
        super(props);
        this.initialState = this.state;
        
        window.addEventListener('hashchange', e => {
            this.setState({
                urlHash: window.location.hash.replace('#', ''),
            })
        })

        this.customerTypes = [
            COLLEGE_UNIVERSITY, 
            COMMUNITY, 
            CORPORATION, 
            EXPORT, 
            FACTORY_SALE, 
            GOVERNMENT, 
            ONLINE, 
            PERSON, 
            RETAIL, 
            SCHOOL, 
            TENDER, 
            WHOLESALE, 
            OTHERS, 
        ]
    }

    state = {
        urlHash: window.location.hash.replace('#', ''), // hash -> CustomerId 

        serach: '',
        searchResult: [], // array of customers
        showAddNewBtn: false,
        toast: false,

        // Search
        contactTypeSearch: undefined,
        customerTypesList: [],
        customerName: '',
        customerId: '',
        email: '',
        
        // Advance search
        advanceSearch: false,
        contactNumber: '',
        gstin: '',
        address: '',
        zipCode: '',
        city: '',
        state: '',
        country: '',
    }

    static propTypes = {
        customer: PropTypes.object.isRequired,
        getCustomers: PropTypes.func.isRequired,
    }

    componentDidMount = () => {
        this.props.getCustomers();
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleAdvanceSearch = () => {
        this.setState({
            advanceSearch: !this.state.advanceSearch,
        })
    }

    onSubmit = e => {
        e.preventDefault();
        this.searchCustomers();
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.searchCustomers();
        }
    }

    // Show toast when user is added
    showToast = (newCustomer) => {
        this.setState({ toast: true, newCustomer })
    }

    toggleToast = () => {
        this.setState({ toast: !this.state.toast });
    }

    matchSearch = (string, stateProp) => {
        if(!stateProp) {
            stateProp = this.state.search;
        }
        if(string && stateProp){
            if((String(string).toUpperCase()).includes((stateProp).toUpperCase())) {        
                return true;
            };
        }
        return false;
    }

    // SEARCH CUSTOMER HELPER METHOD
    getResults = () => {
        let { customers } = this.props.customer;
        let results = [...customers];
        // General Search..
        if(this.state.search) {
            // Show results without advance search -> General Search
            results = results.filter(customer => {
                if(customer.category) {
                    if(this.matchSearch(customer.category)) {
                        return customer;
                    }
                }
                if(customer.customerId) {
                    if(this.matchSearch(customer.customerId)) {
                        return customer;
                    }
                }
                if(customer.customerName) {
                    if(this.matchSearch(customer.customerName)) {
                        return customer;
                    }
                }
                if(customer.email) {
                    if(this.matchSearch(customer.email)) {
                        return customer;
                    }
                }
                if(customer.mobileNumber) {
                    if(this.matchSearch(customer.mobileNumber)) {
                        return customer;
                    }
                }
                if(customer.homeNumber) {
                    if(this.matchSearch(customer.homeNumber)) {
                        return customer;
                    }
                }
                if(customer.address) {
                    if(customer.address.line1) {
                        if(this.matchSearch(customer.address.line1)) {
                            return customer;
                        }
                    }
                    if(customer.address.street) {
                        if(this.matchSearch(customer.address.street)) {
                            return customer;
                        }
                    }
                    if(customer.address.landmark) {
                        if(this.matchSearch(customer.address.landmark)) {
                            return customer;
                        }
                    }
                    if(customer.address.city) {
                        if(this.matchSearch(customer.address.city)) {
                            return customer;
                        }
                    }
                    if(customer.address.state) {
                        if(this.matchSearch(customer.address.state)) {
                            return customer;
                        }
                    }
                }
            });
        }

        // Detailed Search
        console.log('Just checking');
        console.log(this.state.customerTypesList);
        if(this.state.customerTypesList.length !== 0) {
            results = results.filter(customer => {
                if(this.state.customerTypesList.includes(customer.category)) {
                    return customer;
                }
            })
        }

        if(this.state.customerName) {
            results = results.filter(customer => {
                if(customer.customerName) {
                    if(this.matchSearch(customer.customerName, this.state.customerName)) {
                        return customer;
                    }
                }
            })
        }

        if(this.state.customerId) {
            results = results.filter(customer => {
                if(customer.customerId) {
                    if(this.matchSearch(customer.customerId, this.state.customerId)) {
                        return customer;
                    }
                }
            })
        }

        if(this.state.email) {
            results = results.filter(customer => {
                if(customer.email) {
                    if(this.matchSearch(customer.email, this.state.email)) {
                        return customer;
                    }
                }
            })
        }

        // Advanced Search
        if(this.state.contactNumber) {
            results = results.filter(customer => {
                if(customer.mobileNumber) {
                    if(this.matchSearch(customer.mobileNumber, this.state.contactNumber)) {
                        return customer;
                    }
                }
                if(customer.homeNumber) {
                    if(this.matchSearch(customer.homeNumber, this.state.contactNumber)) {
                        return customer;
                    }
                }
            })
        }

        if(this.state.gstin) {
            results = results.filter(customer => {
                if(customer.gstin) {
                    if(this.matchSearch(customer.gstin, this.state.gstin)) {
                        return customer;
                    }
                }
            })
        }

        if(this.state.address) {
            results = results.filter(customer => {
                console.log(customer);
                if(customer.address.line1) {
                    if(this.matchSearch(customer.address.line1, this.state.address)) {
                        return customer;
                    }
                }
                if(customer.address.street) {
                    if(this.matchSearch(customer.address.street, this.state.address)) {
                        return customer;
                    }
                }
                if(customer.address.landmark) {
                    if(this.matchSearch(customer.address.landmark, this.state.address)) {
                        return customer;
                    }
                }

                let foundShippingAddress = false;
                customer.shippingAddress.forEach(address => {
                    if(address.line1) {
                        if(this.matchSearch(address.line1, this.state.adderss)) {
                            foundShippingAddress = true;
                        }
                    }
                    if(address.street) {
                        if(this.matchSearch(address.street, this.state.address)) {
                            foundShippingAddress = true;
                        }
                    }
                    if(address.landmark) {
                        if(this.matchSearch(address.landmark, this.state.address)) {
                            foundShippingAddress = true;
                        }
                    }
                })
                if(foundShippingAddress) {
                    return customer;
                }
            })
        }

        if(this.state.zipCode) {
            results = results.filter(customer => {
                if(customer.address.zipCode) {
                    if(this.matchSearch(customer.address.zipCode, this.state.zipCode)) {
                        return customer;
                    }
                }

                let foundShippingAddress = false;
                customer.shippingAddress.forEach(address => {
                    if(address.line1) {
                        if(this.matchSearch(address.zipCode, this.state.zipCode)) {
                            foundShippingAddress = true;
                        }
                    }
                })
                if(foundShippingAddress) {
                    return customer;
                }
            })
        }

        if(this.state.city) {
            results = results.filter(customer => {
                if(customer.address.city) {
                    if(this.matchSearch(customer.address.city, this.state.city)) {
                        return customer;
                    }
                }

                let foundShippingAddress = false;
                customer.shippingAddress.forEach(address => {
                    if(address.city) {
                        if(this.matchSearch(address.city, this.state.city)) {
                            foundShippingAddress = true;
                        }
                    }
                })
                if(foundShippingAddress) {
                    return customer;
                }
            })
        }

        if(this.state.state) {
            results = results.filter(customer => {
                if(customer.address.state) {
                    if(this.matchSearch(customer.address.state, this.state.state)) {
                        return customer;
                    }
                }

                let foundShippingAddress = false;
                customer.shippingAddress.forEach(address => {
                    if(address.state) {
                        if(this.matchSearch(address.state, this.state.state)) {
                            foundShippingAddress = true;
                        }
                    }
                })
                if(foundShippingAddress) {
                    return customer;
                }
            })
        }

        if(this.state.country) {
            results = results.filter(customer => {
                if(customer.address.country) {
                    if(this.matchSearch(customer.address.country, this.state.country)) {
                        return customer;
                    }
                }

                let foundShippingAddress = false;
                customer.shippingAddress.forEach(address => {
                    if(address.country) {
                        if(this.matchSearch(address.country, this.state.country)) {
                            foundShippingAddress = true;
                        }
                    }
                })
                if(foundShippingAddress) {
                    return customer;
                }
            })
        }
        return results;
    }

    searchCustomers = () => {
        const element = document.getElementById("customer-search-btn");
        if(element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth" })
            }, 500)
        }
        
        let searchResult = this.getResults();
        this.setState({
            searchResult,
            showAddNewBtn: true,
        })
    }

    clearSearch = () => {
        this.setState(this.initialState);
        this.setState({
            search: '',
            contactTypeSearch: [],
        })
    }

    addCustomerTypeToList = () => {
        if(!this.state.contactTypeSearch) return;
        let customerTypesList = [];
        if(this.state.customerTypesList.includes(this.state.contactTypeSearch)) return;
        if(this.state.customerTypesList.length === 0) {
            customerTypesList = [this.state.contactTypeSearch];
        } else {
            customerTypesList = [...this.state.customerTypesList, this.state.contactTypeSearch];
        }
        this.setState({
            customerTypesList,
        })
    }

    removeCustomerTypeFromList = (type) => {
        this.setState({
            customerTypesList: this.state.customerTypesList.filter(t => t !== type),
        })
    }

    render() {
        return (
            <div>
                {this.state.toast ? (
                    <Toast
                        style={{position: "fixed", bottom: "20px", left: "50%", whiteSpace: "pre-wrap", zIndex: "10"}}
                        toggle={this.toggleToast}
                        isOpen={this.state.toast}
                        className="shadow"
                    >
                        <ToastHeader className="center">
                            {this.state.newCustomer.customerName} added! {"  "}
                            <a href={`#${this.state.newCustomer.customerId}`} onClick={this.toggleToast}>(see profile)</a>
                        </ToastHeader>
                    </Toast>
                ) : (null) }

                {this.state.urlHash ? ( 
                    <div>
                        {/**** Show customer profile page on location.hash ****/}
                        <CustomerProfile />
                    </div>
                ) : (
                    <div> {/**** Show search and results page *****/}

                        {/******* SEARCH PANEL *********/}
                        <Card className="shadow mt-4" style={{backgroundColor: "#fefefe"}}>
                            <CardBody>
                                <Form inline onSubmit={this.onSubmit}>
                                    <FormGroup>
                                        <Label className="mr-2">Search</Label>
                                        <Input 
                                            type="text"
                                            autoComplete="off"
                                            onChange={this.onChange}
                                            name="search"
                                            value={this.state.search} 
                                            placeholder="General search..." />
                                    </FormGroup>
                                </Form>
                                <hr />

                                {/***** DETAIL SEARCH *******/}
                                <Form inline onSubmit={this.onSubmit}>
                                    <FormGroup>
                                        <Label className="mr-2">Contact Type: </Label>
                                        <Input 
                                            type="select"
                                            onChange={this.onChange}
                                            name="contactTypeSearch"
                                            value={this.state.contactTypeSearch}
                                            onChange={this.onChange}
                                        >
                                            <option selected disabled>Select Contact Type</option>
                                            {this.customerTypes.map(customerType => (
                                                <option key={customerType}> {customerType} </option>
                                            ))}
                                        </Input>
                                        <Button color="primary" className="ml-2" onClick={this.addCustomerTypeToList} >Add</Button> 
                                    </FormGroup>
                                </Form>

                                <Row className="mt-2">
                                    {/***** SEARCH BY NAME *******/}
                                    <Col md={4}>
                                        <Label>Name: </Label>
                                        <Input 
                                            type="text"
                                            autoComplete="off"
                                            name="customerName"
                                            value={this.state.customerName}
                                            onChange={this.onChange}
                                            placeholder="Customer Name"
                                            onKeyDown={this.handleKeyDown}
                                        />
                                    </Col>

                                    {/***** SEARCH BY ID *******/}
                                    <Col md={4} >
                                        <Label>ID:</Label>
                                        <Input 
                                            type="text"
                                            autoComplete="off"
                                            name="customerId"
                                            value={this.state.customerId}
                                            onChange={this.onChange}
                                            placeholder="Customer ID"
                                            onKeyDown={this.handleKeyDown}
                                        />
                                    </Col>

                                    {/***** SEARCH BY EMAIL *******/}
                                    <Col md={4} >
                                        <Label>Email:</Label>
                                        <Input 
                                            type="text"
                                            autoComplete="off"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            placeholder="Email"
                                            onKeyDown={this.handleKeyDown}
                                        />
                                    </Col>
                                </Row>
                                
                                {/************** ADVANCE SEARCH ****************/}
                                {this.state.advanceSearch ? (
                                    <Button onClick={this.toggleAdvanceSearch} className="mt-3" color="info" >Cancel </Button>
                                ) : (
                                    <Button onClick={this.toggleAdvanceSearch} className="mt-3" color="info" >Advance Search</Button>
                                )}
                                <Collapse isOpen={this.state.advanceSearch}>
                                    <Card className="shadow">
                                        <CardBody>
                                            <Row className="mb-3">
                                                {/***** SEARCH BY CONTACT NUMBER *******/}
                                                <Col md={4} >
                                                    <Label>Contact: </Label>
                                                    <Input 
                                                        type="text"
                                                        autoComplete="off"
                                                        name="contactNumber"
                                                        value={this.state.contactNumber}
                                                        onChange={this.onChange}
                                                        placeholder="Mobile | Home"
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                </Col>

                                                {/***** SEARCH BY GSTIN *******/}
                                                <Col md={4} >
                                                    <Label> GSTIN: </Label>
                                                    <Input 
                                                        type="text"
                                                        autoComplete="off"
                                                        name="gstin"
                                                        value={this.state.gstin}
                                                        onChange={this.onChange}
                                                        placeholder="GSTIN"
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                </Col>
                                            </Row>
                                            {/***** SEARCH BY ADDRESS *******/}
                                            <Row>
                                                <Col md={12} >
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="mb-2">
                                                                <Col md={8} >
                                                                    <Label>Address: </Label>
                                                                    <Input 
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        name="address"
                                                                        value={this.state.address}
                                                                        onChange={this.onChange}
                                                                        placeholder="Line1 | Street | Landmark"
                                                                        onKeyDown={this.handleKeyDown}
                                                                    />
                                                                </Col>

                                                                <Col md={4} >
                                                                    <Label>Zip</Label>
                                                                    <Input 
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        name="zipCode"
                                                                        value={this.state.zipCode}
                                                                        onChange={this.onChange}
                                                                        placeholder="Zip/PIN Code"
                                                                        onKeyDown={this.handleKeyDown}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={4} >
                                                                    <Label>City: </Label>
                                                                    <Input 
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        name="city"
                                                                        value={this.state.city}
                                                                        onChange={this.onChange}
                                                                        placeholder="City"
                                                                        onKeyDown={this.handleKeyDown}
                                                                    />
                                                                </Col>
                                                                <Col md={4} >
                                                                    <Label>State: </Label>
                                                                    <Input 
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        name="state"
                                                                        value={this.state.state}
                                                                        onChange={this.onChange}
                                                                        placeholder="State"
                                                                        onKeyDown={this.handleKeyDown}
                                                                    />
                                                                </Col>
                                                                <Col md={4} >
                                                                    <Label>Country: </Label>
                                                                    <Input 
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        name="country"
                                                                        value={this.state.country}
                                                                        onChange={this.onChange}
                                                                        placeholder="Country"
                                                                        onKeyDown={this.handleKeyDown}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </CardBody>
                            <CardFooter >
                                {this.state.customerTypesList.map(type => (
                                    <Badge color="light" onClick={this.removeCustomerTypeFromList.bind(this, type)}>
                                        {type}
                                        <button className="btn-style"> <FontAwesomeIcon icon={faTimes}/> </button>
                                    </Badge>
                                ))}

                                <Button 
                                    className="float-right" 
                                    id="customer-search-btn"
                                    color="primary"
                                    onClick={this.searchCustomers} 
                                    type="button" 
                                >Search</Button>
                                <Button 
                                    className="float-right mr-3" 
                                    color="link"
                                    onClick={this.clearSearch}
                                >Clear all</Button>
                            </CardFooter>
                        </Card>
                        
                        {/******* SEARCH RESULT *********/}
                        <div>
                            {this.state.searchResult.map(customer => (
                                <Card className="text-left m-3 d-inline-flex shadow rounded" 
                                        style={{ width: '18rem', whiteSpace: "pre-line" }}>
                                    <CardHeader>
                                        <strong>{customer.category}</strong>
                                    </CardHeader>
                                    <CardBody>
                                        <Label className="mb-0"> {customer.customerName} </Label> {"\n"}
                                        <small className="m-0">{customer.email} </small>
                                        <hr className="mt-2 mb-4"/>
                                        {customer.organizationName ? (
                                            <Label><strong>{customer.organizationName}</strong></Label>
                                        ) : (null)}
                                        <Label>
                                            <strong>Address:</strong> {formatAddress(customer.address)} 
                                        </Label> {"\n"}
                                        <Label>
                                            {customer.mobileNumber ? (
                                                <>
                                                    <strong>Contact:</strong> {customer.mobileNumber}
                                                </>
                                            ) : (
                                                <div>
                                                    {customer.homeNumber ? (
                                                        <>
                                                            <strong>Contact:</strong> {customer.homeNumber}
                                                        </>
                                                    ) : (null)}
                                                </div>
                                            )}
                                        </Label>
                                    </CardBody>
                                    <CardFooter>
                                        <Button href={"#"+customer.customerId} 
                                            >Profile</Button>
                                    </CardFooter>
                                </Card>
                            ))}  
                        </div>

                        {/* Add new customer button */}
                        {/* Only show when searched!! and pass the search params to the modal */}
                        {this.state.showAddNewBtn ? (
                            <div>
                                {this.state.searchResult.length === 0 ? (
                                    <h3 className="mt-3">No results</h3>
                                ) : (null)}
                                <div className="fab-container">
                                    <button className="btn-style fab fab-icon-holder">
                                        <FontAwesomeIcon icon={faPlus} size="2x" color="blue" />
                                    </button> 

                                    <ul className="fab-options">
                                        <li>
                                            <Label className="fab-label">Add New Customer</Label>
                                            <NewCustomerModal showToast={this.showToast} clearSearch={this.clearSearch}/>
                                        </li>
                                    </ul>

                                </div>
                                {/* <NewCustomerModal showToast={this.showToast} clearSearch={this.clearSearch}/> */}

                            </div>

                        ) : null}
                    </div>                  
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer
})

export default connect( mapStateToProps , { getCustomers })(Customer);