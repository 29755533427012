import React from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { addEmployee } from '../../../actions/EmployeeActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';

class AddEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        
        fullName: '',
        jobTitle: null,
        contact: '',
        email: '',
        jobStatus: null,
        startDate: null,

        line1: '',
        street: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',

        notes: '',

        errorMsg: '',
    }

    static propTypes = {
        employee: PropTypes.object.isRequired,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMsg: '',
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    addEmployee = () => {
        {// Validation
            if(!this.state.fullName) {
                this.setState({
                    errorMsg: 'Please enter full name'
                })
                return;
            }

            if(!this.state.contact) {
                this.setState({
                    errorMsg: 'Please enter contact number'
                })
                return;
            }
        }

        // Creat new employee and add 
        // 1. Create employeeId
        let employeeId = 100000;
        this.props.employee.employees.forEach(employee => {
            if(employee.employeeId === (employeeId).toString()) {
                employeeId++;
            }
        })
        // 2. Create employee
        let employee = {
            employeeId,
            fullName: this.state.fullName,
            address: {
                line1: this.state.line1,
                street: this.state.street,
                landmark: this.state.landmark,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                zipCode: this.state.zipCode,
            },
            jobTitle: this.state.jobTitle,
            email: this.state.email,
            contact: this.state.contact,
            jobStatus: this.state.jobStatus,
            startDate: this.state.startDate,
            notes: this.state.notes,
        }
        this.props.addEmployee(employee);
        // console.log(employee);
        this.cancelChanges();
    }

    cancelChanges = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button className="btn-style" onClick={this.toggle} >
                    <FontAwesomeIcon size="2x" icon={faIdBadge} color="#0275d8" />
                </button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader>
                        Add Employee
                    </ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Full name: *</Label>
                                <Input 
                                    type="text"
                                    name="fullName"
                                    value={this.state.fullName}
                                    onChange={this.onChange}
                                    placeholder="Enter full name"
                                />
                            </Col>
                            <Col md={6}>
                                <Label>Job Title:</Label>
                                <Input 
                                    type="select"
                                    name="jobTitle"
                                    value={this.state.jobTitle}
                                    onChange={this.onChange}
                                >
                                    <option selected disabled>Select job jobTitle</option>
                                    <option>Sales Executive</option>
                                    <option>Operator</option>
                                    <option>Accountant</option>
                                    <option>Manager</option>
                                </Input>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Contact Number: *</Label>
                                <Input 
                                    type="text"
                                    name="contact"
                                    value={this.state.contact}
                                    onChange={this.onChange}
                                    placeholder="Mobile Number"
                                />
                            </Col>
                            <Col md={6}>
                                <Label>Email:</Label>
                                <Input 
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    placeholder="Email"
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Job Status:</Label>
                                <Input 
                                    type="select"
                                    name="jobStatus"
                                    value={this.state.jobStatus}
                                    onChange={this.onChange}
                                >
                                    <option selected disabled>Select job status</option>
                                    <option>Contract</option>
                                    <option>Part-time</option>
                                    <option>Full-time</option>
                                </Input>
                            </Col>
                            <Col md={6}>
                                <Label>Start Date:</Label>
                                <Input 
                                    type="date"
                                    name="startDate"
                                    value={this.state.startDate}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>
                        
                        <Row >
                            <Col>
                                <Label>Address</Label>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <Input onChange={this.onChange} value={this.state.line1} type="address" name="line1" placeholder="(Address Line1)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={12}>
                                <Input onChange={this.onChange} value={this.state.street} type="text" name="street" placeholder="(Street/Road)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={12}>
                                <Input onChange={this.onChange} value={this.state.landmark} type="text" name="landmark" placeholder="(Landmark)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={4}>
                                <Input onChange={this.onChange} value={this.state.city} type="text" name="city" placeholder="(City/Town)" />
                            </Col>

                            <Col md={4}>
                                <Input onChange={this.onChange} value={this.state.state} type="text" name="state" placeholder="(State/Province)" />
                            </Col>

                            <Col md={4}>
                                <Input onChange={this.onChange} value={this.state.country} type="text" name="country" placeholder="(Country)" />
                            </Col>
                        </Row>

                        <Row form className="mt-2">
                            <Col md={4} >
                                <Input onChange={this.onChange} value={this.state.zipCode} type="text" name="zipCode" placeholder="(Zip/Pin Code)" />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={12}>
                                <Label>Notes:</Label>
                                <Input 
                                    type="textarea"
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={this.onChange}
                                    placeholder="Add notes..."
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        {this.state.errorMsg ? (
                            <Label color="red" className="mr-auto" > {this.state.errorMsg} </Label>
                        ) : null}
                        <Button color="primary" onClick={this.addEmployee} >Add Employee</Button>
                        <Button color="secondary" onClick={this.cancelChanges} >Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    employee: state.employee
})

export default connect(mapStateToProps, { addEmployee })(AddEmployee)