import { faCheck, faExclamationCircle, faEye, faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { Label, Card, CardBody, Button, Input, Dropdown, 
    DropdownItem, DropdownToggle, DropdownMenu, Row, Col,
    Table,
} from 'reactstrap';
import OrderDetails from '../CustomersTab/Orders/OrderDetails';
import PropTypes from 'prop-types';
import { DECLINE, PENDING, RETURNED, VOID } from '../../constVarAndFunc/StatusOptions';
import { formatAmount, formatDate } from '../../constVarAndFunc/formats';
import moment from 'moment';
import ViewOrder from './ViewOrder';

class Summary extends React.Component {
    state = {
        periodOpen: false,
        period: 'Month',

        startDate: moment().format('YYYY-MM'),
        endDate: '',

        // Totals
        totalRevenue: 0,
        totalDeals: 0,
        totalProducts: 0,

        // Produccts details (combined from all orders)
        products: [],
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        customProduct: PropTypes.object.isRequired,
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.order !== this.props.order) {
            this.setPeriodStat();
            this.getProductsPipeline();
        }
        
        if(prevState.period !== this.state.period) {
            this.setPeriodStat();
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.setPeriodStat();
        })
    }

    togglePeriod = () => {
        this.setState({
            periodOpen: !this.state.periodOpen,
        })
    }

    setPeriod = period => {
        let startDate, endDate;
        if(period === 'Day') {
            startDate = moment.utc().format('YYYY-MM-DD')
        } else if(period === 'Month') {
            startDate = moment.utc().format('YYYY-MM')
        } else if(period === 'Year') {
            startDate = moment.utc().format('YYYY')
        }
        this.setState({
            period,
            startDate,
            endDate,
        })
    }

    setPeriodStat = () => {
        // Get orders matching the period
        let { orders } = this.props.order;
        orders = orders.filter(order => order.status !== DECLINE && order.status !== VOID && order.status !== RETURNED)
        console.table(orders);
        if(this.state.period === 'Day') {
            orders = orders.filter(order => {
                return moment.utc(order.orderDate).isSame(this.state.startDate, 'day')
            })
        } else if(this.state.period === 'Month') {
            orders = orders.filter(order => {
                return moment.utc(order.orderDate).isSame(this.state.startDate, 'month')
            })
        } else if(this.state.period === 'Year') {
            orders = orders.filter(order => {
                return moment.utc(order.orderDate).isSame(this.state.startDate, 'year')
            })
        } else if(this.state.period === 'Custom') {
            orders = orders.filter(order => {
                return moment.utc(order.orderDate).isBetween(this.state.startDate, this.state.endDate)
            })
        }

        // Set stats with filtered orders
        let totalRevenue = 0;
        let totalDeals = orders.length;
        let totalProducts = new Set();
        orders.forEach(order => {
            totalRevenue += order.orderSummary.grandTotal;
            
            order.products.forEach(product => {
                totalProducts.add(product.code);
            })
        })
        this.setState({
            totalRevenue,
            totalDeals,
            totalProducts: totalProducts.size,
        })
    }

    periodList = [
        'Day',
        'Month',
        'Year',
        'Custom'
    ]

    getProduct = (code) => {
        let product;
        this.props.product.products.forEach(p => {
            if(p.code === code) product = p;
        })
        
        if(!product) {
            this.props.customProduct.customProducts.forEach(p => {
                if(p.code === code) product = p;
            })
        }

        return product;
    }

    getProductsPipeline = () => {
        let productsMap = new Map();
        this.props.order.orders.forEach(order => {
            if(order.status === PENDING) {
                order.products.forEach(p => {
                    // If new product in the map add to map
                    let product = this.getProduct(p.code)
                    if(!productsMap.has(p.code)) {
                        productsMap.set(p.code, {
                            code: p.code,
                            name: `${product.name} (${product.productType})`,
                            pages: product.pages,
                            packageSize: product.packageSize,
                            quantity: product.stock * product.packageSize,
                            pipelineQuantity: p.packageSize * p.numPackage,
                        })
                    } else {
                        // If product already exists in the map then add pipelineQuantity
                        let updateProduct = productsMap.get(p.code);
                        updateProduct.pipelineQuantity += p.packageSize * p.numPackage;
                        productsMap.set(p.code, updateProduct);
                    }
                })
            }
        })

        this.setState({
            products: [...productsMap.values()],
        })
    }

    render() {
        let { orders } = this.props.order;
        orders = orders.filter(order => order.status === PENDING);

        // A set of customer name by customer id
        let getCustomerName = new Map();
        this.props.customer.customers.forEach(customer => {
            getCustomerName.set(customer.customerId, customer.customerName);
        })

        return (
            <div className="m-3">
                {/* Row with period settings */}
                <Row>
                    <Dropdown isOpen={this.state.periodOpen} toggle={this.togglePeriod}  >
                        <DropdownToggle caret>
                            {this.state.period}
                        </DropdownToggle>

                        <DropdownMenu >
                            {this.periodList.map(period => (
                                <DropdownItem onClick={this.setPeriod.bind(this, period)}> {period} </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>

                    {this.state.period === 'Day' ? (
                        <Col md={3}>
                            <Input 
                                type="date"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.onChange}
                            />
                        </Col>
                    ) : null}
                    {this.state.period === 'Month' ? (
                        <Col md={3} >
                            <Input 
                                type="month"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.onChange}
                            />
                        </Col>
                    ) : null}
                    {this.state.period === 'Year' ? (
                        <Col md={3} >
                            <Input 
                                type="year"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.onChange}
                            />
                        </Col>
                    ) : null}
                    {this.state.period === 'Custom' ? (
                        <Row className="ml-2">
                            <Col md={1}>
                                <Label>From:</Label>
                            </Col>
                            <Col md={5} >
                                <Input 
                                    type="date"
                                    name="startDate"
                                    value={this.state.startDate}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col md={1}>
                                <Label>To:</Label>
                            </Col>
                            <Col md={5} >
                                 <Input 
                                    type="date"
                                    name="endDate"
                                    value={this.state.endDate}
                                    onChange={this.onChange}
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Row>

                {/******** First Row - Totals per Period ********/}
                <Row className="mt-2">
                    <Col md={4} >
                        <Card className="shadow">
                            <CardBody style={{background: '#0275d8', color: 'white'}}>
                                <Label className="align-items-start" >
                                    Total revenue in given period:
                                </Label>
                                <center className="align-items-center mt-3" style={{padding: '10%'}}>
                                    <h2 >
                                        <FontAwesomeIcon icon={faRupeeSign} className="mr-1" />
                                        {formatAmount(this.state.totalRevenue)} /-
                                    </h2>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4} >
                        <Card className="shadow">
                            <CardBody style={{background: '#0275d8', color: 'white'}}>
                                <Label className="align-items-start" >
                                    Total number of deals made:
                                </Label>
                                <center className="align-items-center mt-3" style={{padding: '10%'}}>
                                    <h2 >
                                        {this.state.totalDeals}
                                    </h2>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4} >
                        <Card className="shadow">   
                            <CardBody style={{background: '#0275d8', color: 'white'}}>
                                <Label className="align-items-start" >
                                    Total number of products: 
                                </Label>
                                <center className="align-items-center mt-3" style={{padding: '10%'}}>
                                    <h2 >
                                        {this.state.totalProducts}
                                    </h2>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <hr />
                {/* **** PENDING ORDERS ***** */}
                <Row className="mt-2" style={{maxHeight: 'auto'}}>
                    <Col md={4} >
                        <Card className="shadow">
                            <CardBody style={{background: '#5bc0de', color: 'white', height: "14rem"}}>
                                <Label className="align-items-start" >
                                    {orders.filter(o => moment(o.deliveryDate).isBefore(moment())).length === 0 ? (
                                        <FontAwesomeIcon icon={faCheck} color="green" className="mr-2"/>
                                    ) : (
                                        <FontAwesomeIcon icon={faExclamationCircle} color="red" className="mr-2" />
                                    )}
                                    Orders missed delivery date:
                                </Label>
                                <center className="align-items-center mt-3 pt-3">
                                    <h1 >
                                        {orders.filter(o => moment(o.deliveryDate).isBefore(moment())).length}
                                    </h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={8}>
                        <Card className="shadow tableFixHead">
                            <CardBody >
                                <Label className="mb-2">Pending Orders</Label>
                                <Table size="sm" hover >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>C. Name</th>
                                            <th>Order ID</th>
                                            <th>Items</th>
                                            <th>Order date</th>
                                            <th>Delivery date</th>
                                            <th>Amount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="overflow-auto">
                                        {orders.map((order, i) => (
                                            <tr className={moment().isAfter(order.deliveryDate) ? ("table-danger") : (null)}>
                                                <td> {i+1} </td>
                                                <td> {getCustomerName.get(order.customerId)} </td>
                                                <td> {(order._id).slice(-10)} </td>
                                                <td> {order.products.length} </td>
                                                <td> {formatDate(order.orderDate)} </td>
                                                <td> {formatDate(order.deliveryDate)} </td>
                                                <td> {formatAmount(order.orderSummary.grandTotal)} </td>
                                                <td>
                                                    <ViewOrder order={order} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Button color="link" className="float-right" href="/sales">View all orders</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <hr />
                {/* Products in pipeline */}
                <Row className="mt-3">
                    <Col md={4} >
                        <Card className="shadow">
                            <CardBody style={{background: '#5bc0de', color: 'white', height: "14rem"}}>
                                <Label className="align-items-start" >
                                    {this.state.products.filter(p =>p.quantity < p.pipelineQuantity).length === 0 ? (
                                        <FontAwesomeIcon icon={faCheck} color="green" className="mr-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faExclamationCircle} color="red" className="mr-2" />
                                    )}
                                    Products with low stock: 
                                </Label>
                                <center className="align-items-center mt-3 pt-3">
                                    <h1 >
                                        {this.state.products.filter(p => p.quantity < p.pipelineQuantity).length}
                                    </h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={8} >
                        <Card className="shadow tableFixHead">
                            <CardBody >
                                <Label className="mb-2">Products in pipeline</Label>
                                <Table size="sm" hover >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Code</th>
                                            <th>Product Name</th>
                                            <th>Pages</th>
                                            <th>Pack. Size</th>
                                            <th>Quantity</th>
                                            <th>Pipeline</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="overflow-auto">
                                        {this.state.products.map((product, i) => (
                                            <tr>
                                                <td> {i+1} </td>
                                                <td> {product.code} </td>
                                                <td> {product.name} </td>
                                                <td> {product.pages} </td>
                                                <td> {product.packageSize} </td>
                                                <td> {product.quantity} </td>
                                                <td> {product.pipelineQuantity} </td>
                                                <td>
                                                    {product.quantity >= product.pipelineQuantity ? (
                                                        <FontAwesomeIcon icon={faCheck} color="green" />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faExclamationCircle} color="red"/>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Button color="link" className="float-right" href="/products" >View all products</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    order: state.order,
    customer: state.customer,
    product: state.product,
    customProduct: state.customProduct,
})

export default connect(mapStateToProps, {})(Summary);