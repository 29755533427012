import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  LOADING_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_STOCK,
  INVENTORY_COUNT,
  ARCHIVE_PRODUCT,
  UNARCHIVE_PRODUCT,
  DAMAGED_PRODUCTS,
} from "../actions/types";

const initialState = {
  products: [],
  activeProducts: [],
  isLoading: false,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_PRODUCTS:
            return {
                ...state,
                isLoading: true
            }
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                activeProducts: action.payload.filter(product => product.isActive),
                isLoading: false
            }
        case ADD_PRODUCT: 
            return {
              ...state,
              products: [...state.products, action.payload],
              activeProducts: [...state.activeProducts, action.payload],
            };
        case DELETE_PRODUCT:
            return {
                ...state,
                products: state.products.filter(product => product._id !== action.payload),
                activeProducts: state.activeProducts.filter(product => product._id !== action.payload),
            }
        case ADD_STOCK:
        case INVENTORY_COUNT:
        case ARCHIVE_PRODUCT:
        case UNARCHIVE_PRODUCT:
        case DAMAGED_PRODUCTS:
        case UPDATE_PRODUCT:
            let products = state.products.map(product => product._id === action.payload._id ? action.payload : product);
            let activeProducts = products.filter(product => product.isActive);
            return {
                ...state,
                products,
                activeProducts,
            }
        default:
            return state;
    }
}