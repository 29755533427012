import React from 'react';
import { Modal, ModalHeader, ModalFooter, Button, Input, Row, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addUser } from '../../../actions/UserActions';
import { ADMINISTRATION_MANAGEMENT, USER } from '../../constVarAndFunc/UserLevel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ModalBody from 'reactstrap/lib/ModalBody';

class NewUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = this.state;
    }

    state = {
        modal: false,
        name: '',
        email: '',
        userLevel: USER,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            msg: '',
        })
    }
    
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    userLevelOptions = [
        ADMINISTRATION_MANAGEMENT,
        USER
    ]

    saveChanges = () => {
        if(!this.state.name) {
            this.setState({
                msg: 'Please enter a name',
            })
            return;
        }

        if(!this.state.email) {
            this.setState({
                msg: 'Please enter an email',
            })
            return;
        }

        // Check if email already exists
        // If true return without saving changes
        let emailExists = false;
        this.props.users.forEach(user => {
            if(user.email === this.state.email) {
                emailExists = true;
            }
        })

        if(emailExists) {
            this.setState({
                msg: 'This email already exists!'
            })
            return;
        }

        let user = {
            name: this.state.name,
            email: this.state.email,
            userLevel: this.state.userLevel,
        }

        this.props.addUser(user);
        this.setState({
            msg: 'Adding user...'
        })
        setTimeout(() => {
            this.cancel();
        }, 1500)
    }

    cancel = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <div>
                <button className="btn-style" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faUserPlus} size="2x" color="blue" />
                </button>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader>
                        Add new user
                    </ModalHeader>
                    
                    <ModalBody>
                        <Row className="mb-3">
                            <Col md={4} >
                                <strong>Name:</strong>
                            </Col>

                            <Col md={8} >
                                <Input 
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    placeholder="Name"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={4} >
                                <strong>Email:</strong>
                            </Col>

                            <Col md={8} >
                                <Input 
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    placeholder="Email"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={4} >
                                <strong>Name:</strong>
                            </Col>

                            <Col md={8} >
                                <Input
                                    type="select"
                                    name="userLevel"
                                    value={this.state.userLevel}
                                    onChange={this.onChange}
                                >
                                    {this.userLevelOptions.map(option => (
                                        <option> {option} </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        {this.state.msg ? (
                            <Label className="float-left">
                                {this.state.msg}
                            </Label>
                        ) : null}
                        <Button color="primary" onClick={this.saveChanges}>Save Changes</Button>
                        <Button color="secondary" onClick={this.cancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.user.users,
})

export default connect(mapStateToProps, { addUser })(NewUserModal)