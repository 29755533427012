import React from 'react';
import { Label, Card, CardBody, Row, Input, Alert, Button } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';

class ResetPassword extends React.Component {
    state = {
        password: '',
        confirm: '',
        alertMsg: '',
        alertMsgColor: '',
        isTokenValid: false,
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    componentDidMount = () => {
        window.addEventListener('hashchange', this.tokenValidation);
        this.tokenValidation();
    }

    tokenValidation = () => {
        const token = window.location.hash.replace('#', '');
        axios.get(`/api/forgot/reset/${token}`)
            .then(res => {
                this.setState({
                    isTokenValid: true,
                    alertMsgColor: '',
                    alertMsg: '',
                })
            })
            .catch(err => {
                this.setState({
                    alertMsgColor: 'danger',
                    alertMsg: err.response.data.msg,
                    isTokenValid: false,
                })
            })
    }

    resetPassword = () => {
        const token = window.location.hash.replace('#', '');
        axios.post(`/api/forgot/reset/${token}`, {password: this.state.password, confirm: this.state.confirm})
            .then(res => {
                console.log('Calling reset api');
                // Login page or dashboard.
                this.setState({
                    password: '',
                    confirm: '',
                    alertMsg: res.data.msg + '\nRedirecting you to login.',
                    alertMsgColor: 'success',
                })

                setTimeout(() => {
                    window.location.pathname = '/'
                }, 3500)
            })
            .catch(err => {
                // Display error as alert
                console.log('Error: ' + err);
                if(err.response) {
                    this.setState({
                        password: '',
                        confirm: '',
                        alertMsg: err.response.data.msg,
                        alertMsgColor: 'danger',
                    })
                }
            })
    }

    goHome = () => {
        window.location.pathname = "/"
    }

    handleKeyDown = e => {
        if(e.key === 'Enter') {
            this.resetPassword();
        }
    }

    render() {
        return (
            <div style={{background: "#F5F5DC"}}>
                <div className="login-page-image">
                    <div className="login-card shadow-lg">
                        <Card >
                            <CardBody >
                                <Button color="primary" className="float-left" onClick={this.goHome}>
                                    <FontAwesomeIcon size="1x" icon={faHome } />
                                </Button>
                                <h4 className="d-flex justify-content-center">Forgot password?</h4>
                                {this.state.alertMsg ? (
                                    <Alert color={this.state.alertMsgColor}>
                                        {this.state.alertMsg}
                                    </Alert>
                                ) : null}
                                <hr />
                                <Label>Enter new password.</Label>
                                <Row className="p-3">
                                    <Label>New Password</Label>
                                    <Input 
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                        disabled={!this.state.isTokenValid}
                                        placeholder="Enter password"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3">
                                    <Label>Confirm Password</Label>
                                    <Input 
                                        type="password"
                                        name="confirm"
                                        value={this.state.confirm}
                                        onChange={this.onChange}
                                        disabled={!this.state.isTokenValid}
                                        placeholder="Enter password again"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </Row>
                                <Row className="p-3" >
                                    <Button block color="primary" onClick={this.resetPassword} >Change Password</Button>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;